import React from "react";
import { IGroup, IInterval, IColumnConfig } from "../types";
import { SidebarCell } from "../cells/SidebarCell";
import { ContentCell } from "../cells/ContentCell";
import { GridItem } from "../utils/GridItem";

interface IProps<G> {
    group: G;
    intervals: IInterval[];
    rowIndex: number;
    columns: IColumnConfig[];
    className?: string;
    sidebarCellContentRenderer?: (props: { group: G }) => React.ReactNode;
    cellMarkerRenderer?: (props: { group: G; column: IColumnConfig }) => React.ReactNode;
}

export const GroupRow = <G extends IGroup>(props: IProps<G>) => (
    <>
        <SidebarCell
            group={props.group}
            rowIndex={props.rowIndex}
            className={props.className}
            contentRenderer={props.sidebarCellContentRenderer}
        />
        {props.columns.map((col, index) => {
            const cellMarker =
                props.cellMarkerRenderer &&
                props.cellMarkerRenderer({
                    group: props.group,
                    column: col,
                });
            return (
                <React.Fragment key={col.id}>
                    <ContentCell
                        group={props.group}
                        intervals={props.intervals.filter(
                            p => p.day.getTime() >= col.beginDay.getTime() && p.day.getTime() <= col.endDay.getTime()
                        )}
                        rowIndex={props.rowIndex}
                        columnIndex={1 + index + 1}
                        className={props.className}
                    />
                    {cellMarker && (
                        <GridItem
                            column={{
                                start: 1 + index + 1,
                                end: 1 + index + 1,
                            }}
                            row={{
                                start: props.rowIndex,
                                end: props.rowIndex,
                            }}
                        >
                            {cellMarker}
                        </GridItem>
                    )}
                </React.Fragment>
            );
        })}
    </>
);
