import React, { useContext } from "react";
import styles from "./ContentCell.module.scss";
import { IGroup, IInterval } from "../types";
import { Stack } from "../stacking/Stack";
import classNames from "classnames";
import { StacksContext } from "../context/StacksContext";

interface IProps<G extends IGroup, I extends IInterval> {
    intervals: I[];
    group: G;
    rowIndex: number;
    columnIndex: number;
    className?: string;
}

export const ContentCell = <G extends IGroup, I extends IInterval>(props: IProps<G, I>) => {
    const stacks = useContext(StacksContext);
    return (
        <div
            className={classNames(styles.cell, props.className)}
            style={{
                gridRowStart: props.rowIndex,
                gridRowEnd: props.rowIndex,
                gridColumnStart: props.columnIndex,
                gridColumnEnd: props.columnIndex,
            }}
        >
            {stacks.map(stack => {
                const intervals = props.intervals.filter(intr => (intr.group.stackID ?? 1) === stack.id);

                return intervals.length > 0 || stack.visibleIfEmpty ? (
                    <Stack key={stack.id} intervals={intervals} />
                ) : null;
            })}
        </div>
    );
};
