import { createReducer, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { PracListResultMobile } from "../../model/WorksheetModels";
import {
    fetchWorksheetDataForMonth,
    fetchWorksheetDataForNextMonth,
    fetchWorksheetDataForPreviousMonth,
    getAttachments,
    getParamsForWorksheet,
    resetUploadAttachment,
    resetWorksheetData,
    scrollWorksheetNext,
    scrollWorksheetPrevious,
    signWorksheet,
    uploadAttachment,
} from "../actions/worksheet";
import { IParameter } from "../../model/IParameter";

export interface IWorksheetState {
    isFetching: boolean;
    isFetchingPrevious: boolean;
    isFetchingNext: boolean;
    datasourcePrevious?: PracListResultMobile;
    datasource?: PracListResultMobile;
    datasourceNext?: PracListResultMobile;
    error?: SerializedError;
}

export interface IWorksheetParams {
    isFetching: boolean;
    params?: IParameter[];
    error?: SerializedError;
}

export interface IWorksheetSigning {
    isFetching: boolean;
    succesful?: boolean;
    error?: SerializedError;
}

export interface IWorksheetAttachments {
    isUploading: boolean;
    successful?: boolean;
    error?: SerializedError;
    attachments: IWorksheetAttachment[];
    isLoading: boolean;
}

export interface IWorksheetAttachment {
    id_att: number;
    name: string;
    size: number;
    extension: string;
    checkedBy: string;
    checkedWhen: string;
    poznamka: string;
}

export const worksheetReducer = createReducer<IWorksheetState>(
    {
        isFetching: false,
        isFetchingNext: false,
        isFetchingPrevious: false,
    },
    builder => {
        return builder
            .addCase(fetchWorksheetDataForMonth.pending, (state, action) => ({
                ...state,
                isFetching: true,
                datasource: undefined,
                datasourceNext: undefined,
                datasourcePrevious: undefined,
                error: undefined,
            }))
            .addCase(fetchWorksheetDataForMonth.fulfilled, (state, action) => ({
                ...state,
                isFetching: false,
                datasource:
                    action.payload !== null
                        ? { ...action.payload, Mesic: action.meta.arg.StartDate?.toISOString() || "" }
                        : action.payload,
                error: undefined,
            }))
            .addCase(fetchWorksheetDataForMonth.rejected, (state, action) => ({
                ...state,
                isFetching: false,
                datasource: undefined,
                error: action.error,
            }))
            .addCase(fetchWorksheetDataForPreviousMonth.pending, (state, action) => ({
                ...state,
                isFetchingPrevious: true,
            }))
            .addCase(fetchWorksheetDataForPreviousMonth.fulfilled, (state, action) => ({
                ...state,
                isFetchingPrevious: false,
                datasourcePrevious:
                    action.payload !== null
                        ? {
                              ...action.payload,
                              Mesic: action.meta.arg.params.StartDate?.addMonths(-1).toISOString() || "",
                          }
                        : action.payload,
            }))
            .addCase(fetchWorksheetDataForPreviousMonth.rejected, (state, action) => ({
                ...state,
                isFetchingPrevious: false,
                datasourcePrevious: undefined,
            }))
            .addCase(fetchWorksheetDataForNextMonth.pending, (state, action) => ({
                ...state,
                isFetchingNext: true,
            }))
            .addCase(fetchWorksheetDataForNextMonth.fulfilled, (state, action) => ({
                ...state,
                isFetchingNext: false,
                datasourceNext:
                    action.payload !== null
                        ? {
                              ...action.payload,
                              Mesic: action.meta.arg.params.StartDate?.addMonths(1).toISOString() || "",
                          }
                        : action.payload,
            }))
            .addCase(fetchWorksheetDataForNextMonth.rejected, (state, action) => ({
                ...state,
                isFetchingNext: false,
                datasourceNext: undefined,
            }))
            .addCase(scrollWorksheetPrevious, (state, action) => ({
                ...state,
                datasourceNext: state.datasource,
                datasource: state.datasourcePrevious,
                datasourcePrevious: undefined,
            }))
            .addCase(scrollWorksheetNext, (state, action) => ({
                ...state,
                datasourcePrevious: state.datasource,
                datasource: state.datasourceNext,
                datasourceNext: undefined,
            }))
            .addCase(resetWorksheetData, (state, action) => ({
                ...state,
                datasourcePrevious: undefined,
                datasource: undefined,
                datasourceNext: undefined,
            }));
    }
);

export const worksheetParamsReducer = createReducer<IWorksheetParams>(
    {
        isFetching: false,
    },
    builder => {
        return builder
            .addCase(getParamsForWorksheet.pending, (state, action) => ({
                ...state,
                isFetching: true,
                params: undefined,
                error: undefined,
            }))
            .addCase(getParamsForWorksheet.fulfilled, (state, action: PayloadAction<any>) => ({
                ...state,
                isFetching: false,
                params: action.payload,
                error: undefined,
            }))
            .addCase(getParamsForWorksheet.rejected, (state, action) => ({
                ...state,
                isFetching: false,
                params: undefined,
                error: action.error,
            }));
    }
);

export const signingWorksheetReducer = createReducer<IWorksheetSigning>(
    {
        isFetching: false,
    },
    builder => {
        return builder
            .addCase(signWorksheet.pending, (state, action) => ({
                ...state,
                isFetching: true,
                succesful: undefined,
                error: undefined,
            }))
            .addCase(signWorksheet.fulfilled, (state, action: PayloadAction<any>) => ({
                ...state,
                isFetching: false,
                succesful: action.payload,
                error: undefined,
            }))
            .addCase(signWorksheet.rejected, (state, action) => ({
                ...state,
                isFetching: false,
                succesful: undefined,
                error: action.error,
            }));
    }
);

export const attachmentWorksheetReducer = createReducer<IWorksheetAttachments>(
    {
        isUploading: false,
        attachments: [],
        isLoading: false,
    },
    builder => {
        return builder
            .addCase(uploadAttachment.pending, (state, action) => ({
                ...state,
                isUploading: true,
                successful: undefined,
                error: undefined,
            }))
            .addCase(uploadAttachment.fulfilled, (state, action) => ({
                ...state,
                isUploading: false,
                successful: true,
                error: undefined,
            }))
            .addCase(uploadAttachment.rejected, (state, action) => ({
                ...state,
                isUploading: false,
                successful: false,
                error: action.error,
            }))
            .addCase(resetUploadAttachment, (state, action) => ({
                ...state,
                isUploading: false,
                successful: undefined,
                error: undefined,
            }))
            .addCase(getAttachments.pending, (state, action) => ({
                ...state,
                isLoading: true,
            }))
            .addCase(getAttachments.fulfilled, (state, action) => ({
                ...state,
                isLoading: false,
                attachments: action.payload,
            }))
            .addCase(getAttachments.rejected, (state, action) => ({
                ...state,
                isLoading: false,
            }));
    }
);
