import { logout, fetchToken } from "../actions/user";
import { ThunkMiddleware } from "redux-thunk";
import { IRootState } from "../reducers/root";

let timeoutId: number;

export const tokenRefresher: ThunkMiddleware<IRootState> = store => next => action => {
    const result = next(action);

    switch (action.type) {
        case fetchToken.fulfilled.type:
            clearTimeout(timeoutId);
            const expireIn = store.getState().session.user.token?.expires_in ?? 0;
            const ms = (expireIn - 30) * 1000; // 30s rezerva pro odpověď serveru
            timeoutId = setTimeout(() => {
                store.dispatch(
                    fetchToken({
                        refreshToken: store.getState().session.user.token?.refresh_token,
                    })
                );
            }, ms) as any as number;
            break;
        case logout.type:
            clearTimeout(timeoutId);
            break;
    }

    return result;
};
