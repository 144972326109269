import { createReducer, SerializedError } from "@reduxjs/toolkit";
import { WorkSheetPersons } from "../../model/IWorksheetState";
import { fetchWorksheetStates } from "../actions/worksheetState";

export interface IWorksheetStatesState {
    isFetching: boolean;
    worksheetsWithState?: WorkSheetPersons[];
    inlineCount: number;
    error?: SerializedError;
}

export const worksheetStateReducer = createReducer<IWorksheetStatesState>(
    {
        isFetching: false,
        inlineCount: 0,
    },
    builder => {
        builder
            .addCase(fetchWorksheetStates.pending, state => ({
                ...state,
                isFetching: true,
                worksheetsWithState: undefined,
                inlineCount: 0,
                error: undefined,
            }))
            .addCase(fetchWorksheetStates.fulfilled, (state, action) => ({
                ...state,
                isFetching: false,
                worksheetsWithState: GetResultsFromRawData(action.payload),
                inlineCount: GetCountFromRawData(action.payload),
                error: undefined,
            }))
            .addCase(fetchWorksheetStates.rejected, (state, action) => ({
                ...state,
                isFetching: false,
                worksheetsWithState: undefined,
                inlineCount: 0,
                error: action.error,
            }));
    }
);

function GetResultsFromRawData(payload: any): WorkSheetPersons[] {
    return payload.Results;
}

function GetCountFromRawData(payload: any): number {
    return payload.InlineCount;
}
