import React from "react";
import { TimePicker, TimePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { connect } from "react-redux";
import { IRootState } from "../../redux/reducers/root";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import { ILanguage } from "../../model/localization/ILanguage";

import csMessages from "../../assets/cs.json";
loadMessages(csMessages, "cs");

interface IOwnProps {
    onPick: (time: Date) => void;
    selectedTime: Date;
    steps: number;
}

interface IStateProps {
    language: ILanguage;
}

type IProps = IOwnProps & IStateProps;

function TranslatedTimePicker(props: IProps) {
    const handleChange = (event: TimePickerChangeEvent) => {
        if (event.value !== null) {
            const day = new Date(event.value);
            props.onPick(day);
        }
    };

    let time: Date = new Date(props.selectedTime);
    let steps = {
        minute: props.steps,
    };

    return (
        <>
            <LocalizationProvider language={props.language.code}>
                <IntlProvider locale={props.language.code}>
                    <TimePicker value={time} steps={steps} onChange={handleChange} />
                </IntlProvider>
            </LocalizationProvider>
        </>
    );
}

export default connect<IStateProps, {}, IOwnProps, IRootState>(state => ({
    language: state.localization.language,
}))(TranslatedTimePicker);
