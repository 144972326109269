import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialLanguage, languages } from "../../constants/languages";
import { ILanguage } from "../../model/localization/ILanguage";
import { IStrings } from "../../constants/languageStrings/IStrings";
import { selectTranslations } from "../../localization/selectTranslations";

export interface ILocalizationState {
    language: ILanguage;
    strings: IStrings;
}

type SetPayload = {
    language: ILanguage;
    strings: IStrings;
};

export const localizationSlice = createSlice({
    name: "localization",
    initialState: {
        language: initialLanguage,
        strings: selectTranslations(initialLanguage),
    } as ILocalizationState,
    reducers: {
        init: {
            reducer: (state, action: PayloadAction<SetPayload>) => ({
                language: action.payload.language,
                strings: action.payload.strings,
            }),
            prepare: () => {
                const browserLangIsoCode = navigator?.language ?? "";
                const language = languages.find(l => browserLangIsoCode.startsWith(l.code)) ?? initialLanguage;

                return {
                    payload: {
                        language: language,
                        strings: selectTranslations(language),
                    },
                };
            },
        },
        set: {
            reducer: (state, action: PayloadAction<SetPayload>) => ({
                language: action.payload.language,
                strings: action.payload.strings,
            }),
            prepare: (language: ILanguage) => ({
                payload: {
                    language: language,
                    strings: selectTranslations(language),
                },
            }),
        },
    },
});
