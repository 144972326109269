import React from "react";
import { useFormatDate } from "../hooks/useFormatDate";
import { DateFormatOptions } from "@telerik/kendo-intl";

interface IProps {
    value: Date;
    format: string | DateFormatOptions;
}

export function DateTimeFormatter(props: IProps) {
    const formatDate = useFormatDate();

    return <>{formatDate(props.value, props.format)}</>;
}
