import React from "react";
import { useSelector } from "react-redux";
import { selectStrings } from "../../redux/selectors/selectStrings";
import { IRootState } from "../../redux/reducers/root";
import { IBalanceResult } from "../../model/dashboard/IBalanceResult";
import { formatMinutesAsHours } from "./formatMinutesAsHours";

export function Balance() {
    const strings = useSelector(selectStrings);
    const balance = useSelector<IRootState, IBalanceResult | undefined>(s => s.session.dashboard.data?.balance);

    let text = "";
    if (!balance?.isComputed) {
        text = strings.dashboard.NotComputed + "!";
    } else if (balance.hasError) {
        text = strings.dashboard.BalanceError + "!";
    } else {
        text = formatMinutesAsHours(balance.minutes);
    }

    return <>{text}</>;
}
