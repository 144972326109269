import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import React from "react";
import classes from "./CategoriesPicker.module.scss";
import { fetchPossibleCategories } from "../../redux/actions/dropdowns/categories";
import { IPOREFKAT } from "../../redux/reducers/dropdowns/categories";
import { connect } from "react-redux";
import { IRootState } from "../../redux/reducers/root";
import { IStrings } from "../../constants/languageStrings/IStrings";
import { resetCategory } from "../../redux/actions/centerPicker";

interface IOwnProps {
    onSelect?: (category: IPOREFKAT) => void;
    disabled?: boolean;
}

interface IDispatchProps {
    onFetch: () => void;
    onResetCategory: () => void;
}

interface IStateProps {
    strings: IStrings;
    categories: IPOREFKAT[];
    isFetching: boolean;
}

interface IState {
    selectedCategory: number;
}

type Props = IOwnProps & IDispatchProps & IStateProps;

class CategoriesPicker extends React.Component<Props, IState> {
    private defaultItem: IPOREFKAT = { NAZEV_KAT: this.props.strings.common.AllCatsLabel, IXS_KAT: 0 };
    state = {
        selectedCategory: 0,
    };

    async componentDidMount() {
        this.props.onResetCategory && this.props.onResetCategory();
    }

    private handleChange(e: DropDownListChangeEvent): void {
        const cat = e.value as IPOREFKAT;
        this.setState({ selectedCategory: cat.IXS_KAT || 0 });
        this.props.onSelect && this.props.onSelect(cat);
    }

    public render() {
        return (
            <section>
                <div className={classes.root}>
                    <label className={classes.description}>{this.props.strings.common.Category}:</label>
                    <div className={classes.dropdown}>
                        <DropDownList
                            data={this.props.categories}
                            textField="NAZEV_KAT"
                            dataItemKey="IXS_KAT"
                            defaultItem={this.defaultItem}
                            loading={this.props.isFetching}
                            //value={state.value}
                            onChange={change => this.handleChange(change)}
                            onOpen={() => this.props.onFetch && this.props.onFetch()}
                        />
                    </div>
                </div>
            </section>
        );
    }
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IRootState>(
    state => ({
        strings: state.localization.strings,
        categories: state.session.categories.categories,
        isFetching: state.session.categories.isFetching,
    }),
    {
        onFetch: () => fetchPossibleCategories(),
        onResetCategory: () => resetCategory(),
    }
)(CategoriesPicker);
