import React, { useEffect, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import styles from "./PictureDialog.module.scss";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { resetUploadAttachment, uploadAttachment } from "../../redux/actions/worksheet";
import { useAppSelector } from "../../hooks/useAppSelector";
import { SpinnerBox } from "../../components/Spinner/SpinnerBox";

async function ResizeImage(
    dataURL: string,
    maxSize: number,
    callback: (blob: Blob | null) => void,
    filetype?: string,
    quality?: number,
    callback1?: (dataUrl: string) => void
) {
    const image = new Image();
    image.src = dataURL;

    image.onload = function () {
        const maxWidth = maxSize;
        const maxHeight = maxSize;
        let imageWidth = image.width;
        let imageHeight = image.height;

        if (imageWidth > imageHeight) {
            if (imageWidth > maxWidth) {
                imageHeight *= maxWidth / imageWidth;
                imageWidth = maxWidth;
            }
        } else {
            if (imageHeight > maxHeight) {
                imageWidth *= maxHeight / imageHeight;
                imageHeight = maxHeight;
            }
        }

        const canvas = document.createElement("canvas");
        canvas.width = imageWidth;
        canvas.height = imageHeight;

        const ctx = canvas.getContext("2d");
        ctx?.drawImage(image, 0, 0, imageWidth, imageHeight);

        canvas.toBlob(blob => callback(blob), filetype, quality);
        callback1?.(canvas.toDataURL(filetype, quality));
    };
}

export default function PictureDialog(props: { src: string; file?: File; date: Date; onClose: () => void }) {
    const dispatch = useAppDispatch();
    const [name, setName] = useState("");
    const [note, setNote] = useState("");
    const isUploading = useAppSelector(s => s.session.worksheetAttachment.isUploading);
    const successful = useAppSelector(s => s.session.worksheetAttachment.successful);
    const strings = useAppSelector(s => s.localization.strings);
    const [file, setFile] = useState<Blob | null>(null);
    const filename = props.file?.name ?? "";
    const [src, setSrc] = useState("");
    const quality = useAppSelector(s => s.config.config.uploadQuality);
    const maxSize = useAppSelector(s => s.config.config.uploadSize);

    useEffect(() => {
        if (successful) {
            dispatch(resetUploadAttachment());
            props.onClose();
        }
    }, [successful, props, dispatch]);
    useEffect(() => {
        ResizeImage(props.src, maxSize, setFile, props.file?.type, quality, setSrc);
    }, [props, quality, maxSize]);

    useEffect(() => {
        if (filename !== "") {
            setName(filename.split(".").slice(0, -1).join(".") ?? "");
        }
    }, [props, filename]);

    return (
        <Dialog title={strings.worksheet.NewAttachemnt} onClose={props.onClose} width="90%">
            {src !== "" ? (
                <img src={src} alt="" className={styles.img} />
            ) : (
                <div className={styles.img}>
                    <SpinnerBox />
                </div>
            )}
            <Input
                label={strings.worksheet.AttachmentName}
                value={name}
                onChange={e => setName(e.value)}
                required
                disabled={isUploading || filename === ""}
                maxLength={100}
            />
            <Input
                label={strings.worksheet.AttachmentNote}
                value={note}
                onChange={e => setNote(e.value)}
                disabled={isUploading}
                maxLength={255}
            />
            <DialogActionsBar>
                <Button className="k-button" onClick={props.onClose}>
                    {strings.common.Cancel}
                </Button>
                <Button
                    className="k-button"
                    disabled={name === "" || isUploading || !file}
                    icon={isUploading ? "loading" : undefined}
                    onClick={() => {
                        if (file !== null) {
                            dispatch(
                                uploadAttachment({
                                    file: file!,
                                    filename: filename,
                                    date: props.date,
                                    name: name,
                                    note: note,
                                })
                            );
                        }
                    }}
                >
                    {strings.worksheet.Upload}
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
}
