import { IStringsBundle } from "../model/localization/IStringsBundle";
import { en } from "./languageStrings/strings.en";
import { cs } from "./languageStrings/strings.cs";
import { sk } from "./languageStrings/strings.sk";

export const strings: IStringsBundle = {
    en,
    cs,
    sk,
};
