import React from "react";
import { Popup, PopupProps } from "@progress/kendo-react-popup";

/** Custom popup který řeší zobrazení popupu mimo viditelnou oblast na malých zařízeních. */
export default function CustomPopup(props: PopupProps) {
    return (
        <Popup
            {...props}
            collision={{
                horizontal: "fit",
                vertical: "fit",
            }}
        />
    );
}
