import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import React, { useState } from "react";
import classes from "./CustomerCodeForm.module.scss";
import { useAppSelector } from "../../hooks/useAppSelector";

export default function CustomerCodeForm(props: { code: string; onSetCode: (code: string) => void }) {
    const [code, setCode] = useState(props.code);
    const strings = useAppSelector(s => s.localization.strings);
    return (
        <form onSubmit={() => props.onSetCode(code)}>
            <fieldset className={classes.fieldset}>
                <Input
                    name="customerCode"
                    label={strings.login.CustomerCode}
                    onChange={event => setCode(event.value)}
                    value={code}
                    required={true}
                />
            </fieldset>
            <Button type="submit" className="w-100" primary={true} disabled={code === ""}>
                {strings.common.continue}
            </Button>
        </form>
    );
}
