import React, { PropsWithChildren } from "react";
import classes from "./SlimPageLayout.module.scss";
import { AppHeader } from "../../components/AppHeader/AppHeader";
import AppFooter from "../../components/AppFooter/AppFooter";

export function SlimPageLayout(props: PropsWithChildren<{}>) {
    return (
        <div className={classes.layout}>
            <AppHeader />
            <div className={classes.body}>{props.children}</div>
            <AppFooter />
        </div>
    );
}

export default SlimPageLayout;
