import { createReducer, SerializedError } from "@reduxjs/toolkit";
import { ICenter } from "../../model/ICenter";
import { fetchCenterLevel, selectCenter, includingSubcenters } from "../actions/centerPicker";

export interface ICenterPickerState {
    isFetching: boolean;
    requestID?: string;
    parents: ICenter[];
    loadedCenter: ICenter | null;
    childrens: ICenter[];
    selected: ICenter | null;
    includingSubcenters: boolean;
    error?: SerializedError;
}

export const centerPickerReducer = createReducer<ICenterPickerState>(
    {
        isFetching: false,
        parents: [],
        loadedCenter: null,
        childrens: [],
        selected: null,
        includingSubcenters: true,
    },
    builder =>
        builder
            .addCase(fetchCenterLevel.pending, (state, action) => ({
                ...state,
                isFetching: true,
                requestID: action.meta.requestId,
                parents: state.parents,
                loadedCenter: state.loadedCenter,
                childrens: state.childrens,
                selected: state.selected,
                error: undefined,
            }))
            .addCase(fetchCenterLevel.fulfilled, (state, action) =>
                state.requestID === action.meta.requestId
                    ? {
                          ...state,
                          isFetching: false,
                          parents: action.payload.parents,
                          loadedCenter: action.payload.loaded,
                          childrens: action.payload.childrens,
                          selected: state.selected ?? action.payload.loaded, // automatic select on init fetch
                          error: undefined,
                      }
                    : state
            )
            .addCase(fetchCenterLevel.rejected, (state, action) =>
                state.requestID === action.meta.requestId
                    ? {
                          ...state,
                          isFetching: false,
                          parents: state.parents,
                          loadedCenter: state.loadedCenter,
                          childrens: [],
                          selected: state.selected,
                          error: action.error,
                      }
                    : state
            )
            .addCase(selectCenter, (state, action) => ({
                ...state,
                selected: action.payload,
            }))
            .addCase(includingSubcenters, (state, action) => ({
                ...state,
                includingSubcenters: action.payload,
            }))
);
