import { createReducer, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { changePassword, getOldPaswordVisible, getPasswordParam } from "../actions/passwordChange";

export interface IPasswordChangeState {
    changed: boolean;
    isFetching: boolean;
    error?: SerializedError;
}
export interface IPasswordParamState {
    isFetching: boolean;
    strict: boolean;
    error?: SerializedError;
}

export interface IPasswordOldVisibleState {
    isFetching: boolean;
    visible: boolean;
    error?: SerializedError;
}

export const passwordChangeReducer = createReducer<IPasswordChangeState>(
    {
        isFetching: false,
        changed: false,
    },
    builder =>
        builder
            .addCase(changePassword.pending, (state, action) => ({
                ...state,
                changed: false,
                isFetching: true,
                error: undefined,
            }))
            .addCase(changePassword.fulfilled, (state, action) => ({
                ...state,
                changed: true,
                isFetching: false,
                error: undefined,
            }))
            .addCase(changePassword.rejected, (state, action) => ({
                ...state,
                changed: false,
                isFetching: false,
                error: action.error,
            }))
);
export const passwordParamReducer = createReducer<IPasswordParamState>(
    {
        isFetching: false,
        strict: false,
    },
    builder => {
        return builder
            .addCase(getPasswordParam.pending, (state, action) => ({
                ...state,
                isFetching: true,
                strict: false,
                error: undefined,
            }))
            .addCase(getPasswordParam.fulfilled, (state, action: PayloadAction<any>) => ({
                ...state,
                isFetching: false,
                strict: action.payload,
                error: undefined,
            }))
            .addCase(getPasswordParam.rejected, (state, action) => ({
                ...state,
                isFetching: false,
                strict: false,
                error: action.error,
            }));
    }
);

export const passwordOldVisibleReducer = createReducer<IPasswordOldVisibleState>(
    {
        isFetching: false,
        visible: true,
    },
    builder => {
        return builder
            .addCase(getOldPaswordVisible.pending, (state, action) => ({
                ...state,
                isFetching: true,
                visible: true,
                error: undefined,
            }))
            .addCase(getOldPaswordVisible.fulfilled, (state, action: PayloadAction<any>) => ({
                ...state,
                isFetching: false,
                visible: action.payload,
                error: undefined,
            }))
            .addCase(getOldPaswordVisible.rejected, (state, action) => ({
                ...state,
                isFetching: false,
                visible: true,
                error: action.error,
            }));
    }
);
