import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import React, { MouseEventHandler } from "react";
import { connect } from "react-redux";
import { PermissionGuard } from "../../components/PermissionGuard";
import { dateOptions, timeOptions } from "../../constants/dates";
import { permissions } from "../../constants/permissions";
import { IPresenceForPerson } from "../../model/IPresenceForPerson";
import { ILanguage } from "../../model/localization/ILanguage";
import { IStrings } from "../../constants/languageStrings/IStrings";
import { Translation } from "../../model/presenceTranslation";
import { IRootState } from "../../redux/reducers/root";

class PresenceDetail extends React.Component<IProps, {}> {
    private getCorrectDateTimeString(LAST_TIME: Date | null | undefined): string {
        if (LAST_TIME != null) {
            let time: Date = new Date(LAST_TIME);
            return (
                time.toLocaleDateString(this.props.lang.code, dateOptions) +
                " " +
                time.toLocaleTimeString(this.props.lang.code, timeOptions)
            );
        } else return "";
    }

    private getPassTranslation = (presence: number | null): string => {
        return this.props.passesTranslations.find(ipo => ipo.PK_VALUE === presence?.toString())?.PREKLAD ?? "";
    };

    public render() {
        return (
            <Dialog title={<label>{this.props.strings.presence.DetailPresence}</label>} width="90%" closeIcon={false}>
                {this.props.detailData.FullName && (
                    <Input readOnly label={this.props.strings.common.Person} value={this.props.detailData.FullName} />
                )}
                <PermissionGuard permission={permissions.presence.showDetails}>
                    {this.props.detailData.OC && (
                        <Input readOnly label={this.props.strings.common.OC} value={this.props.detailData.OC} />
                    )}
                </PermissionGuard>
                <PermissionGuard permission={permissions.presence.showDetails}>
                    {this.props.detailData.STR_TEXT && (
                        <Input
                            readOnly
                            label={this.props.strings.common.Center}
                            value={this.props.detailData.STR_TEXT}
                        />
                    )}
                </PermissionGuard>
                {this.props.detailData.PRUCHOD_TERM && (
                    <Input
                        readOnly
                        label={this.props.strings.presence.LastPassName}
                        value={
                            this.props.lang.code !== "cs" && this.props.passesTranslations != null
                                ? this.getPassTranslation(this.props.detailData.PRITOMNOST)
                                : this.props.detailData.PRUCHOD_TERM
                        }
                    />
                )}
                <PermissionGuard permission={permissions.presence.showDetails}>
                    {this.props.detailData.LAST_TIME && (
                        <Input
                            readOnly
                            label={this.props.strings.presence.LastPassTime}
                            value={this.getCorrectDateTimeString(this.props.detailData.LAST_TIME)}
                        />
                    )}
                </PermissionGuard>
                <PermissionGuard permission={permissions.presence.showDetails}>
                    {this.props.detailData.TERM_TXT && (
                        <Input
                            readOnly
                            label={this.props.strings.presence.TerminalPres}
                            value={this.props.detailData.TERM_TXT}
                        />
                    )}
                </PermissionGuard>
                <PermissionGuard permission={permissions.presence.showDetails}>
                    {this.props.detailData.SMENA && (
                        <Input
                            readOnly
                            label={this.props.strings.presence.ShiftName}
                            value={this.props.detailData.SMENA}
                        />
                    )}
                </PermissionGuard>
                <DialogActionsBar>
                    <Button onClick={this.props.onClose}>{this.props.strings.common.Close}</Button>
                </DialogActionsBar>
            </Dialog>
        );
    }
}

interface IOwnProps {
    onClose?: MouseEventHandler;
    detailData: IPresenceForPerson;
}

interface IStateProps {
    strings: IStrings;
    lang: ILanguage;
    passesTranslations: Translation[];
}

type IProps = IOwnProps & IStateProps;

export default connect<IStateProps, {}, IOwnProps, IRootState>(state => ({
    strings: state.localization.strings,
    lang: state.localization.language,
    passesTranslations: state.session.passesTranslations.passesTranslations,
}))(PresenceDetail);
