import React from "react";
import { IInterval } from "../types";
import { Interval } from "../intervals/Interval";
import { time2seconds } from "../utils";

interface IProps {
    stackWidth: number;
    interval: IInterval;
}

export function AbsolutePositioner(props: IProps) {
    function computePosition() {
        const beginPerct = (100 / (24 * 60 * 60)) * time2seconds(props.interval.beginTime);
        const endPerct = (100 / (24 * 60 * 60)) * time2seconds(props.interval.endTime);

        const beginPos = (props.stackWidth / 100) * beginPerct;
        const endPos = (props.stackWidth / 100) * endPerct;

        return { left: beginPos, width: endPos - beginPos };
    }

    return (
        <div
            style={{
                position: "absolute",
                zIndex: props.interval.layer ?? undefined,
                ...computePosition(),
            }}
        >
            <Interval interval={props.interval} />
        </div>
    );
}
