import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseError } from "../../helpers/handleResponseError";
import { WflReq } from "../../model/wflReq";
import { IRootState } from "../reducers/root";

const DOMAIN = "myRequests";

export const fetchMyRequestsDatasource = createAsyncThunk<
    WflReq,
    { BoolValue: boolean; IsRequests: boolean },
    { state: IRootState }
>(`${DOMAIN}/fetchMyRequestsDatasource`, async function (arg, api) {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
    let reqBody = {
        IsActual: false,
        BoolValue: arg.BoolValue,
        CurrentAgenda: arg.IsRequests ? "requests" : "unavailabilities",
    };
    const requestOptions: RequestInit = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(reqBody),
    };

    const response = await fetch(
        api.getState().config.config?.endpoint + "/breeze/IpoData/GetWflRequestsTabForMobile",
        requestOptions
    );

    await handleResponseError(response);

    return await response.json();
});
