import { createReducer, SerializedError } from "@reduxjs/toolkit";
import { IPresenceForPerson } from "../../model/IPresenceForPerson";
import { Translation } from "../../model/presenceTranslation";
import {
    fetchAccountsTranslations,
    fetchPassesTranslations,
    fetchPresenceData,
    fetchPresenceTranslations,
} from "../actions/presence";

export interface IPresenceState {
    isFetching: boolean;
    count: number;
    presenceData?: IPresenceForPerson[];
    error?: SerializedError;
}

export const presenceReducer = createReducer<IPresenceState>(
    {
        isFetching: false,
        count: 0,
    },
    builder => {
        builder
            .addCase(fetchPresenceData.pending, state => ({
                ...state,
                isFetching: true,
                presenceData: state.presenceData,
                count: state.count,
                error: undefined,
            }))
            .addCase(fetchPresenceData.fulfilled, (state, action) => ({
                ...state,
                isFetching: false,
                presenceData: parseResult(action.payload),
                count: getCount(action.payload),
                error: undefined,
            }))
            .addCase(fetchPresenceData.rejected, (state, action) => ({
                ...state,
                isFetching: false,
                presenceData: undefined,
                count: 0,
                error: action.error,
            }));
    }
);

function parseResult(payload: any): IPresenceForPerson[] {
    return payload.Results;
}

function getCount(payload: any): number {
    return payload.InlineCount;
}

export interface IPresenceTranslationsState {
    isFetching: boolean;
    presenceTranslations: Translation[];
    error?: SerializedError;
}

export const presenceTranslationsReducer = createReducer<IPresenceTranslationsState>(
    {
        isFetching: false,
        presenceTranslations: [],
    },
    builder => {
        builder
            .addCase(fetchPresenceTranslations.pending, state => ({
                ...state,
                isFetching: true,
                presenceTranslations: state.presenceTranslations,
                error: undefined,
            }))
            .addCase(fetchPresenceTranslations.fulfilled, (state, action) => ({
                ...state,
                isFetching: false,
                presenceTranslations: action.payload,
                error: undefined,
            }))
            .addCase(fetchPresenceTranslations.rejected, (state, action) => ({
                ...state,
                isFetching: false,
                presenceTranslations: [],
                error: action.error,
            }));
    }
);

export interface IPassesTranslationsState {
    isFetching: boolean;
    passesTranslations: Translation[];
    error?: SerializedError;
}

export const passesTranslationsReducer = createReducer<IPassesTranslationsState>(
    {
        isFetching: false,
        passesTranslations: [],
    },
    builder => {
        builder
            .addCase(fetchPassesTranslations.pending, state => ({
                ...state,
                isFetching: true,
                passesTranslations: state.passesTranslations,
                error: undefined,
            }))
            .addCase(fetchPassesTranslations.fulfilled, (state, action) => ({
                ...state,
                isFetching: false,
                passesTranslations: action.payload,
                error: undefined,
            }))
            .addCase(fetchPassesTranslations.rejected, (state, action) => ({
                ...state,
                isFetching: false,
                passesTranslations: [],
                error: action.error,
            }));
    }
);

export interface IAccountsTranslationsState {
    isFetching: boolean;
    accountsTranslations: Translation[];
    error?: SerializedError;
}

export const accountsTranslationsReducer = createReducer<IAccountsTranslationsState>(
    {
        isFetching: false,
        accountsTranslations: [],
    },
    builder => {
        builder
            .addCase(fetchAccountsTranslations.pending, state => ({
                ...state,
                isFetching: true,
                error: undefined,
            }))
            .addCase(fetchAccountsTranslations.fulfilled, (state, action) => ({
                ...state,
                isFetching: false,
                accountsTranslations: action.payload,
                error: undefined,
            }))
            .addCase(fetchAccountsTranslations.rejected, (state, action) => ({
                ...state,
                isFetching: false,
                accountsTranslations: [],
                error: action.error,
            }));
    }
);
