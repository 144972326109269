import React, { PropsWithChildren } from "react";
import styles from "./SpinnerBox.module.scss";

interface IProps extends PropsWithChildren<{}> {
    /** Size of spinner in rem units. Defaults to 5rem. */
    size?: number;
    stretchToWindow?: boolean;
}

export function SpinnerBox(props: IProps) {
    return (
        <div className={styles.container} style={props.stretchToWindow ? { height: "95vh" } : {}}>
            <span
                className="k-icon k-i-loading"
                style={{
                    fontSize: `${props.size ?? 5}rem`,
                    marginBottom: props.children ? "1rem" : undefined,
                }}
            ></span>
            {props.children}
        </div>
    );
}
