import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRootState } from "../../reducers/root";
import { handleResponseError } from "../../../helpers/handleResponseError";
import { IPOREFKAT } from "../../reducers/dropdowns/categories";

const DOMAIN = "dropdowns/categories";

export const fetchPossibleCategories = createAsyncThunk<IPOREFKAT[], void, { state: IRootState }>(
    `${DOMAIN}/fetchPossibleCategories`,
    async (arg, api) => {
        const requestOptions: RequestInit = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${api.getState().session.user?.token?.access_token}`,
            },
            body: JSON.stringify({
                Item1:
                    api.getState().session.centerPicker.loadedCenter != null
                        ? api.getState().session.centerPicker.loadedCenter!.id
                        : "0",
                Item2: api.getState().session.centerPicker.includingSubcenters,
            }),
            signal: api.signal,
        };

        const action: string = "/breeze/IpoData/SelfService_GetAvailableKats";

        const response = await fetch(api.getState().config.config?.endpoint + action, requestOptions);

        await handleResponseError(response);

        return await response.json();
    }
);
