import React from "react";
import { Notification } from "@progress/kendo-react-notification";
import { useStrings } from "../../../hooks/useStrings";
import { useAppSelector } from "../../../hooks/useAppSelector";

export function TooMuchPersonsWarning() {
    const strings = useStrings();
    const centerName = useAppSelector(s => s.session.centerPicker.selected?.name ?? "");
    const includingSubcenters = useAppSelector(s => s.session.centerPicker.includingSubcenters);
    const max = useAppSelector(s => s.session.plan.department.maxAllowedPersonsCount);

    const _0 =
        centerName + (includingSubcenters ? `, ${strings.centerPicker.IncludingSubcenters.toLocaleLowerCase()}` : "");
    return (
        <Notification type={{ icon: true, style: "warning" }}>
            {strings.plan.Msg_TooMuchPersons.replace("{0}", _0).replace("{1}", max.toString())}
        </Notification>
    );
}
