import React from "react";
import { useParams } from "react-router-dom";
import { NavBar } from "../../components/NavBar/NavBar";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import { useStrings } from "../../hooks/useStrings";
import styles from "./Help.module.scss";

export default function Help() {
    const strings = useStrings();
    const { anchor } = useParams<{ anchor: string }>();
    return (
        <PageLayout
            header={
                <NavBar
                    canNavigateBack
                    previousPage={"/" + anchor.replaceAll("_", "/")}
                    label={strings.common.Help}
                ></NavBar>
            }
        >
            <iframe title="help" className={styles.helpFrame} src={"./help/cs.html#/" + anchor.replaceAll("_", "/")} />
        </PageLayout>
    );
}
