import { createAsyncThunk, SerializedError } from "@reduxjs/toolkit";
import { IRootState } from "../reducers/root";

export const reportError = createAsyncThunk<void, SerializedError, { state: IRootState }>(
    "error/report",
    (arg, api) => {
        fetch(api.getState().config.config.endpoint + "/breeze/IpoData/ReportError", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(arg),
            signal: api.signal,
        });
    }
);
