export const VENDOR = "ANeT";

export const APP_NAME = "SelfService";

export const APP_VERSION_MAJOR = "2";
export const APP_VERSION_MINOR = __APP_VERSION_MINOR__;
export const APP_VERSION = APP_VERSION_MAJOR + "." + APP_VERSION_MINOR;

export const COMMIT_HASH = __COMMIT_HASH__;

export const InternalMaxLimitParam = 1000;

export const LOCALSTORAGE_CUSTOMERCODE = `${VENDOR}/${APP_NAME}/customerCode`;
