import React from "react";
import { Toolbar, DropDownButton, DropDownButtonItemClickEvent } from "@progress/kendo-react-buttons";
import styles from "./NavBar.module.scss";
import { Link, useHistory } from "react-router-dom";
import classNames from "classnames";
import { ArrowBack } from "@material-ui/icons";
import { IBaseMenuItem } from "./IBaseMenuItem";
import { useStrings } from "../../hooks/useStrings";
import { useAppSelector } from "../../hooks/useAppSelector";

interface INavBarProps<I extends IBaseMenuItem = IBaseMenuItem> {
    canNavigateRoot?: boolean;
    canNavigateBack?: boolean;
    previousPage?: string;
    label: string;
    labelContent?: React.ReactNode;

    menu?: {
        items: I[];
        onItemClick?: (item: I) => void;
    };
}

export function NavBar(props: React.PropsWithChildren<INavBarProps>) {
    const strings = useStrings();
    const tokenFetching = useAppSelector(s => s.session.user.isAuthenticating);
    const dataFetching = useAppSelector(s => s.session.dashboard.isFetching);
    const history = useHistory();

    if (props.menu !== undefined && props.menu.items.every(i => i.id !== 0)) {
        props.menu.items.push({
            id: 0,
            content: strings.common.Help,
            icon: props.menu.items.some(i => i.icon !== undefined) ? "none" : undefined,
        });
    }

    function handleMenuItemClick(e: DropDownButtonItemClickEvent) {
        if (e.item?.id === 0) {
            history.push("/help/" + window.location.hash.substring(2).replaceAll("/", "_"));
        }
        props.menu?.onItemClick && props.menu.onItemClick(e.item);
    }

    return (
        <Toolbar className={classNames(styles.toolbar, styles.primary)}>
            {props.canNavigateRoot ? <LinkButton to="/dashboard" /> : null}
            {props.canNavigateBack ? <LinkButton to={props.previousPage ? props.previousPage : "/dashboard"} /> : null}
            <h1 className={classNames(styles.heading, "app-bar-heading")} title={props.label}>
                {props.labelContent ?? props.label}
            </h1>
            {props.children}
            {props.menu ? (
                <DropDownButton
                    disabled={tokenFetching || dataFetching}
                    icon="more-vertical"
                    items={props.menu?.items}
                    textField="content"
                    onItemClick={handleMenuItemClick}
                    popupSettings={{
                        anchorAlign: {
                            horizontal: "center",
                            vertical: "center",
                        },
                        popupAlign: {
                            horizontal: "right",
                            vertical: "top",
                        },
                        popupClass: styles.popup,
                        animate: false,
                    }}
                />
            ) : null}
        </Toolbar>
    );
}

function LinkButton(props: { to: string }) {
    return (
        <Link
            className={classNames("k-button", "k-button-icon")}
            style={{
                display: "flex",
                alignItems: "center",
                padding: 0,
            }}
            to={props.to}
        >
            <ArrowBack />
        </Link>
    );
}
