import { createReducer, SerializedError } from "@reduxjs/toolkit";
import { IPlanPerson } from "../../model/IPlanPerson";
import { fetchPlanStates, getParamsForPlanStates } from "../actions/planState";

export interface IPlanStatesState {
    isFetching: boolean;
    planWithState?: IPlanPerson[];
    inlineCount: number;
    error?: SerializedError;
}

export const planStateReducer = createReducer<IPlanStatesState>(
    {
        isFetching: false,
        inlineCount: 0,
    },
    builder => {
        builder
            .addCase(fetchPlanStates.pending, state => ({
                ...state,
                isFetching: true,
                planWithState: [],
                inlineCount: 0,
                error: undefined,
            }))
            .addCase(fetchPlanStates.fulfilled, (state, action) => ({
                ...state,
                isFetching: false,
                planWithState: GetResultsFromRawData(action.payload),
                inlineCount: GetCountFromRawData(action.payload),
                error: undefined,
            }))
            .addCase(fetchPlanStates.rejected, (state, action) => ({
                ...state,
                isFetching: false,
                planWithState: [],
                inlineCount: 0,
                error: action.error,
            }));
    }
);

function GetResultsFromRawData(payload: any): IPlanPerson[] {
    return payload.Results;
}

function GetCountFromRawData(payload: any): number {
    return payload.InlineCount;
}

export interface IPlanStatesParametersState {
    isFetching: boolean;
    recommendedDays: number;
    error?: SerializedError;
}

export const planStateParametersReducer = createReducer<IPlanStatesParametersState>(
    {
        isFetching: false,
        recommendedDays: 0,
    },
    builder => {
        builder
            .addCase(getParamsForPlanStates.pending, state => ({
                ...state,
                isFetching: true,
                recommendedDays: 0,
                error: undefined,
            }))
            .addCase(getParamsForPlanStates.fulfilled, (state, action) => ({
                ...state,
                isFetching: false,
                recommendedDays: Number(action.payload),
                error: undefined,
            }))
            .addCase(getParamsForPlanStates.rejected, (state, action) => ({
                ...state,
                isFetching: false,
                recommendedDays: 0,
                error: action.error,
            }));
    }
);
