import { AppThunkAction } from "../../../model/redux/AppThunkAction";
import { VENDOR, APP_NAME } from "../../../constants/common";

export function buildKey(key: string): AppThunkAction<string> {
    return (dispatch, getState) => {
        const parts: string[] = [`${VENDOR}.${APP_NAME}`, key];

        const user = getState().session.user;
        if (user.isAuthenticated && user.token?.sid) parts.push(user.token?.sid);

        return parts.join("|");
    };
}
