import React from "react";
import { Button, ButtonProps } from "@progress/kendo-react-buttons";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useStrings } from "../../../hooks/useStrings";

export const SelectedCenterButton = React.forwardRef<Button, ButtonProps>((props, ref) => {
    const strings = useStrings();
    const center = useAppSelector(s => s.session.centerPicker.selected);
    return (
        <Button {...props} ref={ref}>
            {center?.name ?? `<${strings.centerPicker.NotSelected}>`}
        </Button>
    );
});
