import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IRootState } from "../../reducers/root";
import { IDepartmentPlanData } from "../../../model/plan/department/IDepartmentPlanData";
import getHeaders from "../../../helpers/getHeaders";
import GetResponseJson from "../../../helpers/getResponseJson";

type Arg = {
    from: Date;
    to: Date;
};
type IntervalsArg = Arg & {
    ixsRefs: string[];
};

const DATA_ADDRESS = "/breeze/IpoData/SelfService_GetDepartmentPlanData";
const INTERVALS_ADDRESS = "/breeze/IpoData/SelfService_GetDepartmentPlanDataIntervals";

const getDataBody = (api: { getState: () => IRootState }, arg: Arg) => {
    return JSON.stringify({
        Item1: arg.from,
        Item2: arg.to,
        Item3: api.getState().session.centerPicker.selected?.id,
        Item4: api.getState().session.centerPicker.includingSubcenters,
        Item5: api.getState().session.categories.selected,
    });
};

const getIntervalsBody = (arg: IntervalsArg, recalculate: boolean) => {
    return JSON.stringify({
        Item1: arg.from,
        Item2: arg.to,
        Item3: arg.ixsRefs,
        Item4: recalculate,
    });
};

export const fetchDepartmentPlanData = createAsyncThunk<Partial<IDepartmentPlanData>, Arg, { state: IRootState }>(
    "plan/department/data/fetch",
    async (arg, api) => {
        const requestOptions: RequestInit = {
            method: "POST",
            headers: getHeaders(api),
            body: getDataBody(api, arg),
            signal: api.signal,
        };
        return await GetResponseJson(api, DATA_ADDRESS, requestOptions);
    }
);

export const fetchDepartmentPlanDataIntervals = createAsyncThunk<
    Partial<IDepartmentPlanData>,
    IntervalsArg,
    { state: IRootState }
>("plan/department/data/intervals", async (arg, api) => {
    const requestOptions: RequestInit = {
        method: "POST",
        headers: getHeaders(api),
        body: getIntervalsBody(arg, false),
        signal: api.signal,
    };
    return await GetResponseJson(api, INTERVALS_ADDRESS, requestOptions);
});

export const fetchDepartmentPlanDataIntervalsRecalculate = createAsyncThunk<
    Partial<IDepartmentPlanData>,
    IntervalsArg,
    { state: IRootState }
>("plan/department/data/recalculate", async (arg, api) => {
    const requestOptions: RequestInit = {
        method: "POST",
        headers: getHeaders(api),
        body: getIntervalsBody(arg, true),
        signal: api.signal,
    };
    return await GetResponseJson(api, INTERVALS_ADDRESS, requestOptions);
});

export const fetchDepartmentPlanPreviousData = createAsyncThunk<
    Partial<IDepartmentPlanData>,
    Arg,
    { state: IRootState }
>("plan/department/data/previous/fetch", async (arg, api) => {
    const requestOptions: RequestInit = {
        method: "POST",
        headers: getHeaders(api),
        body: getDataBody(api, arg),
        signal: api.signal,
    };
    return await GetResponseJson(api, DATA_ADDRESS, requestOptions);
});

export const fetchDepartmentPlanDataPreviousIntervals = createAsyncThunk<
    Partial<IDepartmentPlanData>,
    IntervalsArg,
    { state: IRootState }
>("plan/department/data/previous/intervals", async (arg, api) => {
    const requestOptions: RequestInit = {
        method: "POST",
        headers: getHeaders(api),
        body: getIntervalsBody(arg, false),
        signal: api.signal,
    };
    return await GetResponseJson(api, INTERVALS_ADDRESS, requestOptions);
});

export const fetchDepartmentPlanDataIntervalsPreviousRecalculate = createAsyncThunk<
    Partial<IDepartmentPlanData>,
    IntervalsArg,
    { state: IRootState }
>("plan/department/data/previous/recalculate", async (arg, api) => {
    const requestOptions: RequestInit = {
        method: "POST",
        headers: getHeaders(api),
        body: getIntervalsBody(arg, true),
        signal: api.signal,
    };
    return await GetResponseJson(api, INTERVALS_ADDRESS, requestOptions);
});

export const fetchDepartmentPlanNextData = createAsyncThunk<Partial<IDepartmentPlanData>, Arg, { state: IRootState }>(
    "plan/department/data/next/fetch",
    async (arg, api) => {
        const requestOptions: RequestInit = {
            method: "POST",
            headers: getHeaders(api),
            body: getDataBody(api, arg),
            signal: api.signal,
        };
        return await GetResponseJson(api, DATA_ADDRESS, requestOptions);
    }
);

export const fetchDepartmentPlanDataNextIntervals = createAsyncThunk<
    Partial<IDepartmentPlanData>,
    IntervalsArg,
    { state: IRootState }
>("plan/department/data/next/intervals", async (arg, api) => {
    const requestOptions: RequestInit = {
        method: "POST",
        headers: getHeaders(api),
        body: getIntervalsBody(arg, false),
        signal: api.signal,
    };
    return await GetResponseJson(api, INTERVALS_ADDRESS, requestOptions);
});

export const fetchDepartmentPlanDataIntervalsNextRecalculate = createAsyncThunk<
    Partial<IDepartmentPlanData>,
    IntervalsArg,
    { state: IRootState }
>("plan/department/data/next/recalculate", async (arg, api) => {
    const requestOptions: RequestInit = {
        method: "POST",
        headers: getHeaders(api),
        body: getIntervalsBody(arg, true),
        signal: api.signal,
    };
    return await GetResponseJson(api, INTERVALS_ADDRESS, requestOptions);
});

export const scrollDepartmentPlanPrevious = createAction("plan/department/previous");

export const scrollDepartmentPlanNext = createAction("plan/department/next");

export const resetDeparmentPlanData = createAction("plan/department/reset");
