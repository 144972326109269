import moment from "moment";
import { SerializedInterval, IPlanTimelineInterval } from "../../../model/plan/types";
import { Color } from "../../../model/Color";
import { deserialize } from "../shared/deserialize";
import { resolveTitle } from "../shared/resolveTitle";

export function transformDataForTimeline(intrs: SerializedInterval[]): IPlanTimelineInterval[] {
    let id = 1;

    return deserialize(intrs)
        .map(intr => {
            const day = moment(new Date(intr.begin)).startOf("day");
            const begin = moment(new Date(intr.begin));
            const end = moment(new Date(intr.end));

            const base: IPlanTimelineInterval = {
                id: `${day.toDate().getTime()}.${id++}`,
                group: {
                    id: day.toDate().getTime(),
                    stackID: (() => {
                        switch (intr.type) {
                            case "PLNCHANGE":
                                return 0;
                            case "IPODENSMEN":
                                return 1;
                            case "IPOPOH":
                                return 3;
                            default:
                                return 2;
                        }
                    })(),
                },
                title: resolveTitle(intr),
                day: day.toDate(),
                beginTime: begin.toDate(),
                endTime: intr.isAllDay ? end.set({ hour: 23, minute: 59, second: 59 }).toDate() : end.toDate(),
                style: {
                    background:
                        intr.type === "IPODENSMEN" || intr.type === "PLNCHANGE"
                            ? new Color({ red: 255, green: 255, blue: 255, alpha: 1 }).toCSSRGBA()
                            : Color.fromPbColor(intr.color).toCSSRGBA(),
                    color:
                        intr.color !== null && (intr.type === "IPODENSMEN" || intr.type === "PLNCHANGE")
                            ? Color.fromPbColor(intr.color).toCSSRGBA()
                            : undefined,
                    borderColor: intr.type === "PLNCHANGE" ? "red" : "black",
                },
                layer: intr.type === "IPOPREST" || intr.type === "PLNCHANGE" ? 1 : undefined,
                parent: intr,
            };

            const intervalLen = end.clone().startOf("day").diff(begin.clone().startOf("day"), "day") + 1;

            if (intervalLen > 1) {
                var result = [];
                const firstDay = begin.clone().startOf("day");
                result.push({
                    ...base,
                    id: `${day.toDate().getTime()}.${id++}`,
                    day: firstDay.toDate(),
                    beginTime: begin.toDate(),
                    endTime: begin.clone().endOf("day").toDate(),
                    style: {
                        ...base.style,
                        borderRight: "none",
                    },
                });
                for (let i = 1; i < intervalLen; i++) {
                    var currentDay = firstDay.clone().add(i, "day");
                    var style;
                    if (i === intervalLen - 1) {
                        style = {
                            ...base.style,
                            borderLeft: "none",
                        };
                    } else {
                        style = {
                            ...base.style,
                            borderRight: "none",
                            borderLeft: "none",
                        };
                    }
                    result.push({
                        ...base,
                        id: `${day.toDate().getTime()}.${id++}`,
                        group: {
                            ...base.group,
                            id: currentDay.toDate().getTime(),
                        },
                        day: currentDay.toDate(),
                        beginTime: currentDay.toDate(),
                        endTime: i === intervalLen - 1 ? end.toDate() : currentDay.clone().endOf("day").toDate(),
                        style: style,
                    });
                }
                return result;
            } else return [base];
        })
        .flat();
}
