import React from "react";
import ConfigDownloading from "./ConfigDownloading";
import SlimPageLayout from "./SlimPageLayout";
import { AuthLayout } from "./AuthLayout";
import ServerChecking from "./ServerChecking";
import LicenseGuard from "./LicenseGuard";

export default function StartUp() {
    return (
        <SlimPageLayout>
            <ConfigDownloading>
                <ServerChecking>
                    <LicenseGuard>
                        <AuthLayout />
                    </LicenseGuard>
                </ServerChecking>
            </ConfigDownloading>
        </SlimPageLayout>
    );
}
