import React, { Key, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { IRootState } from "../../redux/reducers/root";
import { errorsListSlice, IErrorContainer } from "../../redux/slices/errorsList";
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from "@material-ui/core";
import { DeleteForever } from "@material-ui/icons";
import { Button } from "@progress/kendo-react-buttons";
import { IStrings } from "../../constants/languageStrings/IStrings";
import { errorsVisibilitySlice } from "../../redux/slices/errorsVisibility";

interface IStateProps {
    strings: IStrings;
    errors: IErrorContainer[];
}

interface IDispatchProps {
    onClose: () => void;
    onRemove: (id: Key) => void;
    onRemoveAll: () => void;
}

type Props = IStateProps & IDispatchProps;

const ErrorsDialog = (props: Props) => {
    const [lastCopyInfo, setLastCopyInfo] = useState<{ id: Key; text?: string } | undefined>();
    const [timeoutID, setTimeoutID] = useState<number>(0);

    useEffect(() => () => clearTimeout(timeoutID), [timeoutID]);

    const handleListItemClick = (e: IErrorContainer) => {
        clearTimeout(timeoutID);
        const text = JSON.stringify(e, null, "\t");

        if (window.isSecureContext) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    setLastCopyInfo({
                        id: e.id,
                        text: props.strings.debug.Msg_DetailsSuccessfullyCopiedIntoClipboard,
                    });
                })
                .catch(reason => {
                    console.error(reason);
                    setLastCopyInfo({
                        id: e.id,
                        text: reason,
                    });
                })
                .finally(() => {
                    const id = setTimeout(() => setLastCopyInfo(undefined), 3000) as unknown as number;
                    setTimeoutID(id);
                });
        } else if (!!document.execCommand) {
            unsecuredCopyToClipboard(text);
            setLastCopyInfo({
                id: e.id,
                text: props.strings.debug.Msg_DetailsSuccessfullyCopiedIntoClipboard,
            });
            const id = setTimeout(() => setLastCopyInfo(undefined), 3000) as unknown as number;
            setTimeoutID(id);
        }
    };

    function unsecuredCopyToClipboard(text: string) {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand("copy");
        } catch (err) {
            console.error("Unable to copy to clipboard", err);
        }
        document.body.removeChild(textArea);
    }

    return (
        <Dialog title={props.strings.debug.ErrorsMany} width="100%" height="100%" onClose={props.onClose}>
            <List>
                {props.errors.map(e => (
                    <ListItem key={e.id} divider button onClick={() => handleListItemClick(e)}>
                        <ListItemText
                            primary={`#${e.id} ${e.error.message}`}
                            primaryTypographyProps={{ noWrap: true }}
                            secondary={
                                lastCopyInfo?.id === e.id
                                    ? lastCopyInfo.text
                                    : props.strings.debug.Msg_ClickForCopyDetailsIntoClipboard
                            }
                        />
                        <ListItemSecondaryAction>
                            <IconButton
                                title={props.strings.common.Delete}
                                edge="end"
                                onClick={() => props.onRemove(e.id)}
                            >
                                <DeleteForever />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
            <DialogActionsBar>
                <Button
                    title={`${props.strings.common.DeleteAll} & ${props.strings.common.Close}`}
                    icon="delete"
                    onClick={() => {
                        props.onRemoveAll();
                        props.onClose();
                    }}
                >
                    {`${props.strings.common.DeleteAll} & ${props.strings.common.Close}`}
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
};

export default connect<IStateProps, IDispatchProps, {}, IRootState>(
    state => ({
        strings: state.localization.strings,
        errors: state.errors.list,
    }),
    dispatch => ({
        onClose: () => dispatch(errorsVisibilitySlice.actions.set(false)),
        onRemove: id => dispatch(errorsListSlice.actions.remove(id)),
        onRemoveAll: () => dispatch(errorsListSlice.actions.removeAll()),
    })
)(ErrorsDialog);
