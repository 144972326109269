import React from "react";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { TooltipProps, Tooltip } from "@material-ui/core";

type Props = Omit<TooltipProps, "title">;

export function SelectedCenterTooltip(props: Props) {
    const centerName = useAppSelector(s => s.session.centerPicker.selected?.name ?? "");

    return <Tooltip {...props} title={centerName} />;
}
