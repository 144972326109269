import { createReducer } from "@reduxjs/toolkit";
import { wflChangeState, wflChangeStateMultiple } from "../actions/wflStateChange";

export interface IWFLChangeState {
    inserting?: boolean;
    response?: unknown;
    error?: unknown;
}

export const wflChangeStateReducer = createReducer<IWFLChangeState>({}, builder =>
    builder
        .addCase(wflChangeState.pending, (state, action) => ({
            ...state,
            inserting: true,
            response: undefined,
            error: undefined,
        }))
        .addCase(wflChangeState.fulfilled, (state, action) => ({
            ...state,
            inserting: false,
            response: action.payload,
        }))
        .addCase(wflChangeState.rejected, (state, action) => ({
            ...state,
            inserting: false,
            error: action.payload,
        }))
        .addCase(wflChangeStateMultiple.pending, (state, action) => ({
            ...state,
            inserting: true,
            response: undefined,
            error: undefined,
        }))
        .addCase(wflChangeStateMultiple.fulfilled, (state, action) => ({
            ...state,
            inserting: false,
            response: action.payload,
        }))
        .addCase(wflChangeStateMultiple.rejected, (state, action) => ({
            ...state,
            inserting: false,
            error: action.payload,
        }))
);
