import { useAppSelector } from "./useAppSelector";
import { formatDate as kendoFormatDate, DateFormatOptions } from "@telerik/kendo-intl";

export function useFormatDate() {
    const locale = useAppSelector(s => s.localization.language.code);

    return function formatDate(value: Date, format: string | DateFormatOptions): string {
        return kendoFormatDate(value, format, locale);
    };
}
