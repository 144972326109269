import React from "react";
import LanguageDropDown from "../LanguageDropDown/LanguageDropDown";
import styles from "./AppHeader.module.css";
import logoSource from "../../assets/images/ANeT_Time_100x100.png";

export function AppHeader() {
    return (
        <header className={styles.header}>
            <img src={logoSource} alt="ANeT-SelfService" width={100} height={100} />
            <div className={styles.heading}>
                <h2>
                    ANeT
                    <br />
                    SelfService
                </h2>
            </div>
            <div className={styles.lang}>
                <LanguageDropDown />
            </div>
        </header>
    );
}
