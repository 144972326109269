import { createReducer, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { fetchMyRequestsDatasource } from "../actions/myRequests";
import { WflReq } from "../../model/wflReq";

export interface IMyRequestsState {
    isFetching: boolean;
    datasource?: WflReq[];
    error?: SerializedError;
}

export const myRequestsReducer = createReducer<IMyRequestsState>(
    {
        isFetching: false,
    },
    builder => {
        return builder
            .addCase(fetchMyRequestsDatasource.pending, (state, action) => ({
                ...state,
                isFetching: true,
                datasource: undefined,
                error: undefined,
            }))
            .addCase(fetchMyRequestsDatasource.fulfilled, (state, action: PayloadAction<any>) => ({
                ...state,
                isFetching: false,
                datasource: action.payload,
                error: undefined,
            }))
            .addCase(fetchMyRequestsDatasource.rejected, (state, action) => ({
                ...state,
                isFetching: false,
                datasource: undefined,
                error: action.error,
            }));
    }
);
