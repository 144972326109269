import React from "react";
import { formatMinutesAsHours } from "./formatMinutesAsHours";
import { useStrings } from "../../hooks/useStrings";
import { useAppSelector } from "../../hooks/useAppSelector";
import { IVacationResult } from "../../model/dashboard/IVacationResult";

export function Vacation(props: { vacation?: IVacationResult; defaultName: string }) {
    const strings = useStrings();
    const lang = useAppSelector(s => s.localization.language.id);
    let days: number = props.vacation?.days ?? 0;
    let minutes: number = props.vacation?.minutes ?? 0;

    const parts: string[] = [];

    if (lang === -1 && props.vacation?.nameEN) parts.push(props.vacation.nameEN);
    else if (lang === -2 && props.vacation?.nameCZ) parts.push(props.vacation.nameCZ);
    else if (lang === -3 && props.vacation?.nameSK) parts.push(props.vacation.nameSK);
    else parts.push(props.defaultName);

    parts.push(": ");

    parts.push(`${days} ${strings.dashboard.Days.toLocaleLowerCase()}, `);

    parts.push(`${formatMinutesAsHours(minutes)} ${strings.dashboard.Hours4.toLocaleLowerCase()}`);

    return <>{parts.join("")}</>;
}
