import { createAsyncThunk } from "@reduxjs/toolkit";
import { QueryParam } from "../../model/CommonQueryParams";
import { IRootState } from "../reducers/root";
import { PLNUNAVA } from "../../model/PLNUNAVA";

const DOMAIN = "Unavailabilities";

export const fetchUnavalabilities = createAsyncThunk<{}, QueryParam<string>, { state: IRootState }>(
    `${DOMAIN}/fetchUnavalabilities`,
    async (arg, api) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
        const requestOptions: RequestInit = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(arg),
        };

        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/SelfService_GetPLNUNAVAs",
            requestOptions
        );

        const data = await response.json();

        if (response.status === 200) return data;
        else return api.rejectWithValue(data);
    }
);

export const createUnavailability = createAsyncThunk<{}, QueryParam<PLNUNAVA>, { state: IRootState }>(
    `${DOMAIN}/createUnavailability`,
    async (arg, api) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
        const requestOptions: RequestInit = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(arg),
        };

        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/SelfService_CreatePLNUNAVA",
            requestOptions
        );

        const data = await response.json();

        if (response.status === 200) return data;
        else return api.rejectWithValue(data);
    }
);

export const deleteUnavailability = createAsyncThunk<{}, QueryParam<number>, { state: IRootState }>(
    `${DOMAIN}/deleteUnavailability`,
    async (arg, api) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
        const requestOptions: RequestInit = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(arg),
        };

        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/SelfService_DeletePLNUNAVA",
            requestOptions
        );

        const data = await response.json();

        if (response.status === 200) return data;
        else return api.rejectWithValue(data);
    }
);
