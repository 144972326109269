export class LoginError extends Error {
    private _code: string;

    public get code(): string {
        return this._code;
    }

    constructor(code: string, message?: string) {
        super(message);
        this.name = "LoginError";
        this._code = code;
    }
}
