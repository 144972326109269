import { createReducer, SerializedError } from "@reduxjs/toolkit";
import { fetchConfig } from "../actions/config";
import { defaults } from "../../constants/config";
import { IConfig } from "../../model/config/IConfig";

export interface IConfigState {
    isFetching: boolean;
    config: IConfig;
    error?: SerializedError;
}

export const configReducer = createReducer<IConfigState>(
    {
        isFetching: false,
        config: defaults,
    },
    builder =>
        builder
            .addCase(fetchConfig.pending, (state, action) => ({
                ...state,
                isFetching: true,
                config: state.config,
                error: undefined,
            }))
            .addCase(fetchConfig.fulfilled, (state, action) => ({
                ...state,
                isFetching: false,
                config: action.payload,
                error: undefined,
            }))
            .addCase(fetchConfig.rejected, (state, action) => ({
                ...state,
                isFetching: false,
                config: state.config,
                error: action.error,
            }))
);
