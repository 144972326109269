import { createReducer, SerializedError } from "@reduxjs/toolkit";
import { IPOCPT } from "../../model/terminal/IPOCPT";
import { fetchSecuredPassesDatasource, insertPass } from "../actions/terminal";

export interface ITerminalState {
    isFetching: boolean;
    passes?: IPOCPT[];
    error?: SerializedError;
}

export const terminalReducer = createReducer<ITerminalState>(
    {
        isFetching: false,
    },
    builder => {
        builder
            .addCase(fetchSecuredPassesDatasource.pending, (state, action) => ({
                ...state,
                isFetching: true,
                passes: undefined,
                error: undefined,
            }))
            .addCase(fetchSecuredPassesDatasource.fulfilled, (state, action) => ({
                ...state,
                isFetching: false,
                passes: checkValidity(action.payload),
                error: undefined,
            }))
            .addCase(fetchSecuredPassesDatasource.rejected, (state, action) => ({
                ...state,
                isFetching: false,
                payload: undefined,
                error: action.error,
            }));
    }
);

function checkValidity(payload: any): IPOCPT[] {
    payload.forEach((cpt: IPOCPT) => {
        cpt.PRUCHOD_TERM = cpt.PRUCHOD_TXT || cpt.PRUCHOD_TERM;
    });
    return payload;
}
export interface IPassInsertState {
    isFetching: boolean;
    result: boolean;
    error?: SerializedError;
}

export const passInsertReducer = createReducer<IPassInsertState>(
    {
        isFetching: false,
        result: false,
    },
    builder => {
        builder
            .addCase(insertPass.pending, (state, action) => ({
                ...state,
                isFetching: true,
                result: false,
                error: undefined,
            }))
            .addCase(insertPass.fulfilled, (state, action) => ({
                ...state,
                isFetching: false,
                result: true,
                error: undefined,
            }))
            .addCase(insertPass.rejected, (state, action) => ({
                ...state,
                isFetching: false,
                result: false,
                error: action.error,
            }));
    }
);
