import React, { useEffect, PropsWithChildren } from "react";
import { fetchCenterLevel, initialize, includeSubcentersWithSave, selectCategory } from "../redux/actions/centerPicker";
import { SpinnerBox } from "./Spinner/SpinnerBox";
import { Button } from "@progress/kendo-react-buttons";
import { List, ListItem } from "@material-ui/core";
import classes from "./CenterPicker.module.scss";
import { selectStrings } from "../redux/selectors/selectStrings";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { useAppSelector } from "../hooks/useAppSelector";
import { Checkbox } from "@progress/kendo-react-inputs";
import { useStrings } from "../hooks/useStrings";
import CategoriesPicker from "./CategoriesPicker/CategoriesPicker";
import PermissionGuard from "./PermissionGuard";
import { permissions } from "../constants/permissions";

interface IProps {
    isPresence?: boolean;
}

export function CenterPicker(props: IProps) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(initialize());
    }, [dispatch]);

    return (
        <section className={classes.root}>
            <Header>
                <Navigation />
                <CheckboxContainer>
                    <IncludingSubcentersCheckbox />
                </CheckboxContainer>
            </Header>
            <Body />
            <PermissionGuard permission={permissions.categoryFilter.enable}>
                {!props.isPresence && <CategoriesPicker onSelect={e => dispatch(selectCategory(e.IXS_KAT))} />}
            </PermissionGuard>
        </section>
    );
}

function CheckboxContainer(props: PropsWithChildren<{}>) {
    return <div className={classes.checkboxContainer}>{props.children}</div>;
}

function IncludingSubcentersCheckbox() {
    const dispatch = useAppDispatch();
    const includingSubcenters = useAppSelector(s => s.session.centerPicker.includingSubcenters);
    const strings = useStrings();
    return (
        <Checkbox
            value={includingSubcenters}
            onChange={() => dispatch(includeSubcentersWithSave(!includingSubcenters))}
            label={strings.centerPicker.IncludingSubcenters}
        />
    );
}

function Navigation() {
    const dispatch = useAppDispatch();
    const actual = useAppSelector(s => s.session.centerPicker.loadedCenter);
    const parents = useAppSelector(s => s.session.centerPicker.parents);
    const isLoading = useAppSelector(s => s.session.centerPicker.isFetching);
    return (
        <section className={classes.nav}>
            <Button primary icon="home" disabled={isLoading} onClick={() => dispatch(fetchCenterLevel(null))} />
            {parents.map(center => (
                <Button
                    key={center.id}
                    primary
                    disabled={isLoading}
                    className={classes.href}
                    onClick={() => dispatch(fetchCenterLevel(center.id))}
                >
                    {center.name} {">"}
                </Button>
            ))}
            <Button primary disabled className={classes.selected}>
                {actual?.name}
            </Button>
        </section>
    );
}

function Header(props: PropsWithChildren<{}>) {
    return <header>{props.children}</header>;
}

function Body() {
    const dispatch = useAppDispatch();
    const childrens = useAppSelector(s => s.session.centerPicker.childrens);
    const isLoading = useAppSelector(s => s.session.centerPicker.isFetching);
    const strings = useAppSelector(selectStrings);

    return isLoading ? (
        <SpinnerBox />
    ) : (
        <List className={classes.list}>
            {childrens.length > 0 ? (
                childrens.map(child => (
                    <ListItem
                        key={child.id}
                        divider
                        dense // = úzké
                        button
                        onClick={() => dispatch(fetchCenterLevel(child.id))}
                    >
                        {child.name}
                    </ListItem>
                ))
            ) : (
                <ListItem>{strings.centerPicker.NoSubcenters}!</ListItem>
            )}
        </List>
    );
}
