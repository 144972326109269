import moment from "moment";
import { IMyPlanTimelineGroup } from "../../../model/plan/types";

export function generateGroups(month: Date): IMyPlanTimelineGroup[] {
    const lastDay = moment(month).endOf("month").date();

    const groups: IMyPlanTimelineGroup[] = [];

    let currentDate = moment(month).startOf("month");
    for (let date = 1; date <= lastDay; date++) {
        const day = new Date(month.getFullYear(), month.getMonth(), date);
        groups.push({
            id: day.getTime(),
            title: "",
            day: day,
        });
        currentDate = currentDate.add(1, "day");
    }

    return groups;
}
