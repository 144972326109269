import React, { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "../redux/reducers/root";

interface IProps {
    permission: string;
}

export function PermissionGuard(props: PropsWithChildren<IProps>) {
    const invisiblePermissions = useSelector<IRootState, string[]>(s => {
        const token = s.session.user.token;

        return [...(token?.userInvisibleActions ?? []), ...(token?.userDisabledActions ?? [])];
    });

    const isInvisible = invisiblePermissions.some(p => p === props.permission);

    return <>{isInvisible ? null : props.children}</>;
}

export default PermissionGuard;
