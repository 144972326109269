import React from "react";
import { Notification } from "@progress/kendo-react-notification";
import { SerializedError } from "@reduxjs/toolkit";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import styles from "../components/ErrorNotification.module.scss";
import { IAppError } from "../model/errors/IAppError";
import { selectStrings } from "../redux/selectors/selectStrings";
import { useSelector } from "react-redux";
import { IStrings } from "../constants/languageStrings/IStrings";

/**
 * Komponenta pro chybový text který neobsahuje tzv. bílé znaky.
 */
export function ErrorNotification(props: { error?: SerializedError }) {
    const strings = useSelector(selectStrings);
    let err: IAppError | undefined = undefined;
    if (props.error != null && props.error.message != null) {
        try {
            err = JSON.parse(props.error.message);
        } catch (error) {
            err = undefined;
        }
    }

    return (
        <div className={styles.notification}>
            <Notification type={{ style: "error" }}>
                <PanelBar expandMode={"single"}>
                    <PanelBarItem title={getErrorTitle(err, props.error, strings)} expanded={false}>
                        <div style={{ wordBreak: "break-all" }}>{props.error?.message}</div>
                    </PanelBarItem>
                </PanelBar>
            </Notification>
        </div>
    );
}

export function getErrorTitle(
    err: IAppError | undefined,
    error: SerializedError | undefined,
    strings: IStrings
): string {
    if (err != null) {
        if (err.status >= 500) {
            return strings.common.ErrorNotificationServer;
        }
        if (err.status >= 400 && err.status < 500) {
            return strings.common.ErrorNotificationClient;
        }
    } else if (error?.name === "TypeError") {
        return strings.common.ErrorNotificationClient;
    }
    return strings.common.ErrorNotification;
}

export function ErrorNotificationText(props: { text?: string }) {
    return (
        <Notification type={{ style: "error" }}>
            <div style={{ wordBreak: "break-all" }}>{props.text}</div>
        </Notification>
    );
}
