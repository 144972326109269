import React from "react";
import { ICenter } from "../model/ICenter";
import { IRootState } from "../redux/reducers/root";
import { useSelector } from "react-redux";
import { selectStrings } from "../redux/selectors/selectStrings";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { CenterPicker } from "./CenterPicker";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { selectCenterWithSave } from "../redux/actions/centerPicker";
import classes from "./CenterPickerDialog.module.scss";
import { useAppSelector } from "../hooks/useAppSelector";

interface IProps {
    onClose?: () => void;
    onConfirm?: (center: ICenter, includingSubcenters: boolean) => void;
    isPresence?: boolean;
}

export function CenterPickerDialog(props: IProps) {
    const dispatch = useAppDispatch();
    const center = useSelector<IRootState, ICenter | null>(s => s.session.centerPicker.loadedCenter);
    const strings = useSelector(selectStrings);
    const includingSubcenters = useAppSelector(s => s.session.centerPicker.includingSubcenters);

    function handleConfirmButtonClick() {
        if (center === null) return;

        dispatch(selectCenterWithSave(center));
        props.onConfirm && props.onConfirm(center, includingSubcenters);
    }

    return (
        <Dialog width="100%" height="100%" className={classes.dialog}>
            <CenterPicker isPresence={props.isPresence} />
            <DialogActionsBar>
                <Button onClick={() => props.onClose && props.onClose()}>{strings.common.Close}</Button>
                <Button disabled={center === null} onClick={handleConfirmButtonClick}>
                    {strings.common.Confirm}
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
}
