// eslint-disable-next-line no-extend-native
Date.prototype.toISOStringIgnoringTZ = function () {
    const year = this.getFullYear();
    const month = (this.getMonth() + 1).toString().padStart(2, "0");
    const date = this.getDate().toString().padStart(2, "0");

    const hours = this.getHours().toString().padStart(2, "0");
    const minutes = this.getMinutes().toString().padStart(2, "0");
    const seconds = this.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${date}T${hours}:${minutes}:${seconds}Z`;
};

// eslint-disable-next-line no-extend-native
Date.prototype.toJSON = function () {
    return this.toISOStringIgnoringTZ();
};

// eslint-disable-next-line no-extend-native
Date.prototype.withTime = function (time: Date) {
    return new Date(
        this.getFullYear(),
        this.getMonth(),
        this.getDate(),
        time.getHours(),
        time.getMinutes(),
        time.getSeconds(),
        time.getMilliseconds()
    );
};

// eslint-disable-next-line no-extend-native
Date.prototype.getTimeOfDay = function () {
    return this.getTime() - this.getStartOfDay().getTime();
};

// eslint-disable-next-line no-extend-native
Date.prototype.firstDayOfMonth = function (): Date {
    return new Date(this.getFullYear(), this.getMonth(), 1);
};

// eslint-disable-next-line no-extend-native
Date.prototype.lastDayOfMonth = function (): Date {
    return new Date(this.getFullYear(), this.getMonth() + 1, 0, 23, 59);
};

// eslint-disable-next-line no-extend-native
Date.prototype.getStartOfDay = function (): Date {
    return new Date(this.getFullYear(), this.getMonth(), this.getDate(), 0, 0, 0);
};

// eslint-disable-next-line no-extend-native
Date.prototype.getEndOfDay = function (): Date {
    return new Date(this.getFullYear(), this.getMonth(), this.getDate(), 23, 59);
};

// eslint-disable-next-line no-extend-native
Date.prototype.firstDayOfWeek = function (): Date {
    // set to Monday of this week
    var d: Date = new Date(this.valueOf());
    d.setDate(d.getDate() - ((d.getDay() + 6) % 7));
    d = d.getStartOfDay();
    return d;
};

// eslint-disable-next-line no-extend-native
Date.prototype.lastDayOfWeek = function (): Date {
    // set to Monday of this week
    var d: Date = new Date(this.valueOf());
    d.setDate(d.getDate() - ((d.getDay() + 6) % 7) + 6);
    d = d.getEndOfDay();
    return d;
};

// eslint-disable-next-line no-extend-native
Date.prototype.addSeconds = function (seconds: number): Date {
    return new Date(this.getTime() + seconds * 1000);
};

// eslint-disable-next-line no-extend-native
Date.prototype.addMinutes = function (minutes: number): Date {
    return new Date(this.getTime() + minutes * 60 * 1000);
};

// eslint-disable-next-line no-extend-native
Date.prototype.addHours = function (hours: number): Date {
    return new Date(this.getTime() + hours * 60 * 60 * 1000);
};

// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function (days: number): Date {
    if (this === undefined) return this;
    var result = new Date(this.getTime());
    result.setDate(result.getDate() + days);
    return result;
};

// eslint-disable-next-line no-extend-native
Date.prototype.addMonths = function (months: number): Date {
    if (this === undefined) {
        return this;
    }

    var dt = new Date(this.getFullYear(), this.getMonth() + 1 + months, 0);
    dt.setDate(Math.min(dt.getDate(), this.getDate()));
    return dt;
};

// eslint-disable-next-line no-extend-native
Date.prototype.addYears = function (years: number): Date {
    if (this === undefined) return this;
    return new Date(this.getFullYear() + years, this.getMonth(), this.getDate());
};

// eslint-disable-next-line no-extend-native
Date.prototype.getTimeOfDayValue = function (): number {
    var res = (this.valueOf() - this.getTimezoneOffset() * 60 * 1000) % 86400000;
    if (res < 0) {
        //před 1970
        res = 24 * 60 * 60 * 1000 + res;
    }

    return res;
};

// eslint-disable-next-line no-extend-native
Date.prototype.getFullDateString = function (): string {
    let today: Date = new Date();
    return today.getFullYear() + "_" + (today.getMonth() + 1) + "_" + today.getDate();
};

// eslint-disable-next-line no-extend-native
Date.prototype.addTime = function (time: Date): Date {
    return new Date(this.valueOf() + time.getTimeOfDayValue());
};

export default null;
