import React from "react";
import { Calendar, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { connect } from "react-redux";
import { IRootState } from "../../redux/reducers/root";
import { Dialog } from "@progress/kendo-react-dialogs";
import styles from "./NavbarMonthPicker.module.scss";
import { IntlProvider, loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";
import { ILanguage } from "../../model/localization/ILanguage";
import { Button } from "@progress/kendo-react-buttons";
import ReactDOM from "react-dom";

import csMessages from "../../assets/cs.json";
loadMessages(csMessages, "cs");

interface IOwnProps {
    onPick: (month: Date) => void;
    selectedMonth: Date;
}

interface IStateProps {
    language: ILanguage;
}

type IProps = IOwnProps & IStateProps;

function NavbarMonthPicker(props: IProps) {
    const [isOpen, setIsOpen] = React.useState(false);

    const handleChange = (event: CalendarChangeEvent) => {
        setIsOpen(false);

        const month = new Date(event.value.getFullYear(), event.value.getMonth(), 1);
        props.onPick(month);
    };

    const month = new Date(props.selectedMonth.getFullYear(), props.selectedMonth.getMonth(), 1);
    return (
        <>
            <Button onClick={() => setIsOpen(true)}>
                <span>
                    {props.selectedMonth.toLocaleString(props.language.code, {
                        month: "long",
                    })}
                </span>
                <span className={styles.year}>&nbsp;{props.selectedMonth.getFullYear()}</span>
            </Button>
            {isOpen &&
                ReactDOM.createPortal(
                    <Dialog className={styles.window} onClose={() => setIsOpen(false)} autoFocus>
                        <LocalizationProvider language={props.language.code}>
                            <IntlProvider locale={props.language.code}>
                                <Calendar
                                    bottomView="year"
                                    topView="decade"
                                    navigation={false}
                                    defaultValue={month}
                                    onChange={handleChange}
                                />
                            </IntlProvider>
                        </LocalizationProvider>
                    </Dialog>,
                    document.body
                )}
        </>
    );
}

export default connect<IStateProps, {}, IOwnProps, IRootState>(state => ({
    language: state.localization.language,
}))(NavbarMonthPicker);
