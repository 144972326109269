import React from "react";
import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { connect } from "react-redux";
import { IRootState } from "../../redux/reducers/root";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import { ILanguage } from "../../model/localization/ILanguage";

import csMessages from "../../assets/cs.json";
loadMessages(csMessages, "cs");

interface IOwnProps {
    onPick: (day: Date) => void;
    selectedDay: Date;
}

interface IStateProps {
    language: ILanguage;
}

type IProps = IOwnProps & IStateProps;

function TranslatedDatePicker(props: IProps) {
    const handleChange = (event: DatePickerChangeEvent) => {
        if (event.value !== null) {
            const day = new Date(event.value);
            props.onPick(day);
        }
    };

    let day: Date = new Date(props.selectedDay);
    return (
        <>
            <LocalizationProvider language={props.language.code}>
                <IntlProvider locale={props.language.code}>
                    <DatePicker value={day} onChange={handleChange} />
                </IntlProvider>
            </LocalizationProvider>
        </>
    );
}

export default connect<IStateProps, {}, IOwnProps, IRootState>(state => ({
    language: state.localization.language,
}))(TranslatedDatePicker);
