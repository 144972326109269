import React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { selectUserFullname } from "../../../redux/selectors/selectUserFullname";
import classes from "./ChangeRequestDialog.module.scss";
import { useStrings } from "../../../hooks/useStrings";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { ErrorNotification } from "../../../components/ErrorNotification";
import { useFormatDate } from "../../../hooks/useFormatDate";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { updateChangeRequests } from "../../../redux/actions/plan/changing";
import { unwrapResult } from "@reduxjs/toolkit";

interface IOwnProps {
    onClose?: () => void;
    onUpdated?: () => void;
}

type Props = IOwnProps;

function ChangeRequestDialog(props: Props) {
    const strings = useStrings();
    const isUpdating = useAppSelector(s => s.session.plan.changing.isUpdating);
    const error = useAppSelector(s => s.session.plan.changing.error);
    const fullname = useAppSelector(selectUserFullname);
    const requests = useAppSelector(s => s.session.plan.my.currentPage?.changeRequests ?? []);
    const customizableText = useAppSelector(s => s.session.plan.my.currentPage?.customizableTexts.change ?? "");
    const formatDate = useFormatDate();
    const dispatch = useAppDispatch();

    function updateRequest(approved: boolean) {
        const ids = requests.map(r => r.id);

        dispatch(
            updateChangeRequests({
                ids: ids,
                approved: approved,
            })
        )
            .then(unwrapResult)
            .then(() => props.onUpdated && props.onUpdated());
    }

    return (
        <Dialog
            title={strings.plan.ApprovalOfPlanChanges}
            autoFocus
            closeIcon={false}
            onClose={() => !isUpdating && props.onClose && props.onClose()}
        >
            <p>
                <span>{strings.common.Person}: </span>
                <b>{fullname}</b>
            </p>
            {requests.map(r => (
                <p key={r.id}>
                    <span>{strings.common.Day}: </span>
                    <b>
                        {formatDate(new Date(r.day), "d")}, {r.intervals[0] != null ? r.intervals[0].name : ""}
                    </b>
                </p>
            ))}
            <p>
                <span>{strings.plan.PlanWasChangedBy}: </span>
                <b>{requests[0].fullname_zadal}</b>
            </p>
            {customizableText && (
                <p className={classes.customizableText}>
                    <b>{customizableText}</b>
                </p>
            )}
            {error && <ErrorNotification error={error} />}
            <DialogActionsBar>
                <Button disabled={isUpdating} onClick={props.onClose}>
                    {strings.common.Close}
                </Button>
                <Button
                    icon={isUpdating ? "loading" : undefined}
                    disabled={isUpdating}
                    onClick={() => updateRequest(false)}
                    style={{ color: "red" }}
                >
                    {strings.common.Deny}
                </Button>
                <Button
                    icon={isUpdating ? "loading" : undefined}
                    disabled={isUpdating}
                    onClick={() => updateRequest(true)}
                    style={{ color: "green" }}
                >
                    {strings.common.Approve}
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
}

export default ChangeRequestDialog;
