import React from "react";
import { DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import styles from "./LanguageDropDown.module.css";
import { ILanguage } from "../../model/localization/ILanguage";
import { languages } from "../../constants/languages";
import { localizationSlice } from "../../redux/slices/localization";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";

interface Props {
    label?: string;
}

export function LanguageDropDown(props: Props) {
    function renderRow(lang: ILanguage) {
        return (
            <div className={styles.row}>
                <b className={styles.shortcut}>{lang.shortcut}</b>
                <img src={lang.iconSrc} alt={lang.shortcut} width={25} height={17}></img>
            </div>
        );
    }

    function renderItem(li: React.ReactElement<HTMLLIElement>, props: ListItemProps) {
        const lang = props.dataItem as ILanguage;

        return React.cloneElement(li, li.props, renderRow(lang));
    }

    function renderValue(span: React.ReactElement<HTMLSpanElement>, language: ILanguage) {
        if (language == null) return span;
        else return React.cloneElement(span, span.props, renderRow(language));
    }

    const language = useAppSelector(s => s.localization.language);
    const dispatch = useAppDispatch();
    return (
        <DropDownList
            label={props.label}
            data={languages.slice()} // make copy of array
            defaultValue={language}
            dataItemKey="id"
            textField="shortcut"
            itemRender={renderItem}
            valueRender={renderValue}
            onChange={e => dispatch(localizationSlice.actions.set(e.value))}
        />
    );
}

export default LanguageDropDown;
