import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseError } from "../../helpers/handleResponseError";
import { IRootState } from "../reducers/root";
import { OvertimeRequest } from "../reducers/overtime";

const DOMAIN = "overtime";

export const fetchOvertime = createAsyncThunk<OvertimeRequest[], void, { state: IRootState }>(
    `${DOMAIN}/fetchOvertime`,
    async function (arg, api) {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
        const requestOptions: RequestInit = {
            method: "GET",
            headers: headers,
        };

        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/GetOvertime",
            requestOptions
        );

        await handleResponseError(response);

        return await response.json();
    }
);

export const setOvertimeStatus = createAsyncThunk<string, { Id: number; Approve: boolean }, { state: IRootState }>(
    `${DOMAIN}/setOvertimeStatus`,
    async function (arg, api) {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
        const requestOptions: RequestInit = {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                Item1: arg.Id,
                Item2: arg.Approve,
            }),
        };

        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/SetOvertimeStatus",
            requestOptions
        );

        await handleResponseError(response);

        return await response.json();
    }
);
