import { IRootState } from "../redux/reducers/root";
import { handleResponseError } from "./handleResponseError";

export default async function GetResponseJson(
    api: { getState: () => IRootState },
    adress: string,
    options: RequestInit
) {
    const response = await fetch(api.getState().config.config.endpoint + adress, options);
    await handleResponseError(response);
    return await response.json();
}
