import React from "react";
import { DropDownList, DropDownListFilterChangeEvent, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { connect } from "react-redux";
import { IRootState } from "../../redux/reducers/root";
import { IStrings } from "../../constants/languageStrings/IStrings";
import { IporefForWidget } from "../../model/IporefForWidget";
import { fetchIporefsForWFL, fetchIporefsForWFLWithFilter } from "../../redux/actions/requestsForApproval";
import { AppDispatch } from "../..";

interface IOwnProps {
    data?: IporefForWidget[];
    isFilterEnabled?: boolean;
    onSelectIxsref?: (person: IporefForWidget) => void;
    disabled?: boolean;
    isRequest: boolean;
}

interface IDispatchProps {
    onLoadNewSubset: (e: { isRequest: boolean }) => Promise<any>;
    onFilterRemote: (e: { filter: string; isRequest: boolean }) => Promise<any>;
}

interface IStateProps {
    strings: IStrings;
    datasource?: IporefForWidget[];
    isDatasourceFetching?: boolean;

    isFetchingFilter?: boolean;
    filteredData?: IporefForWidget[];
}

type IProps = IOwnProps & IDispatchProps & IStateProps;

class PersonsForApprovalDropdown extends React.Component<IProps, {}> {
    state = {
        isFilterEnabled: false,
        data: this.props.datasource,
    };

    async componentDidMount() {
        await this.props.onLoadNewSubset({ isRequest: this.props.isRequest });
        this.setState({ data: this.props.datasource });
    }

    onFilterChange = async (event: DropDownListFilterChangeEvent) => {
        if (event.filter.value.length > 2) {
            await this.props.onFilterRemote({ filter: event.filter.value, isRequest: this.props.isRequest });
            this.setState({
                isFilterEnabled: true,
                data: this.props.filteredData,
            });
        } else if (event.filter.value.length === 2 && this.state.isFilterEnabled) {
            this.setState({
                isFilterEnabled: false,
                data: this.props.datasource,
            });
        }
    };

    handleChange(change: DropDownListChangeEvent): void {
        this.props.onSelectIxsref && this.props.onSelectIxsref(change.value);
    }

    public render() {
        return (
            <DropDownList
                data={this.state.data}
                label={this.props.strings.requests.ForPerson}
                dataItemKey="IXS_REF"
                textField={"OcFullName"}
                loading={this.props.isDatasourceFetching || this.props.isFetchingFilter}
                filterable={true}
                onFilterChange={event => this.onFilterChange(event)}
                onChange={change => this.handleChange(change)}
                popupSettings={{
                    height: "288px",
                }}
                disabled={this.props.disabled}
            />
        );
    }
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IRootState>(
    state => ({
        strings: state.localization.strings,

        datasource: state.session.personsForApproval.datasource,

        isDatasourceFetching: state.session.personsForApproval.fetching,

        filteredData: state.session.personsForApprovalWithFilter.datasource,
        isFetchingFilter: state.session.personsForApprovalWithFilter.fetching,
    }),
    (dispatch: AppDispatch) => ({
        onFilterRemote: (e: { filter: string; isRequest: boolean }) => dispatch(fetchIporefsForWFLWithFilter(e)),
        onLoadNewSubset: (e: { isRequest: boolean }) => dispatch(fetchIporefsForWFL(e)),
    })
)(PersonsForApprovalDropdown);
