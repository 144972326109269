import React, { useMemo } from "react";
import classes from "./VerticalLines.module.scss";

interface IProps {
    count: number;
}

export const VerticalLines = (props: IProps) => {
    const sections = useMemo<React.ReactNode>(() => {
        const parts: null[] = [];
        for (let i = 1; i <= props.count; i++) {
            parts.push(null);
        }

        return (
            <>
                {parts.map((empty, index) => (
                    <div key={index} className={classes.item}></div>
                ))}
            </>
        );
    }, [props.count]);

    return <div className={classes.container}>{sections}</div>;
};
