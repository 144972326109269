export interface WflReq {
    Applicant: string;
    Approver: string;
    Created: string;
    DateModified: string;
    ID: number;
    Note: string;
    RemainingDays: number;
    RemainingDaysFloor: number;
    ReqText: string;
    State: number;
    Expdate: string;
}

export enum RequestsAgend {
    Requests,
    Unavalabilities,
}
