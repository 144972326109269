import React from "react";
import { HoursHeader } from "../headers/HoursHeader";
import classes from "./ColumnCell.module.scss";
import { IColumnConfig } from "../types";
import { DayHeader } from "../headers/DayHeader";

interface IProps {
    column: IColumnConfig;
    columnIndex: number;
    dayHeaderContentRenderer?: (props: { column: IColumnConfig }) => React.ReactNode;
}

export const ColumnCell = (props: IProps) => (
    <div
        className={classes.cell}
        style={{
            gridRowStart: 1,
            gridRowEnd: 1,
            gridColumnStart: props.columnIndex,
            gridColumnEnd: props.columnIndex,
        }}
    >
        <DayHeader column={props.column} contentRenderer={props.dayHeaderContentRenderer} />
        <HoursHeader />
    </div>
);
