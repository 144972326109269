import React from "react";
import { IAccount } from "../../../model/plan/types";
import classes from "./Accounts.module.scss";
import { useStrings } from "../../../hooks/useStrings";

interface IOwnProps {
    accounts: IAccount[];
}

function Accounts(props: IOwnProps) {
    const strings = useStrings();
    return (
        <React.Fragment>
            <span className="text-primary">{strings.plan.Legend}</span>
            <table className={classes.table}>
                <tbody>
                    {props.accounts.map(acc => (
                        <tr key={acc.id}>
                            <td
                                className={classes.colorCell}
                                style={{
                                    backgroundColor: acc.color,
                                }}
                            ></td>
                            <td className={classes.title}>{acc.name + (acc.shortcut ? ` (${acc.shortcut})` : "")}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </React.Fragment>
    );
}

export default Accounts;
