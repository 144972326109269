import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRootState } from "../../reducers/root";
import { handleResponseError } from "../../../helpers/handleResponseError";
import { APP_VERSION } from "../../../constants/common";

export const updateChangeRequests = createAsyncThunk<void, { ids: number[]; approved: boolean }, { state: IRootState }>(
    "plan/my/change/requests/update",
    async (arg, api) => {
        const requestOptions: RequestInit = {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${api.getState().session.user.token?.access_token}`,
            },
            body: JSON.stringify({
                Item1: arg.ids,
                Item2: arg.approved,
                Item3: APP_VERSION,
            }),
            signal: api.signal,
        };

        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/UpdatePlanChangeRequests",
            requestOptions
        );

        await handleResponseError(response);
    }
);
