import { SerializedInterval, IAccount } from "../../../model/plan/types";
import { Color } from "../../../model/Color";

export function resolveAccounts(intrs: SerializedInterval[]): IAccount[] {
    const legends: IAccount[] = [];
    const map: { [AccountID: number]: boolean } = {};

    intrs.forEach(intr => {
        if (intr.accountID && !map[intr.accountID]) {
            legends.push({
                id: intr.accountID,
                name: intr.name.trim(),
                shortcut: intr.shortcut?.trim(),
                color: Color.fromPbColor(intr.color).toCSSRGBA(),
            });
            map[intr.accountID] = true;
        }
    });

    return legends;
}
