import React, { MouseEventHandler } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import styles from "./ChangePersonDialog.module.css";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { IStrings } from "../../constants/languageStrings/IStrings";
import { connect } from "react-redux";
import { IRootState } from "../../redux/reducers/root";

interface IOwnProps {
    onClose?: MouseEventHandler;
}

interface IStateProps {
    strings: IStrings;
}

class ChangePersonDialog extends React.Component<IOwnProps & IStateProps> {
    private readonly persons = [];

    public render() {
        return (
            <Dialog title={this.props.strings.dashboard.ChangePerson} width="90%" closeIcon={false}>
                <DropDownList
                    label={this.props.strings.common.For}
                    className={styles.flexGrow1}
                    data={this.persons}
                    defaultValue={this.persons[0]}
                />
                <DialogActionsBar>
                    <Button onClick={this.props.onClose}>{this.props.strings.common.Cancel}</Button>
                    <Button disabled={true} onClick={this.props.onClose}>
                        {this.props.strings.common.Change}
                    </Button>
                </DialogActionsBar>
            </Dialog>
        );
    }
}

export default connect<IStateProps, {}, IOwnProps, IRootState>(state => ({
    strings: state.localization?.strings,
}))(ChangePersonDialog);
