import { createReducer } from "@reduxjs/toolkit";
import { insertAbsencesToMultipleDates } from "../actions/absenceInsertion";

export interface IAbsenceInsertionState {
    inserting: boolean;
    response?: unknown;
    error?: unknown;
}

export const absenceInsertionReducer = createReducer<IAbsenceInsertionState>(
    {
        inserting: false,
    },
    builder =>
        builder
            .addCase(insertAbsencesToMultipleDates.pending, (state, action) => ({
                ...state,
                inserting: true,
                response: undefined,
                error: undefined,
            }))
            .addCase(insertAbsencesToMultipleDates.fulfilled, (state, action) => ({
                ...state,
                inserting: false,
                response: action.payload,
            }))
            .addCase(insertAbsencesToMultipleDates.rejected, (state, action) => ({
                ...state,
                inserting: false,
                error: action.payload,
            }))
);
