import React from "react";
import { Notification } from "@progress/kendo-react-notification";
import { useSelector } from "react-redux";
import { IRootState } from "../redux/reducers/root";
import { ICenter } from "../model/ICenter";
import { selectStrings } from "../redux/selectors/selectStrings";
import { useAppSelector } from "../hooks/useAppSelector";

export function TooMuchPersonNotification() {
    const center = useSelector<IRootState, ICenter | null>(s => s.session.centerPicker.loadedCenter);
    const strings = useSelector(selectStrings);
    const includingSubcenters = useAppSelector(s => s.session.centerPicker.includingSubcenters);

    return (
        <Notification type={{ icon: true, style: "warning" }}>
            {strings.presence.MaxCount.replace(
                "{0}",
                center?.name +
                    (includingSubcenters ? `, ${strings.centerPicker.IncludingSubcenters.toLocaleLowerCase()}` : "")
            )}
        </Notification>
    );
}
