import React from "react";
import { Calendar, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { connect } from "react-redux";
import { IRootState } from "../../redux/reducers/root";
import { Dialog } from "@progress/kendo-react-dialogs";
import styles from "../MonthPicker/MonthPicker.module.scss";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import { ILanguage } from "../../model/localization/ILanguage";
import { Button } from "@progress/kendo-react-buttons";

import csMessages from "../../assets/cs.json";
loadMessages(csMessages, "cs");

interface IOwnProps {
    onPick: (month: Date) => void;
    selectedMonth: Date;
}

interface IStateProps {
    language: ILanguage;
}

type IProps = IOwnProps & IStateProps;

function MonthPicker(props: IProps) {
    const [isOpen, setIsOpen] = React.useState(false);

    const handleChange = (event: CalendarChangeEvent) => {
        setIsOpen(false);

        const month = new Date(event.value.getFullYear(), event.value.getMonth(), 1);
        props.onPick(month);
    };

    const month = new Date(props.selectedMonth.getFullYear(), props.selectedMonth.getMonth(), 1);
    return (
        <>
            <div className={styles.flex}>
                <Button className={styles.monthButt} onClick={() => setIsOpen(true)}>
                    <span>
                        {props.selectedMonth.toLocaleString(props.language.code, {
                            month: "long",
                        })}
                        &nbsp;{props.selectedMonth.getFullYear()}
                    </span>
                </Button>
                <Button
                    icon={"arrow-left"}
                    className={styles.buttons}
                    onClick={() => props.onPick(props.selectedMonth.addMonths(-1))}
                ></Button>
                <Button
                    icon={"arrow-right"}
                    className={styles.buttons}
                    onClick={() => props.onPick(props.selectedMonth.addMonths(1))}
                ></Button>
            </div>
            {isOpen && (
                <div className={styles.window}>
                    <Dialog>
                        <LocalizationProvider language={props.language.code}>
                            <IntlProvider locale={props.language.code}>
                                <Calendar
                                    bottomView="year"
                                    topView="decade"
                                    navigation={false}
                                    defaultValue={month}
                                    onChange={handleChange}
                                />
                            </IntlProvider>
                        </LocalizationProvider>
                    </Dialog>
                </div>
            )}
        </>
    );
}

export default connect<IStateProps, {}, IOwnProps, IRootState>(state => ({
    language: state.localization.language,
}))(MonthPicker);
