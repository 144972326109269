import React from "react";
import { IInterval } from "../types";
import { time2minutes } from "../utils";
import styles from "./GridPositioner.module.scss";
import { Interval } from "../intervals/Interval";

export function GridPositioner(props: { interval: IInterval }) {
    const beginPerct = (100 / 1440) * time2minutes(props.interval.beginTime);
    const endPerct = 100 - (100 / 1440) * time2minutes(props.interval.endTime);

    return (
        <div
            className={styles.positioner}
            style={{
                paddingLeft: beginPerct + "%",
                paddingRight: endPerct + "%",
                zIndex: props.interval.layer ?? undefined,
            }}
        >
            <Interval interval={props.interval} />
        </div>
    );
}
