import { createReducer, SerializedError } from "@reduxjs/toolkit";
import { fetchOvertime } from "../actions/overtime";

export interface OvertimeRequest {
    id: number;
    date: string;
    start: string;
    end: string;
    note: string;
    approved: boolean;
    approvable: boolean;
    accountName: string;
}

export interface IOvertimeState {
    isFetching: boolean;
    data: OvertimeRequest[];
    error?: SerializedError;
}

export const overtimeReducer = createReducer<IOvertimeState>(
    {
        isFetching: false,
        data: [],
    },
    builder => {
        builder
            .addCase(fetchOvertime.pending, (state, action) => ({
                ...state,
                isFetching: true,
                data: [],
                error: undefined,
            }))
            .addCase(fetchOvertime.fulfilled, (state, action) => ({
                ...state,
                isFetching: false,
                data: action.payload,
                error: undefined,
            }))
            .addCase(fetchOvertime.rejected, (state, action) => ({
                ...state,
                isFetching: false,
                data: [],
                error: action.error,
            }));
    }
);
