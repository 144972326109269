import React, { PropsWithChildren } from "react";
import styles from "./Stack.module.scss";
import { IInterval } from "../types";
import { GridPositioner } from "../positioning/GridPositioner";
import { generateDummyInterval } from "../generators";
import { AbsolutePositioner } from "../positioning/AbsolutePositioner";

const dummy = generateDummyInterval();

type IProps = PropsWithChildren<{ intervals: IInterval[] }>;

interface IState {
    width: number;
}

export class Stack extends React.Component<IProps, IState> {
    private ref = React.createRef<HTMLDivElement>();

    public state: Readonly<IState> = {
        width: 0,
    };

    private handleResize = (e: Event) => {
        this.setState({ width: this.ref.current?.clientWidth ?? 0 });
    };

    componentDidMount() {
        this.setState({ width: this.ref.current?.clientWidth ?? 0 });

        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    public render() {
        return (
            <div ref={this.ref} className={styles.stack}>
                {this.props.intervals.map(intr => (
                    <AbsolutePositioner key={intr.id} stackWidth={this.state.width} interval={intr} />
                ))}
                <GridPositioner interval={dummy} />
            </div>
        );
    }
}
