import React, { PropsWithChildren } from "react";

interface IProps {
    row: {
        start: number;
        end: number;
    };
    column: {
        start: number;
        end: number;
    };
}

export const GridItem = (props: PropsWithChildren<IProps>) => (
    <div
        style={{
            gridRowStart: props.row.start,
            gridRowEnd: props.row.end,
            gridColumnStart: props.column.start,
            gridColumnEnd: props.column.end,
            overflow: "hidden",
        }}
    >
        {props.children}
    </div>
);
