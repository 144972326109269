import React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { confirmSignRequests } from "../../../redux/actions/plan/signing";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectUserFullname } from "../../../redux/selectors/selectUserFullname";
import { ErrorNotification } from "../../../components/ErrorNotification";
import classes from "./SigningDialog.module.scss";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useStrings } from "../../../hooks/useStrings";
import { useFormatDate } from "../../../hooks/useFormatDate";

interface IOwnProps {
    onClose?: () => void;
    onSigned?: () => void;
}

type Props = IOwnProps;

function SigningDialog(props: Props) {
    const dispatch = useAppDispatch();
    const strings = useStrings();
    const formatDate = useFormatDate();
    const isSigning = useAppSelector(s => s.session.plan.signing.isSigning);
    const error = useAppSelector(s => s.session.plan.signing.error);
    const fullname = useAppSelector(selectUserFullname);
    const requests = useAppSelector(s => s.session.plan.my.currentPage?.signingRequests ?? []);
    const customizableText = useAppSelector(s => s.session.plan.my.currentPage?.customizableTexts.sign ?? "");

    const handleSignButtonClick = () => {
        const ids = requests.map(r => r.id);

        dispatch(confirmSignRequests(ids))
            .then(unwrapResult)
            .then(() => props.onSigned && props.onSigned());
    };

    return (
        <Dialog
            title={strings.plan.AcquaintanceWithPlan}
            autoFocus
            closeIcon={false}
            onClose={() => !isSigning && props.onClose && props.onClose()}
        >
            <p>
                <span>{strings.common.Person}: </span>
                <b>{fullname}</b>
            </p>
            <p>
                <span>{strings.worksheet.Period}: </span>
                {requests
                    .map(r => (
                        <b key={r.id}>
                            {formatDate(new Date(r.begin), "d")}
                            <span> - </span>
                            {formatDate(new Date(r.end), "d")}
                        </b>
                    ))
                    .reduce((prev, curr) => (
                        <>
                            {prev}, {curr}
                        </>
                    ))}
            </p>
            <p>
                <span>{strings.plan.WasSubmittedBy}: </span>
                <b>{requests[0].fullname_zadal + " " + formatDate(new Date(requests[0].datum_zadal), "d")}</b>
            </p>
            {customizableText && (
                <p className={classes.customizableText}>
                    <b>{customizableText}</b>
                </p>
            )}
            {error && <ErrorNotification error={error} />}
            <DialogActionsBar>
                <Button disabled={isSigning} onClick={props.onClose}>
                    {strings.common.Close}
                </Button>
                <Button
                    icon={isSigning ? "loading" : "check"}
                    disabled={isSigning}
                    onClick={handleSignButtonClick}
                    style={{ color: "orange" }}
                >
                    {strings.common.Confirm}
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
}

export default SigningDialog;
