import { ThunkMiddleware } from "redux-thunk";
import { reportError } from "../actions/errors";
import { serializeError } from "serialize-error";
import { IRootState } from "../reducers/root";

export const crashReporter: ThunkMiddleware<IRootState> = api => next => action => {
    try {
        return next(action);
    } catch (e) {
        console.error("ANeT.SelfService:", e);

        api.dispatch(reportError(serializeError(e)));

        throw e;
    }
};
