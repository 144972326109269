import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseError } from "../../helpers/handleResponseError";
import { LastPass } from "../../model/terminal/IPOPRT";
import { IRootState } from "../reducers/root";

const DOMAIN = "LastPasses";

export const fetchLastPasses = createAsyncThunk<LastPass[], void, { state: IRootState }>(
    `${DOMAIN}/fetchLastPasses`,
    async (arg, api) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
        const requestOptions: RequestInit = {
            method: "GET",
            headers: headers,
        };

        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/SelfService_GetLastPasses",
            requestOptions
        );

        await handleResponseError(response);

        return await response.json();
    }
);
