import React from "react";
import ReactDOM from "react-dom";
import { Calendar, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { connect } from "react-redux";
import { IRootState } from "../../redux/reducers/root";
import { Dialog } from "@progress/kendo-react-dialogs";
import styles from "./NavbarDayPicker.module.scss";
import { IntlProvider } from "@progress/kendo-react-intl";
import { ILanguage } from "../../model/localization/ILanguage";
import { Button } from "@progress/kendo-react-buttons";
import { formatDate } from "@telerik/kendo-intl";

interface IOwnProps {
    onPick: (day: Date) => void;
    selectedDay: Date;
    labelRenderer?: (day: Date) => React.ReactNode;
}

interface IStateProps {
    language: ILanguage;
}

type IProps = IOwnProps & IStateProps;

function NavbarDayPicker(props: IProps) {
    const [isOpen, setIsOpen] = React.useState(false);
    const [day, setDay] = React.useState(props.selectedDay);

    const handleChange = (event: CalendarChangeEvent) => {
        setIsOpen(false);

        const day = new Date(event.value.getFullYear(), event.value.getMonth(), event.value.getDate());
        setDay(day);
        props.onPick(day);
    };

    const month = new Date(props.selectedDay.getFullYear(), props.selectedDay.getMonth(), props.selectedDay.getDate());
    return (
        <>
            <Button onClick={() => setIsOpen(true)}>
                {(props.labelRenderer && props.labelRenderer(day)) ??
                    formatDate(props.selectedDay, "m", props.language.code)}
            </Button>
            {isOpen &&
                ReactDOM.createPortal(
                    <Dialog className={styles.dialog} autoFocus onClose={() => setIsOpen(false)}>
                        <IntlProvider locale={props.language.code}>
                            <Calendar
                                bottomView="month"
                                topView="decade"
                                navigation={false}
                                defaultValue={month}
                                onChange={handleChange}
                            />
                        </IntlProvider>
                    </Dialog>,
                    document.body
                )}
        </>
    );
}

export default connect<IStateProps, {}, IOwnProps, IRootState>(state => ({
    language: state.localization.language,
}))(NavbarDayPicker);
