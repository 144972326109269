import { IRootState } from "../reducers/root";
import { fetchToken, logout } from "../actions/user";
import { ThunkMiddleware } from "redux-thunk";
import { VENDOR, APP_NAME } from "../../constants/common";

export const KEY_REFRESH_TOKEN = `${VENDOR}|${APP_NAME}|refreshToken`;

export const tokenPersistor: ThunkMiddleware<IRootState> = store => next => action => {
    const result = next(action);

    switch (action.type) {
        case fetchToken.fulfilled.type:
            sessionStorage.setItem(KEY_REFRESH_TOKEN, store.getState().session.user.token?.refresh_token ?? "");
            break;
        case fetchToken.rejected.type:
        case logout.type:
            sessionStorage.removeItem(KEY_REFRESH_TOKEN);
            break;
    }

    return result;
};
