import { createReducer, SerializedError } from "@reduxjs/toolkit";
import { updateChangeRequests } from "../../actions/plan/changing";

export interface IPlanChangeState {
    isUpdating: boolean;
    requestID?: string;
    error?: SerializedError;
}

export const changingReducer = createReducer<IPlanChangeState>(
    {
        isUpdating: false,
    },
    builder =>
        builder
            .addCase(updateChangeRequests.pending, (state, action) => ({
                isUpdating: true,
                requestID: action.meta.requestId,
                error: undefined,
            }))
            .addCase(updateChangeRequests.fulfilled, (state, action) =>
                state.requestID === action.meta.requestId
                    ? {
                          isUpdating: false,
                          error: undefined,
                      }
                    : state
            )
            .addCase(updateChangeRequests.rejected, (state, action) =>
                state.requestID === action.meta.requestId
                    ? {
                          isUpdating: false,
                          error: action.error,
                      }
                    : state
            )
);
