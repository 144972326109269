import React, { useContext } from "react";
import classes from "./HoursHeader.module.scss";
import { HoursMultiplierContext } from "../context/HoursMultiplierContext";

export function HoursHeader() {
    const multiplier = useContext(HoursMultiplierContext);

    const hours: number[] = [];

    for (let hour = 0; hour <= 22; hour += multiplier) {
        hours.push(hour);
    }

    return (
        <div className={classes.container}>
            {hours.map(hour => (
                <div key={hour} className={classes.item}>
                    {hour.toString().padStart(2, "0")}
                </div>
            ))}
        </div>
    );
}
