import React, { PropsWithChildren } from "react";
import { ErrorNotificationText } from "../../components/ErrorNotification";
import { useStrings } from "../../hooks/useStrings";
import { useAppSelector } from "../../hooks/useAppSelector";

export function LicenseGuard(props: PropsWithChildren<{}>) {
    const isAppEnabled = useAppSelector(s => s.app.isEnabled);
    const strings = useStrings();

    if (!isAppEnabled) {
        return <ErrorNotificationText text={strings.app.Msg_AppDisabledByLicense} />;
    }

    return <>{props.children}</>;
}

export default LicenseGuard;
