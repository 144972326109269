import { createReducer } from "@reduxjs/toolkit";
import { resetCategory, selectCategory } from "../../actions/centerPicker";
import { fetchPossibleCategories } from "../../actions/dropdowns/categories";

export interface ICategoriesState {
    isFetching: boolean;
    categories: IPOREFKAT[];
    selected: number;
    error?: unknown;
}

export const categoriesReducer = createReducer<ICategoriesState>(
    {
        isFetching: false,
        categories: [],
        selected: 0,
    },
    builder => {
        builder
            .addCase(fetchPossibleCategories.pending, (state, action) => ({
                ...state,
                isFetching: true,
                categories: [],
                error: undefined,
            }))
            .addCase(fetchPossibleCategories.fulfilled, (state, action) => ({
                ...state,
                isFetching: false,
                categories: GetResultsFromRawData(action.payload),
                error: undefined,
            }))
            .addCase(fetchPossibleCategories.rejected, (state, action) => ({
                ...state,
                isFetching: false,
                categories: [],
                error: action.payload,
            }))
            .addCase(selectCategory, (state, action) => ({
                ...state,
                selected: action.payload,
            }))
            .addCase(resetCategory, (state, action) => ({
                ...state,
                selected: 0,
            }));
    }
);

function GetResultsFromRawData(payload: any): IPOREFKAT[] {
    return payload;
}

export interface IPOREFKAT {
    IXS_KAT: number;
    NAZEV_KAT: string;
}
