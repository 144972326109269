import { createReducer, SerializedError } from "@reduxjs/toolkit";
import { fetchDashboardData } from "../actions/dashboard";
import { IDashboardData } from "../../model/dashboard/IDashboardData";

export interface IDashboardState {
    isFetching: boolean;
    requestID?: string;
    data?: IDashboardData;
    error?: SerializedError;
}

export const dashboardReducer = createReducer<IDashboardState>(
    {
        isFetching: false,
    },
    builder => {
        return builder
            .addCase(fetchDashboardData.pending, (state, action) => ({
                ...state,
                isFetching: true,
                requestID: action.meta.requestId,
                error: undefined,
            }))
            .addCase(fetchDashboardData.fulfilled, (state, action) =>
                state.requestID === action.meta.requestId
                    ? {
                          ...state,
                          isFetching: false,
                          data: action.payload,
                          error: undefined,
                      }
                    : state
            )
            .addCase(fetchDashboardData.rejected, (state, action) =>
                state.requestID === action.meta.requestId
                    ? {
                          ...state,
                          isFetching: false,
                          error: action.error,
                      }
                    : state
            );
    }
);
