import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { IRootState } from "../reducers/root";
import { handleResponseError } from "../../helpers/handleResponseError";
import { ICenterLevelResult } from "../../model/ICenterLevelResult";
import { ICenter } from "../../model/ICenter";
import { AppThunkAction } from "../../model/redux/AppThunkAction";
import { setItem } from "./localStorage/setItem";
import { getItem } from "./localStorage/getItem";

const DOMAIN = "picker/center";

export const fetchCenterLevel = createAsyncThunk<ICenterLevelResult, string | null, { state: IRootState }>(
    `${DOMAIN}/level/fetch`,
    async (arg, api) => {
        const requestOptions: RequestInit = {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${api.getState().session.user.token?.access_token}`,
            },
            signal: api.signal,
        };

        const url = new URL(api.getState().config.config?.endpoint + "/breeze/SelfService/CenterLevel");
        url.searchParams.set("centerID", arg ?? "");

        const response = await fetch(url.toString(), requestOptions);

        await handleResponseError(response);

        const partialResult = (await response.json()) as Partial<ICenterLevelResult>;

        return {
            childrens: [],
            loaded: null,
            parents: [],
            ...partialResult,
        } as ICenterLevelResult;
    }
);

const CENTER_ID_KEY = DOMAIN + "/selected/id";

export const selectCenter = createAction<ICenter>(`${DOMAIN}/select`);

export function selectCenterWithSave(center: ICenter): AppThunkAction {
    return dispatch => {
        dispatch(selectCenter(center));

        dispatch(setItem<string>(CENTER_ID_KEY, center.id));
    };
}

const INCLUDING_SUBCENTERS_KEY = DOMAIN + "/includingSubcenters";

export const includingSubcenters = createAction<boolean>(`${DOMAIN}/subcenters/including`);

export function includeSubcentersWithSave(include: boolean): AppThunkAction {
    return dispatch => {
        dispatch(includingSubcenters(include));

        dispatch(setItem<boolean>(INCLUDING_SUBCENTERS_KEY, include));
    };
}

export const selectCategory = createAction<number>(`${DOMAIN}/selectCategory`);

export function SelectedCategorySave(ixs_kat: number): AppThunkAction {
    return dispatch => {
        dispatch(selectCategory(ixs_kat));
        //dispatch(setItem<number>(SELECTED_CATEGORY_KEY, ixs_kat));
    };
}

export const resetCategory = createAction(`${DOMAIN}/resetCategory`);

export function ResetCategorySave(): AppThunkAction {
    return dispatch => {
        dispatch(resetCategory());
    };
}

export function initialize(): AppThunkAction<Promise<void>> {
    return async (dispatch, getState) => {
        const state = getState().session.centerPicker;

        if (state.isFetching || state.loadedCenter !== null) return;

        const include = dispatch(getItem<boolean>(INCLUDING_SUBCENTERS_KEY)) ?? true;

        if (include !== null) dispatch(includingSubcenters(include));

        const centerID = dispatch(getItem<string>(CENTER_ID_KEY));

        if (centerID !== null) {
            await dispatch(fetchCenterLevel(centerID));
        }

        if (getState().session.centerPicker.loadedCenter === null) {
            await dispatch(fetchCenterLevel(null));
        }
    };
}
