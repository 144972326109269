import React, { memo, PropsWithChildren } from "react";
import { PageLayout } from "../../../components/PageLayout/PageLayout";
import { NavBar } from "../../../components/NavBar/NavBar";
import { connect } from "react-redux";
import { IRootState } from "../../../redux/reducers/root";
import { IStrings } from "../../../constants/languageStrings/IStrings";
import { RouteChildrenProps } from "react-router-dom";
import Timeline from "../../../components/Timeline/Timeline";
import { ILanguage } from "../../../model/localization/ILanguage";
import NavbarDayPicker from "../../../components/NavbarDayPicker/NavbarDayPicker";
import { resolveAccounts } from "../shared/resolveAccounts";
import { Popup, Offset } from "@progress/kendo-react-popup";
import { Menu, MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";
import IntervalDetailDialog from "../shared/IntervalDetailDialog";
import { IPlanTimelineInterval, IAccount } from "../../../model/plan/types";
import { IBaseMenuItem } from "../../../components/NavBar/IBaseMenuItem";
import {
    fetchDepartmentPlanData,
    fetchDepartmentPlanDataIntervals,
    fetchDepartmentPlanDataIntervalsNextRecalculate,
    fetchDepartmentPlanDataIntervalsPreviousRecalculate,
    fetchDepartmentPlanDataIntervalsRecalculate,
    fetchDepartmentPlanDataNextIntervals,
    fetchDepartmentPlanDataPreviousIntervals,
    fetchDepartmentPlanNextData,
    fetchDepartmentPlanPreviousData,
    resetDeparmentPlanData,
    scrollDepartmentPlanNext,
    scrollDepartmentPlanPrevious,
} from "../../../redux/actions/plan/department";
import { formatDate } from "@telerik/kendo-intl";
import { IPerson } from "../../../model/plan/IPerson";
import { IGroup, IColumnConfig } from "../../../components/Timeline/types";
import { transformDataForTimeline } from "./transformation";
import Accounts from "../shared/Accounts";
import moment from "moment";
import classes from "./DepartmentPlan.module.scss";
import { NowColumnMarker } from "../../../components/Timeline/markers/NowColumnMarker";
import { parseDisplayMode } from "./parseDisplayMode";
import { SpinnerBox } from "../../../components/Spinner/SpinnerBox";
import { Tooltip } from "@material-ui/core";
import ReactDOM from "react-dom";
import { SelectedCenterButton } from "./SelectedCenterButton";
import { AppDispatch } from "../../..";
import { initialize as initializeCenterPicker } from "../../../redux/actions/centerPicker";
import { CenterPickerDialog } from "../../../components/CenterPickerDialog";
import { TooMuchPersonsWarning } from "./TooMuchPersonsWarning";
import { SelectedCenterTooltip } from "./SelectedCenterTooltip";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { ErrorNotification } from "../../../components/ErrorNotification";
import { IAbortable } from "../../../model/IAbortable";
import { WindowClickListener } from "../../../components/WindowClickListener";
import { IDepartmentPage } from "../../../redux/reducers/plan/department";
import SwipeableViews from "react-swipeable-views";
import { SlideRenderProps, virtualize } from "react-swipeable-views-utils";

const BASE_KEY = "ANeT/SelfService/plan/department";
const DISPLAY_MODE_KEY = BASE_KEY + "/displayMode";
const SELECTED_DAY_KEY = BASE_KEY + "/selectedDay";
const FETCH_COUNT_PRIMARY = 15;
const FETCH_COUNT_SECONDARY = 40;

/** 1-denní, 3-denní, 7-denní */
export type DisplayMode = 1 | 3 | 7;

interface IDepartmentPlanTimelineGroup extends IGroup {
    person: IPerson;
}

enum EDialog {
    None,
    IntervalDetail,
    CenterPicker,
}

interface IState {
    selectedDay: Date;
    displayMode: DisplayMode;
    visibleDialog: EDialog;
    /** For future shift interval */
    contextMenu: {
        visible: boolean;
        offset?: Offset;
    };
    lastClickedInterval?: IPlanTimelineInterval;
    lastClickedGroup?: IDepartmentPlanTimelineGroup;
    index: number;
}

interface IDispatchProps {
    dispatch: AppDispatch;
    onFetchData: (e: { from: Date; to: Date }) => IAbortable;
    onFetchDataIntervals: (e: { from: Date; to: Date; ixsRefs: string[] }) => IAbortable;
    onFetchDataIntervalsRecalculated: (e: { from: Date; to: Date; ixsRefs: string[] }) => IAbortable;
    onFetchDataPrevious: (e: { from: Date; to: Date }) => IAbortable;
    onFetchDataPreviousIntervals: (e: { from: Date; to: Date; ixsRefs: string[] }) => IAbortable;
    onFetchDataPreviousIntervalsRecalculated: (e: { from: Date; to: Date; ixsRefs: string[] }) => IAbortable;
    onFetchDataNext: (e: { from: Date; to: Date }) => IAbortable;
    onFetchDataNextIntervals: (e: { from: Date; to: Date; ixsRefs: string[] }) => IAbortable;
    onFetchDataNextIntervalsRecalculated: (e: { from: Date; to: Date; ixsRefs: string[] }) => IAbortable;
    onResetData: () => void;
    onScrollPrevious: () => void;
    onScrollNext: () => void;
}

interface IPageProps {
    intervals: IPlanTimelineInterval[];
    accounts: IAccount[];
    shouldFetchNext: boolean;
    publicHolidays: Date[];
    groups: IDepartmentPlanTimelineGroup[];
    fetchedIDs: string[];
    recalculatedIDs: string[];
    persons: IPerson[];
    loaded: boolean;
    isLoading: boolean;
    date?: string;
}

interface IStateProps {
    previousPage: IPageProps;
    currentPage: IPageProps;
    nextPage: IPageProps;
    strings: IStrings;
    language: ILanguage;
}

type IProps = IStateProps & IDispatchProps & RouteChildrenProps;

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

class DepartmentPlan extends React.Component<IProps, IState> {
    private abortable: IAbortable | undefined;

    constructor(props: IProps) {
        super(props);

        const timestamp = Date.parse(localStorage.getItem(SELECTED_DAY_KEY) ?? "");
        const now = new Date(!isNaN(timestamp) ? timestamp : new Date());

        this.state = {
            selectedDay: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
            displayMode: parseDisplayMode(parseInt(localStorage.getItem(DISPLAY_MODE_KEY) ?? "")),
            visibleDialog: EDialog.None,
            contextMenu: {
                visible: false,
            },
            index: 0,
        };
    }

    private resolveHoursMultiplier = (): number => {
        switch (this.state.displayMode) {
            case 1:
                return 2;
            case 3:
                return 6;
            case 7:
                return 12;
            default:
                return 24;
        }
    };

    private fetchData(): IAbortable {
        return this.props.onFetchData({
            from: this.state.selectedDay.addDays(-1), // needed due to multiday intervals
            to: this.state.selectedDay.addDays(this.state.displayMode - 1),
        });
    }

    private fetchDataPrevious(): IAbortable {
        return this.props.onFetchDataPrevious({
            from: this.state.selectedDay.addDays(-1 - this.state.displayMode), // needed due to multiday intervals
            to: this.state.selectedDay.addDays(this.state.displayMode - 1 - this.state.displayMode),
        });
    }

    private fetchDataNext(): IAbortable {
        return this.props.onFetchDataNext({
            from: this.state.selectedDay.addDays(-1 + this.state.displayMode), // needed due to multiday intervals
            to: this.state.selectedDay.addDays(this.state.displayMode - 1 + this.state.displayMode),
        });
    }

    private createMenuItems = () => {
        const strings = this.props.strings;
        return [
            { id: 1, content: strings.common.Day },
            { id: 3, content: strings.plan.N3Days },
            { id: 7, content: strings.plan.Week },
        ] as IBaseMenuItem[];
    };

    private createContextMenuItems = () => {
        const strings = this.props.strings;
        const items = [{ id: 1, text: strings.plan.DetailPlan }];

        return items;
    };

    private createColumnsConfig = (shift?: -1 | 1) => {
        const columns: IColumnConfig[] = [];

        for (let i = 0; i < this.state.displayMode; i++) {
            const day = this.state.selectedDay.addDays(i + (shift ?? 0) * this.state.displayMode);
            const endOfDay = moment(day).endOf("day").toDate();
            columns.push({
                id: `${day.getTime()}:${this.state.displayMode}`,
                beginDay: day,
                endDay: endOfDay,
            });
        }

        return columns;
    };

    private handleMenuItemClick = (item: IBaseMenuItem) => {
        switch (item.id) {
            case 1:
            case 3:
            case 7:
                this.setState(prevState => {
                    const displayMode = parseDisplayMode(item.id as unknown as number);
                    return {
                        selectedDay: displayMode === 7 ? prevState.selectedDay.firstDayOfWeek() : prevState.selectedDay,
                        displayMode: displayMode,
                    };
                });
                break;
            default:
                item.id && console.warn("Unhandled value:", item.id);
        }
    };

    private handleDayChange = (day: Date) => {
        this.setState(prevState => ({
            selectedDay: prevState.displayMode === 7 ? day.firstDayOfWeek() : day,
        }));
        this.props.onResetData();
        this.props.onFetchData(this.getParams());
    };

    private handleTimelineItemClick = (interval: IPlanTimelineInterval, group: IDepartmentPlanTimelineGroup) => {
        this.setState({
            visibleDialog: EDialog.IntervalDetail,
            lastClickedInterval: interval,
            lastClickedGroup: group,
        });
    };

    private handleTimelineItemContextMenu = (
        interval: IPlanTimelineInterval,
        group: IDepartmentPlanTimelineGroup,
        e: React.MouseEvent
    ) => {
        e.preventDefault();

        this.setState({
            contextMenu: {
                visible: true,
                offset: {
                    left: e.clientX,
                    top: e.clientY,
                },
            },
            lastClickedInterval: interval,
            lastClickedGroup: group,
        });
    };

    private handleContextMenuSelect = (e: MenuSelectEvent) => {
        switch (e.item.data) {
            case 1:
                this.setState({
                    visibleDialog: EDialog.IntervalDetail,
                });
                break;
            default:
                console.warn("Unhandled value:", e.item.data);
        }
    };

    private handleWindowClick = () =>
        this.setState({
            contextMenu: {
                visible: false,
            },
        });

    private handleDialogClose = () =>
        this.setState({
            visibleDialog: EDialog.None,
        });

    private handleSelectedCenterButtonClick = () =>
        this.setState({
            visibleDialog: EDialog.CenterPicker,
        });

    private handleCenterPickerDialogConfirm = () => {
        this.handleDialogClose();
        this.props.onResetData();
        this.abortable = this.fetchData();
    };

    componentDidMount() {
        this.props.onResetData();
        this.props.dispatch(initializeCenterPicker()).then(() => (this.abortable = this.fetchData()));
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        if (this.state.selectedDay.getTime() !== prevState.selectedDay.getTime()) {
            localStorage.setItem(SELECTED_DAY_KEY, this.state.selectedDay.toISOStringIgnoringTZ());
        }
        if (this.state.displayMode !== prevState.displayMode) {
            localStorage.setItem(DISPLAY_MODE_KEY, JSON.stringify(this.state.displayMode));
            this.props.onResetData();
            this.abortable = this.fetchData();
        }

        if (this.props.currentPage.loaded && !this.props.currentPage.isLoading) {
            if (!this.props.nextPage.loaded && !this.props.nextPage.isLoading) {
                this.props.onFetchDataNext(this.getParams([], 1));
            } else if (!this.props.previousPage.loaded && !this.props.previousPage.isLoading) {
                this.props.onFetchDataPrevious(this.getParams([], -1));
            } else {
                let notFetched = this.notFetchedIxsRefs(this.props.currentPage, FETCH_COUNT_PRIMARY);
                let notRecalculated = this.notRecalculatedIxsRefs(this.props.currentPage, FETCH_COUNT_PRIMARY);
                if (this.props.currentPage.shouldFetchNext && notFetched.length > 0) {
                    this.props.onFetchDataIntervals(this.getParams(notFetched));
                } else if (this.props.currentPage.shouldFetchNext && notRecalculated.length > 0) {
                    this.props.onFetchDataIntervalsRecalculated(this.getParams(notRecalculated));
                } else if (notRecalculated.length === 0) {
                    let notFetchedNext = this.notFetchedIxsRefs(this.props.nextPage, FETCH_COUNT_SECONDARY);
                    let notRecalculatedNext = this.notRecalculatedIxsRefs(this.props.nextPage, FETCH_COUNT_SECONDARY);
                    if (this.props.nextPage.shouldFetchNext && notFetchedNext.length > 0) {
                        this.props.onFetchDataNextIntervals(this.getParams(notFetchedNext, 1));
                    } else if (this.props.nextPage.shouldFetchNext && notRecalculatedNext.length > 0) {
                        this.props.onFetchDataNextIntervalsRecalculated(this.getParams(notRecalculatedNext, 1));
                    } else if (notRecalculatedNext.length === 0) {
                        let notFetchedPrevious = this.notFetchedIxsRefs(this.props.previousPage, FETCH_COUNT_SECONDARY);
                        let notRecalculatedPrevious = this.notRecalculatedIxsRefs(
                            this.props.previousPage,
                            FETCH_COUNT_SECONDARY
                        );

                        if (this.props.previousPage.shouldFetchNext && notFetchedPrevious.length > 0) {
                            this.props.onFetchDataPreviousIntervals(this.getParams(notFetchedPrevious, -1));
                        } else if (this.props.previousPage.shouldFetchNext && notRecalculatedPrevious.length > 0) {
                            this.props.onFetchDataPreviousIntervalsRecalculated(
                                this.getParams(notRecalculatedPrevious, -1)
                            );
                        }
                    }
                }
            }
        }
    }

    notFetchedIxsRefs(page: IPageProps, maxCount: number) {
        return page.persons
            .map(m => m.id)
            .filter(f => !page.fetchedIDs.some(s => s === f))
            .slice(0, maxCount);
    }
    notRecalculatedIxsRefs(page: IPageProps, maxCount: number) {
        return page.persons
            .map(m => m.id)
            .filter(f => !page.recalculatedIDs.some(s => s === f))
            .slice(0, maxCount);
    }

    componentWillUnmount() {
        this.abortable?.abort();
    }

    pageLayout = memo(
        function pageLayout(props: {
            that: DepartmentPlan;
            dataSource: IPageProps;
            shift?: -1 | 1;
            displayMode: DisplayMode;
        }) {
            return (
                <Loading>
                    <ErrorGuard>
                        <TooMuchPersonsGuard
                            guarded={
                                <div style={{ padding: "1rem", display: "flex", flexDirection: "column" }}>
                                    <SelectedCenterButton
                                        primary
                                        onClick={props.that.handleSelectedCenterButtonClick}
                                    />
                                    <TooMuchPersonsWarning />
                                </div>
                            }
                        >
                            <Timeline
                                groups={props.dataSource.groups}
                                intervals={props.dataSource.intervals}
                                columns={props.that.createColumnsConfig(props.shift)}
                                hoursMultiplier={props.that.resolveHoursMultiplier()}
                                sidebarColumnWidth="20%"
                                fetchedIDs={props.dataSource.fetchedIDs}
                                recalculatedIDs={props.dataSource.recalculatedIDs}
                                virtualized={true}
                                onIntervalClick={props.that.handleTimelineItemClick}
                                onIntervalContextMenu={props.that.handleTimelineItemContextMenu}
                                cornerCellContentRenderer={() => (
                                    <div
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                            overflow: "hidden",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <SelectedCenterTooltip arrow>
                                            <SelectedCenterButton
                                                primary
                                                onClick={props.that.handleSelectedCenterButtonClick}
                                            />
                                        </SelectedCenterTooltip>
                                    </div>
                                )}
                                groupSidebarCellContentRenderer={props => {
                                    const person = props.group.person;
                                    return (
                                        <Tooltip title={`${person.personalNumber} ${person.name}`}>
                                            <div className={classes.personInfoContainer}>
                                                <div>{person.name}</div>
                                                <div>{person.personalNumber}</div>
                                            </div>
                                        </Tooltip>
                                    );
                                }}
                                dayHeaderContentRenderer={innerProps => {
                                    const day = innerProps.column.beginDay;

                                    const isHoliday = props.dataSource.publicHolidays.some(
                                        s => s.getTime() === day.getTime()
                                    );

                                    const dayInWeek = day.getDay();
                                    const isBold = isHoliday || [0, 6].includes(dayInWeek); // Sobota nebo Neděle

                                    return (
                                        <div
                                            style={{
                                                textAlign: "center",
                                                whiteSpace: "nowrap",
                                                fontWeight: isBold ? "bold" : undefined,
                                                color: isHoliday ? "red" : undefined,
                                            }}
                                        >
                                            <span className={classes.dayOfWeek}>
                                                {formatDate(day, "EEEEEE", props.that.props.language.code)}&nbsp;
                                            </span>
                                            {formatDate(day, "m", props.that.props.language.code)}
                                        </div>
                                    );
                                }}
                                columnMarkerRenderer={props => <NowColumnMarker {...props} />}
                            />
                            <div className="section-container">
                                <Accounts accounts={props.dataSource.accounts} />
                            </div>
                        </TooMuchPersonsGuard>
                    </ErrorGuard>
                </Loading>
            );
        },
        (prev, next) => {
            if (prev.dataSource.isLoading !== next.dataSource.isLoading) return false;
            if (prev.dataSource.fetchedIDs.length !== next.dataSource.fetchedIDs.length) return false;
            if (prev.dataSource.recalculatedIDs.length !== next.dataSource.recalculatedIDs.length) return false;
            if (prev.dataSource.loaded !== next.dataSource.loaded) return false;
            if (prev.dataSource.date !== next.dataSource.date) return false;
            return true;
        }
    );

    getParams(ixsRefs?: string[], shift?: -1 | 0 | 1) {
        return {
            from: this.state.selectedDay.addDays(-1 + (shift ?? 0) * this.state.displayMode),
            to: this.state.selectedDay.addDays(this.state.displayMode - 1 + (shift ?? 0) * this.state.displayMode),
            ixsRefs: ixsRefs ?? [],
        };
    }

    private slideRenderer = (props: SlideRenderProps) => {
        const Layout = this.pageLayout;
        if (props.index < this.state.index) {
            return (
                <div key={props.key}>
                    <Layout
                        that={this}
                        dataSource={this.props.previousPage}
                        shift={-1}
                        displayMode={this.state.displayMode}
                    />
                </div>
            );
        } else if (props.index > this.state.index) {
            return (
                <div key={props.key}>
                    <Layout
                        that={this}
                        dataSource={this.props.nextPage}
                        shift={1}
                        displayMode={this.state.displayMode}
                    />
                </div>
            );
        } else {
            return (
                <div key={props.key}>
                    <Layout that={this} dataSource={this.props.currentPage} displayMode={this.state.displayMode} />
                </div>
            );
        }
    };

    private scrollPrevious = () => {
        this.props.onScrollPrevious();
        const newDay = this.state.selectedDay.addDays(-this.state.displayMode);
        this.setState({ selectedDay: newDay });
        this.fetchDataPrevious();
    };

    private scrollNext = () => {
        this.props.onScrollNext();
        const newDay = this.state.selectedDay.addDays(this.state.displayMode);
        this.setState({ selectedDay: newDay });
        this.fetchDataNext();
    };

    public render() {
        return (
            <PageLayout
                canScrollNext={!this.props.nextPage.isLoading && this.props.nextPage.loaded}
                canScrollPrevious={!this.props.previousPage.isLoading && this.props.previousPage.loaded}
                onScrollPrevious={this.scrollPrevious}
                onScrollNext={this.scrollNext}
                header={
                    <NavBar
                        canNavigateRoot
                        label={this.props.strings.dashboard.DepartmentPlan}
                        labelContent={
                            <>
                                <span className={classes.shortHeading}>{this.props.strings.plan.PlanName}</span>
                                <span className={classes.longHeading}>
                                    {this.props.strings.dashboard.DepartmentPlan}
                                </span>
                            </>
                        }
                        menu={{
                            items: this.createMenuItems(),
                            onItemClick: this.handleMenuItemClick,
                        }}
                    >
                        <NavbarDayPicker
                            selectedDay={this.state.selectedDay}
                            onPick={this.handleDayChange}
                            labelRenderer={() => {
                                const displayMode = this.state.displayMode;
                                let day = this.state.selectedDay;

                                if (displayMode > 1) {
                                    if (displayMode === 7) day = day.firstDayOfWeek();

                                    const locale = this.props.language.code;
                                    return `${formatDate(day, "m", locale)} - ${formatDate(
                                        day.addDays(displayMode - 1),
                                        "m",
                                        locale
                                    )}`;
                                }

                                return null;
                            }}
                        />
                    </NavBar>
                }
            >
                <VirtualizeSwipeableViews
                    style={{ minHeight: "100%" }}
                    overscanSlideBefore={1}
                    overscanSlideAfter={1}
                    slideRenderer={this.slideRenderer}
                    onChangeIndex={(index1, index0) => {
                        this.setState({ index: index1 });
                        if (index0 < index1) {
                            this.scrollNext();
                        } else {
                            this.scrollPrevious();
                        }
                    }}
                />
                {ReactDOM.createPortal(
                    <React.Fragment>
                        <WindowClickListener onClick={this.handleWindowClick}>
                            <Popup
                                show={this.state.contextMenu.visible}
                                offset={this.state.contextMenu.offset}
                                animate={false}
                            >
                                <Menu vertical={true} onSelect={this.handleContextMenuSelect}>
                                    {this.createContextMenuItems().map(item => (
                                        <MenuItem key={item.id} data={item.id} text={item.text} />
                                    ))}
                                </Menu>
                            </Popup>
                        </WindowClickListener>
                        {this.state.visibleDialog === EDialog.IntervalDetail && (
                            <IntervalDetailDialog
                                interval={this.state.lastClickedInterval!}
                                owner={this.state.lastClickedGroup?.person}
                                onClose={this.handleDialogClose}
                            />
                        )}
                        {this.state.visibleDialog === EDialog.CenterPicker && (
                            <CenterPickerDialog
                                onClose={this.handleDialogClose}
                                onConfirm={this.handleCenterPickerDialogConfirm}
                            />
                        )}
                    </React.Fragment>,
                    document.body
                )}
            </PageLayout>
        );
    }
}

function getPageStata(page: IDepartmentPage | undefined, isLoading: boolean) {
    return {
        loaded: !!page,
        isLoading: isLoading,
        groups: mapPersons2TimelineGroups(page?.persons ?? []),
        intervals: transformDataForTimeline(page?.intervals ?? []),
        shouldFetchNext: !!page?.shouldFetchNext,
        accounts: resolveAccounts(page?.intervals ?? []),
        publicHolidays: page?.publicHolidays.map(s => new Date(s)) ?? [],
        fetchedIDs: page?.fetchedIDs ?? [],
        recalculatedIDs: page?.recalculatedIDs ?? [],
        persons: page?.persons ?? [],
        date: page?.date,
    };
}

export default connect<IStateProps, IDispatchProps, {}, IRootState>(
    state => ({
        previousPage: getPageStata(
            state.session.plan.department.previousPage,
            state.session.plan.department.isPreviousFetching
        ),
        currentPage: getPageStata(
            state.session.plan.department.currentPage,
            state.session.plan.department.isCurrentFetching
        ),
        nextPage: getPageStata(state.session.plan.department.nextPage, state.session.plan.department.isNextFetching),
        strings: state.localization.strings,
        language: state.localization.language,
    }),
    (dispatch: AppDispatch) => ({
        dispatch: dispatch,
        onFetchData: (e: { from: Date; to: Date }) => dispatch(fetchDepartmentPlanData(e)),
        onFetchDataIntervals: (e: { from: Date; to: Date; ixsRefs: string[] }) =>
            dispatch(fetchDepartmentPlanDataIntervals(e)),
        onFetchDataIntervalsRecalculated: (e: { from: Date; to: Date; ixsRefs: string[] }) =>
            dispatch(fetchDepartmentPlanDataIntervalsRecalculate(e)),
        onFetchDataPrevious: (e: { from: Date; to: Date }) => dispatch(fetchDepartmentPlanPreviousData(e)),
        onFetchDataPreviousIntervals: (e: { from: Date; to: Date; ixsRefs: string[] }) =>
            dispatch(fetchDepartmentPlanDataPreviousIntervals(e)),
        onFetchDataPreviousIntervalsRecalculated: (e: { from: Date; to: Date; ixsRefs: string[] }) =>
            dispatch(fetchDepartmentPlanDataIntervalsPreviousRecalculate(e)),
        onFetchDataNext: (e: { from: Date; to: Date }) => dispatch(fetchDepartmentPlanNextData(e)),
        onFetchDataNextIntervals: (e: { from: Date; to: Date; ixsRefs: string[] }) =>
            dispatch(fetchDepartmentPlanDataNextIntervals(e)),
        onFetchDataNextIntervalsRecalculated: (e: { from: Date; to: Date; ixsRefs: string[] }) =>
            dispatch(fetchDepartmentPlanDataIntervalsNextRecalculate(e)),
        onResetData: () => dispatch(resetDeparmentPlanData()),
        onScrollPrevious: () => dispatch(scrollDepartmentPlanPrevious()),
        onScrollNext: () => dispatch(scrollDepartmentPlanNext()),
    })
)(DepartmentPlan);

function mapPersons2TimelineGroups(persons: IPerson[]): IDepartmentPlanTimelineGroup[] {
    return persons.map(p => ({
        id: p.id,
        title: "",
        person: p,
    }));
}

function Loading(props: PropsWithChildren<{}>) {
    const isLoading = useAppSelector<boolean>(
        s => s.session.plan.department.isCurrentFetching || s.session.centerPicker.isFetching
    );

    if (isLoading) {
        return <SpinnerBox stretchToWindow />;
    }

    return <>{props.children}</>;
}

function ErrorGuard(props: PropsWithChildren<{}>) {
    const error = useAppSelector(s => s.session.plan.department.error);

    if (error) {
        return <ErrorNotification error={error} />;
    }

    return <>{props.children}</>;
}

function TooMuchPersonsGuard(props: PropsWithChildren<{ guarded?: React.ReactNode }>) {
    const tooMuchPersons = useAppSelector<boolean>(s => !!s.session.plan.department.currentPage?.tooMuchPersons);

    if (tooMuchPersons) {
        return <>{props.guarded}</>;
    }

    return <>{props.children}</>;
}
