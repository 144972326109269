import { createReducer, SerializedError } from "@reduxjs/toolkit";
import { fetchPersonalCardData } from "../actions/personalCard";
import { IPersonalCardData } from "../../model/IPersonalCardData";

export interface IPersonalCardState {
    isFetching: boolean;
    requestID?: string;
    data?: Partial<IPersonalCardData>;
    error?: SerializedError;
}

export const personalCardReducer = createReducer<IPersonalCardState>(
    {
        isFetching: false,
    },
    builder => {
        return builder
            .addCase(fetchPersonalCardData.pending, (state, action) => ({
                ...state,
                isFetching: true,
                requestID: action.meta.requestId,
                data: undefined,
                error: undefined,
            }))
            .addCase(fetchPersonalCardData.fulfilled, (state, action) =>
                state.requestID === action.meta.requestId
                    ? {
                          ...state,
                          isFetching: false,
                          data: action.payload,
                          error: undefined,
                      }
                    : state
            )
            .addCase(fetchPersonalCardData.rejected, (state, action) =>
                state.requestID === action.meta.requestId
                    ? {
                          ...state,
                          isFetching: false,
                          data: undefined,
                          error: action.error,
                      }
                    : state
            );
    }
);
