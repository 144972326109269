import React from "react";
import { APP_VERSION, COMMIT_HASH } from "../../constants/common";
import classes from "./classes.module.scss";
import { useAppSelector } from "../../hooks/useAppSelector";

export default function AppFooter() {
    const infoText = useAppSelector(s => s.config.config.infoText);
    return (
        <footer className={classes.footer}>
            <span>{infoText}</span>
            <br />
            <span>Copyright &copy; </span>
            <a href="https://www.anet.eu/">ANeT</a>
            <span>
                &nbsp;{new Date().getFullYear()} ver.: {APP_VERSION}
            </span>
            <span> </span>
            {process.env.REACT_APP_BUILD && <span>build: {process.env.REACT_APP_BUILD}</span>}
            <br />
            <span>{COMMIT_HASH}</span>
        </footer>
    );
}
