import React from "react";
import { IColumnConfig } from "../types";

interface IMarkerProps {
    column: IColumnConfig;
    timeout?: number;
}

interface IState {
    now: Date;
}

export class NowColumnMarker extends React.Component<IMarkerProps, IState> {
    private readonly SECONDS_PER_PERCENT = 100 / (24 * 60 * 60);

    private intervalID?: number;

    constructor(props: IMarkerProps) {
        super(props);

        if (props.column.beginDay.getTime() > props.column.endDay.getTime())
            throw new Error("Begin time have to be lower then end time.");

        this.state = {
            now: new Date(),
        };
    }

    componentDidMount() {
        this.intervalID = setInterval(() => {
            this.setState({
                now: new Date(),
            });
        }, this.props.timeout ?? 10000) as unknown as number;
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    public render = () => {
        const now = this.state.now;
        const begin = this.props.column.beginDay;
        const end = this.props.column.endDay;

        if (now.getTime() < begin.getTime() || now.getTime() > end.getTime()) return null;

        const actual = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();

        const percent = this.SECONDS_PER_PERCENT * actual;

        return (
            <div
                style={{
                    height: "100%",
                    width: "1px",
                    borderLeft: "1px solid red",
                    marginLeft: `${percent}%`,
                }}
            ></div>
        );
    };
}
