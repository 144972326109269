import React from "react";
import classes from "./DayHeader.module.scss";
import { IColumnConfig } from "../types";

interface IProps {
    column: IColumnConfig;
    contentRenderer?: (props: { column: IColumnConfig }) => React.ReactNode;
}

export const DayHeader = (props: IProps) => {
    const f = new Intl.DateTimeFormat();
    const content =
        props.contentRenderer &&
        props.contentRenderer({
            column: props.column,
        });

    return content === null ? null : (
        <div className={classes.container}>
            {props.contentRenderer ? (
                content
            ) : (
                <div className={classes.textAlignCenter}>
                    {f.format(props.column.beginDay)} - {f.format(props.column.endDay)}
                </div>
            )}
        </div>
    );
};
