import React, { MouseEventHandler } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { IStrings } from "../../constants/languageStrings/IStrings";
import { connect } from "react-redux";
import { IRootState } from "../../redux/reducers/root";
import { QueryParam } from "../../model/CommonQueryParams";
import { deleteUnavailability } from "../../redux/actions/unavailability";
import { PLNUNAVA } from "../../model/PLNUNAVA";
import { ILanguage } from "../../model/localization/ILanguage";
import { selectUserFullname } from "../../redux/selectors/selectUserFullname";
import PermissionGuard from "../../components/PermissionGuard";
import { permissions } from "../../constants/permissions";
import classes from "./UnavailabilityDetail.module.scss";

class UnavailabilityDetail extends React.Component<IProps, IState> {
    async onDelete(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> {
        let queryParam: QueryParam<number> = { Value: this.props.data.ID_UNAVA };
        await this.props.onDeleteUnavailability(queryParam);
        this.props.onClose && this.props.onClose(event);
    }

    makeFrom(row: PLNUNAVA): string {
        if (row.STARTTIME === null) {
            return (
                new Date(row.DATE_REF).toLocaleDateString(this.props.language.code) +
                " " +
                new Date(1900, 0, 1, 0, 0).toLocaleTimeString(this.props.language.code)
            );
        } else
            return (
                new Date(row.STARTTIME).toLocaleDateString(this.props.language.code) +
                " " +
                new Date(row.STARTTIME).toLocaleTimeString(this.props.language.code)
            );
    }

    makeTo(row: PLNUNAVA): string {
        if (row.STARTTIME === null) {
            return (
                new Date(row.DATE_REF).toLocaleDateString(this.props.language.code) +
                " " +
                new Date(1900, 0, 1, 23, 59).toLocaleTimeString(this.props.language.code)
            );
        } else
            return (
                new Date(row.ENDTIME!).toLocaleDateString(this.props.language.code) +
                " " +
                new Date(row.ENDTIME!).toLocaleTimeString(this.props.language.code)
            );
    }

    public render() {
        return (
            <Dialog
                title={
                    <div>
                        <label>{this.props.strings.unavailability.Unavailability}</label>
                    </div>
                }
                width="90%"
                closeIcon={false}
            >
                <Input readOnly label={this.props.strings.worksheet.NameWS} value={this.props.user} />
                <Input readOnly label={this.props.strings.requests.FromDate} value={this.makeFrom(this.props.data)} />
                <Input readOnly label={this.props.strings.requests.ToDate} value={this.makeTo(this.props.data)} />

                {this.props.data.NOTE ? (
                    <Input readOnly label={this.props.strings.requests.Comment} value={this.props.data.NOTE} />
                ) : (
                    <></>
                )}
                <DialogActionsBar>
                    <Button onClick={this.props.onClose}>{this.props.strings.common.Cancel}</Button>
                    <PermissionGuard permission={permissions.unavailabilities.edit}>
                        <Button onClick={event => this.onDelete(event)} className={classes.delete}>
                            {this.props.strings.common.Delete}
                        </Button>
                    </PermissionGuard>
                </DialogActionsBar>
            </Dialog>
        );
    }
}

interface IOwnProps {
    onClose?: MouseEventHandler;
    data: PLNUNAVA;
}

interface IStateProps {
    strings: IStrings;
    language: ILanguage;
    user: string;

    isUnavailabilityDeleting?: boolean;
    isSuccessfullyDeleted?: boolean;
}

interface IState {
    comment?: string;
}

interface IDispatchProps {
    onDeleteUnavailability: (e: QueryParam<number>) => void;
}

type IProps = IOwnProps & IStateProps & IDispatchProps & IState;

export default connect<IStateProps, IDispatchProps, IOwnProps, IRootState>(
    state => ({
        strings: state.localization.strings,
        language: state.localization.language,
        user: selectUserFullname(state),

        isUnavailabilityDeleting: state.session.unavailabilityDelete.isFetching,
        isSuccessfullyDeleted: state.session.unavailabilityDelete.succesful,
    }),
    {
        onDeleteUnavailability: (e: QueryParam<number>) => deleteUnavailability(e),
    }
)(UnavailabilityDetail);
