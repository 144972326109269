import React from "react";
import ReactDOM from "react-dom";
import { IStrings } from "../../constants/languageStrings/IStrings";
import { connect } from "react-redux";
import { IRootState } from "../../redux/reducers/root";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import { NavBar } from "../../components/NavBar/NavBar";
import styles from "./WorksheetState.module.scss";
import { List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { ILanguage } from "../../model/localization/ILanguage";
import { WorkSheetMassRequestParams, WorkSheetStatesSelfServiceParams } from "../../model/LeftPanelParams";
import { fetchWorksheetStates } from "../../redux/actions/worksheetState";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import NavbarMonthPicker from "../../components/NavbarMonthPicker/NavbarMonthPicker";
import { WorkSheetPersons } from "../../model/IWorksheetState";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { SelectedCenterTooltip } from "../Plan/Department/SelectedCenterTooltip";
import { SelectedCenterButton } from "../Plan/Department/SelectedCenterButton";
import { initialize as initializeCenterPicker } from "../../redux/actions/centerPicker";
import { CenterPickerDialog } from "../../components/CenterPickerDialog";
import { AppDispatch } from "../..";
import { SpinnerBox } from "../../components/Spinner/SpinnerBox";
import { SerializedError } from "@reduxjs/toolkit";
import { ErrorNotification } from "../../components/ErrorNotification";
import { TooMuchPersonNotification } from "../../components/TooMuchPersonNotification";
import { InternalMaxLimitParam } from "../../constants/common";
import PermissionGuard from "../../components/PermissionGuard";
import { permissions } from "../../constants/permissions";
import { IBaseMenuItem } from "../../components/NavBar/IBaseMenuItem";

enum EDialog {
    None,
    CenterPicker,
}

const param: WorkSheetStatesSelfServiceParams = {
    StartDate: new Date(2020, 4, 1),
    EndDate: new Date(2020, 4, 30),
    SelectedTab: "tab-centers",
    ActivePersonsOnly: true,
    ValidPersonsOnly: true,
    LoadUnconfirmed: false,
    LoadConfirmed: false,
    LoadOpened: false,
    LoadSigned: true,
    LoadApproved: false,
    LoadUnsigned: false,
    LoadAll: false,
    Kind: 1,
};

class WorksheetState extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const now = new Date().addMonths(-1);
        this.state = {
            selectedMonth: now,
            visibleDialog: EDialog.None,
        };
        this.setDateIntoParam(now.getMonth(), now.getFullYear());
    }

    private options: string[] = [
        this.props.strings.worksheetStates.AllChosen,
        this.props.strings.worksheetStates.OnlyCreated,
        this.props.strings.worksheetStates.OnlyApprovedWS,
        this.props.strings.worksheetStates.OnlyConfirmed,
        this.props.strings.worksheetStates.OnlySigned,
        this.props.strings.worksheetStates.OnlySignedHandwritten,
        this.props.strings.worksheetStates.OnlyUnSigned,
    ];

    setDateIntoParam(actMonthNumber: number, actYear: number) {
        param.StartDate?.setMonth(actMonthNumber);
        param.StartDate?.setFullYear(actYear);
        param.EndDate = new Date(actYear, actMonthNumber + 1, 1, 0, 0, 0);
    }
    async componentDidMount() {
        this.SetAllOptionsFalse();
        param.LoadUnsigned = true;
        this.props
            .dispatch(initializeCenterPicker())
            .then(async () => this.props.onLoadStates && this.props.onLoadStates(param));
    }

    public handleMonthChange = (month: Date) => {
        this.setDateIntoParam(month.getMonth(), month.getFullYear());
        this.setState({ selectedMonth: month });
        this.props.onLoadStates && this.props.onLoadStates(param);
    };

    private SetAllOptionsFalse() {
        param.LoadApproved =
            param.LoadConfirmed =
            param.LoadOpened =
            param.LoadAll =
            param.LoadSigned =
            param.LoadUnconfirmed =
            param.LoadUnsigned =
                false;
        param.Kind = 0;
    }

    private handleFilterChange = (event: DropDownListChangeEvent) => {
        this.SetAllOptionsFalse();
        switch (event.value) {
            case this.options[0]:
                param.LoadAll = true;
                break;
            case this.options[1]:
                param.LoadOpened = true;
                break;
            case this.options[2]:
                param.LoadApproved = true;
                break;
            case this.options[3]:
                param.LoadConfirmed = true;
                break;
            case this.options[4]:
                param.LoadSigned = true;
                param.Kind = 1;
                break;
            case this.options[5]:
                param.LoadSigned = true;
                param.Kind = 0;
                break;
            case this.options[6]:
                param.LoadUnsigned = true;
                break;
        }
        this.props.onLoadStates && this.props.onLoadStates(param);
    };

    private GetState(row: WorkSheetPersons): string {
        if (row.Signed)
            return row.SignedKind === 0
                ? this.props.strings.worksheet.State_SignedHandwritten
                : this.props.strings.worksheet.State_SignedElectronically;
        else if (row.Confirmed) return this.props.strings.worksheet.State_Confirmed;
        else if (row.Approved) return this.props.strings.worksheet.State_Approved;
        else return this.props.strings.worksheet.State_Open;
    }

    private getMailToAddress(row: WorkSheetPersons): string {
        const mailTo: string = "mailto:";
        return mailTo + row.Email;
    }

    private handleMailClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, row: WorkSheetPersons): void {
        e.preventDefault();
        window.location.href = this.getMailToAddress(row);
    }

    private handleSingleNumberClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, row: WorkSheetPersons): void {
        e.preventDefault();
        window.location.href = "tel:" + row.Phone[0];
    }

    private handleDialogClose = () =>
        this.setState({
            visibleDialog: EDialog.None,
        });

    private handleSelectedCenterButtonClick = () =>
        this.setState({
            visibleDialog: EDialog.CenterPicker,
        });

    private handleCenterPickerDialogConfirm = () => {
        this.handleDialogClose();
        this.props.onLoadStates && this.props.onLoadStates(param);
    };

    public render() {
        let rows: WorkSheetPersons[] = this.props.data || [];
        return (
            <PageLayout
                header={
                    <NavBar
                        canNavigateRoot
                        label={this.props.strings.worksheetStates.TitleWSStates}
                        menu={{
                            items: [] as IBaseMenuItem[],
                            onItemClick: undefined,
                        }}
                    >
                        <NavbarMonthPicker selectedMonth={this.state.selectedMonth} onPick={this.handleMonthChange} />
                    </NavBar>
                }
            >
                <div className={styles.params}>
                    <SelectedCenterTooltip arrow>
                        <SelectedCenterButton
                            className={styles.centerButton}
                            primary
                            onClick={this.handleSelectedCenterButtonClick}
                        />
                    </SelectedCenterTooltip>
                    <DropDownList
                        data={this.options}
                        onChange={this.handleFilterChange}
                        defaultValue={this.options[6]}
                    ></DropDownList>
                </div>
                {this.props.isFetching && <SpinnerBox></SpinnerBox>}
                {this.props.error && <ErrorNotification error={this.props.error} />}
                {this.props.inlineCount > InternalMaxLimitParam && (
                    <TooMuchPersonNotification></TooMuchPersonNotification>
                )}
                {!this.props.isFetching && !this.props.error && this.props.inlineCount <= InternalMaxLimitParam && (
                    <List>
                        {rows.length === 0 && (
                            <label className={styles.noData}>{this.props.strings.common.NoItems}</label>
                        )}
                        {/* řádky */}
                        {rows.map((row: WorkSheetPersons, key: any) => (
                            <ListItem key={key} divider dense button>
                                <ListItemText
                                    primary={<React.Fragment>{row.FullName}</React.Fragment>}
                                    secondary={<React.Fragment>{this.GetState(row)}</React.Fragment>}
                                />
                                <ListItemSecondaryAction>
                                    <PermissionGuard permission={permissions.presence.showContacts}>
                                        <div>
                                            {row.Email !== "" && row.Email != null && (
                                                <Button
                                                    iconClass="fa fa-envelope"
                                                    onClick={e => this.handleMailClick(e, row)}
                                                ></Button>
                                            )}
                                            {row.Phone.length > 1 && (
                                                <DropDownButton
                                                    iconClass="fa fa-phone"
                                                    items={row.Phone.map((number: string, key: any) => (
                                                        <a key={key} href={"tel:" + number} data-rel="external">
                                                            <span>{number}</span>
                                                        </a>
                                                    ))}
                                                ></DropDownButton>
                                            )}
                                            {row.Phone.length === 1 && (
                                                <Button
                                                    iconClass="fa fa-phone"
                                                    onClick={e => this.handleSingleNumberClick(e, row)}
                                                ></Button>
                                            )}
                                        </div>
                                    </PermissionGuard>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                )}
                {ReactDOM.createPortal(
                    <React.Fragment>
                        {this.state.visibleDialog === EDialog.CenterPicker && (
                            <CenterPickerDialog
                                onClose={this.handleDialogClose}
                                onConfirm={this.handleCenterPickerDialogConfirm}
                            />
                        )}
                    </React.Fragment>,
                    document.body
                )}
            </PageLayout>
        );
    }
}

interface IOwnProps {
    data: WorkSheetPersons[];
    inlineCount: number;
    isFetching: boolean;
    error: SerializedError;
}

interface IStateProps {
    strings: IStrings;
    lang: ILanguage;
}

interface IState {
    selectedMonth: Date;
    visibleDialog: EDialog;
}

interface IDispatchProps {
    dispatch: AppDispatch;
    onLoadStates: (e: WorkSheetMassRequestParams) => void;
}

type IProps = IOwnProps & IStateProps & IDispatchProps & IState;

export default connect<IStateProps, IDispatchProps, IOwnProps, IRootState>(
    state => ({
        strings: state.localization.strings,
        lang: state.localization.language,
        data: state.session.worksheetStates.worksheetsWithState,
        inlineCount: state.session.worksheetStates.inlineCount,
        isFetching: state.session.worksheetStates.isFetching,
        error: state.session.worksheetStates.error,
    }),
    (dispatch: AppDispatch) => ({
        dispatch: dispatch,
        onLoadStates: (e: WorkSheetMassRequestParams) => dispatch(fetchWorksheetStates(e)),
    })
)(WorksheetState);
