import { combineReducers } from "redux";
import { IMyState, myReducer } from "./plan/my";
import { IDepartmentState, departmentReducer } from "./plan/department";
import { ISigningState, signingReducer } from "./plan/signing";
import { IPlanChangeState as IChangingState, changingReducer } from "./plan/changing";

export interface IPlanState {
    my: IMyState;
    signing: ISigningState;
    changing: IChangingState;
    department: IDepartmentState;
}

export const planReducer = combineReducers<IPlanState>({
    my: myReducer,
    signing: signingReducer,
    changing: changingReducer,
    department: departmentReducer,
});
