import { createAsyncThunk } from "@reduxjs/toolkit";
import { InternalMaxLimitParam } from "../../constants/common";
import { handleResponseError } from "../../helpers/handleResponseError";
import { IPresenceForPerson } from "../../model/IPresenceForPerson";
import { LeftPanelParams } from "../../model/LeftPanelParams";
import { Translation } from "../../model/presenceTranslation";
import { IRootState } from "../reducers/root";

const DOMAIN = "Presence";

export const fetchPresenceData = createAsyncThunk<IPresenceForPerson[], LeftPanelParams, { state: IRootState }>(
    `${DOMAIN}/fetchPresenceData`,
    async (arg, api) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
        let center: string[] = [api.getState().session.centerPicker.selected?.id || ""];
        arg.CheckedIxsStrs = center;
        arg.SelectedIxsStr = api.getState().session.centerPicker.selected?.id || "";
        arg.IncludeChildren = api.getState().session.centerPicker.includingSubcenters;
        const requestOptions: RequestInit = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(arg),
        };
        // internal paramter due to performance issues
        const response = await fetch(
            api.getState().config.config?.endpoint +
                `/breeze/IpoData/GetPresenceByLeftPanelParams?$skip=0&$top=${InternalMaxLimitParam + 1}`,
            requestOptions
        );
        await handleResponseError(response);

        return await response.json();
    }
);

export const fetchPresenceTranslations = createAsyncThunk<Translation[], void, { state: IRootState }>(
    `${DOMAIN}/fetchPresenceTranslations`,
    async (arg, api) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
        const requestOptions: RequestInit = {
            method: "GET",
            headers: headers,
        };
        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/SelfService_GetPresenceTranslations",
            requestOptions
        );

        await handleResponseError(response);

        return await response.json();
    }
);

export const fetchPassesTranslations = createAsyncThunk<Translation[], void, { state: IRootState }>(
    `${DOMAIN}/fetchPassesTranslations`,
    async (arg, api) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
        const requestOptions: RequestInit = {
            method: "GET",
            headers: headers,
        };
        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/SelfService_GetPassesTranslations",
            requestOptions
        );

        await handleResponseError(response);

        return await response.json();
    }
);

export const fetchAccountsTranslations = createAsyncThunk<Translation[], void, { state: IRootState }>(
    `${DOMAIN}/fetchAccountsTranslations`,
    async (_arg, api) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
        const requestOptions: RequestInit = {
            method: "GET",
            headers: headers,
        };
        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/SelfService_GetAccountsTranslations",
            requestOptions
        );

        await handleResponseError(response);

        return await response.json();
    }
);
