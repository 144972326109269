import React, { PropsWithChildren } from "react";
import { SpinnerBox } from "../../components/Spinner/SpinnerBox";
import { ErrorNotification } from "../../components/ErrorNotification";
import { useStrings } from "../../hooks/useStrings";
import { useAppSelector } from "../../hooks/useAppSelector";

function ConfigDownloading(props: PropsWithChildren<{}>) {
    const strings = useStrings();
    const isFetching = useAppSelector(s => s.config.isFetching);
    const error = useAppSelector(s => s.config.error);

    if (isFetching) {
        return <SpinnerBox>{strings.app.ConfigurationDownloading + "..."}</SpinnerBox>;
    } else if (error) {
        return <ErrorNotification error={error} />;
    } else {
        return <React.Fragment>{props.children}</React.Fragment>;
    }
}

export default ConfigDownloading;
