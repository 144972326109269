import { combineReducers } from "redux";
import { accountsReducer, IAccountsState } from "./accounts";

export interface IDropdownsState {
    accounts: IAccountsState;
}

export const dropdownsReducer = combineReducers({
    accounts: accountsReducer,
});
