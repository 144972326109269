import { createReducer, SerializedError } from "@reduxjs/toolkit";
import {
    fetchMyPlanData,
    fetchMyPlanDataNext,
    fetchMyPlanDataPrevious,
    fetchOldestMonthWithMyPlanRequest,
    resetMyPlanData,
    scrollMyPlanNext,
    scrollMyPlanPrevious,
} from "../../actions/plan/my";
import { IMyPlanData } from "../../../model/plan/my/IMyPlanData";

export interface IMyState {
    isFetchingPrevious: boolean;
    isFetching: boolean;
    isFetchingNext: boolean;
    previousPage?: IMyPlanData;
    currentPage?: IMyPlanData;
    nextPage?: IMyPlanData;
    requestIdPrevious?: string;
    requestId?: string;
    requestIdNext?: string;
    error?: SerializedError;
}

export const myReducer = createReducer<IMyState>(
    {
        isFetchingPrevious: false,
        isFetching: false,
        isFetchingNext: false,
    },
    builder =>
        builder
            .addCase(fetchMyPlanData.pending, (state, action) => ({
                ...state,
                currentPage: undefined,
                isFetching: true,
                requestId: action.meta.requestId,
            }))
            .addCase(fetchMyPlanData.fulfilled, (state, action) =>
                state.requestId === action.meta.requestId
                    ? {
                          ...state,
                          isFetching: false,
                          currentPage: action.payload,
                          error: undefined,
                      }
                    : state
            )
            .addCase(fetchMyPlanData.rejected, (state, action) =>
                state.requestId === action.meta.requestId
                    ? {
                          ...state,
                          isFetching: false,
                          currentPage: undefined,
                          error: action.error,
                      }
                    : state
            )
            .addCase(fetchMyPlanDataPrevious.pending, (state, action) => ({
                ...state,
                isFetchingPrevious: true,
                previousPage: undefined,
                requestIdPrevious: action.meta.requestId,
            }))
            .addCase(fetchMyPlanDataPrevious.fulfilled, (state, action) =>
                state.requestIdPrevious === action.meta.requestId
                    ? {
                          ...state,
                          isFetchingPrevious: false,
                          previousPage: action.payload,
                      }
                    : state
            )
            .addCase(fetchMyPlanDataPrevious.rejected, (state, action) =>
                state.requestIdPrevious === action.meta.requestId
                    ? {
                          ...state,
                          isFetchingPrevious: false,
                          previousPage: undefined,
                          error: action.error,
                      }
                    : state
            )
            .addCase(fetchMyPlanDataNext.pending, (state, action) => ({
                ...state,
                isFetchingNext: true,
                nextPage: undefined,
                requestIdNext: action.meta.requestId,
            }))
            .addCase(fetchMyPlanDataNext.fulfilled, (state, action) =>
                state.requestIdNext === action.meta.requestId
                    ? {
                          ...state,
                          isFetchingNext: false,
                          nextPage: action.payload,
                      }
                    : state
            )
            .addCase(fetchMyPlanDataNext.rejected, (state, action) =>
                state.requestIdNext === action.meta.requestId
                    ? {
                          ...state,
                          isFetchingNext: false,
                          nextPage: undefined,
                          error: action.error,
                      }
                    : state
            )
            .addCase(scrollMyPlanNext, (state, action) => ({
                ...state,
                previousPage: state.currentPage,
                currentPage: state.nextPage,
                nextPage: undefined,
            }))
            .addCase(scrollMyPlanPrevious, (state, action) => ({
                ...state,
                nextPage: state.currentPage,
                currentPage: state.previousPage,
                previousPage: undefined,
            }))
            .addCase(resetMyPlanData, (state, action) => ({
                ...state,
                nextPage: undefined,
                currentPage: undefined,
                previousPage: undefined,
            }))
            .addCase(fetchOldestMonthWithMyPlanRequest.pending, (state, action) => ({
                ...state,
                isFetching: true,
                requestID: action.meta.requestId,
            }))
            .addCase(fetchOldestMonthWithMyPlanRequest.fulfilled, (state, action) =>
                state.requestId === action.meta.requestId
                    ? {
                          ...state,
                          isFetching: false,
                      }
                    : state
            )
            .addCase(fetchOldestMonthWithMyPlanRequest.rejected, (state, action) =>
                state.requestId === action.meta.requestId
                    ? {
                          ...state,
                          isFetching: false,
                          error: action.error,
                      }
                    : state
            )
);
