import { ILanguage } from "../model/localization/ILanguage";
import czFlagSource from "../assets/images/flag_cz.gif";
import enFlagSource from "../assets/images/flag_en.gif";
import skFlagSource from "../assets/images/flag_sk.gif";

export const languages: ReadonlyArray<ILanguage> = [
    { id: -1, shortcut: "EN", code: "en", iconSrc: enFlagSource },
    { id: -2, shortcut: "CZ", code: "cs", iconSrc: czFlagSource },
    { id: -3, shortcut: "SK", code: "sk", iconSrc: skFlagSource },
];

export const initialLanguage = languages[0];
