import "./extensions/Promise";
import "./extensions/Date";

import "@progress/kendo-theme-material";
import "font-awesome/css/font-awesome.min.css";

import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import { rootReducer } from "./redux/reducers/root";
import { logger } from "redux-logger";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { tokenPersistor } from "./redux/middlewares/tokenPersistor";
import { tokenRefresher } from "./redux/middlewares/tokenRefresher";
import { crashReporter } from "./redux/middlewares/crashReporter";
import { load, loadMessages } from "@progress/kendo-react-intl";
import { rejectedAsyncActionsReporter } from "./redux/middlewares/rejectedAsyncActionsReporter";
import { localizationSlice } from "./redux/slices/localization";

import csMessages from "./assets/cs.json";
import skMessages from "./assets/sk.json";
loadMessages(csMessages, "cs");
loadMessages(skMessages, "sk");

load(
    // CS
    require("cldr-numbers-full/main/cs/numbers.json"),
    require("cldr-numbers-full/main/cs/currencies.json"),
    require("cldr-dates-full/main/cs/ca-gregorian.json"),
    require("cldr-dates-full/main/cs/dateFields.json"),

    // SK
    require("cldr-numbers-full/main/sk/numbers.json"),
    require("cldr-numbers-full/main/sk/currencies.json"),
    require("cldr-dates-full/main/sk/ca-gregorian.json"),
    require("cldr-dates-full/main/sk/dateFields.json"),

    // global
    require("cldr-core/supplemental/likelySubtags.json"),
    require("cldr-core/supplemental/currencyData.json"),
    require("cldr-core/supplemental/weekData.json")
);

const middlewares = [
    ...getDefaultMiddleware(),
    logger,
    crashReporter,
    tokenPersistor,
    tokenRefresher,
    rejectedAsyncActionsReporter,
];

const store = configureStore({
    reducer: rootReducer,
    middleware: middlewares,
});

store.dispatch(localizationSlice.actions.init());

export type AppDispatch = typeof store.dispatch;

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);
