import React from "react";
import Timeline from "../../components/Timeline/Timeline";
import { generateGroups, generateRandomIntervals } from "../../components/Timeline/generators";

export default function TimelinePage() {
    return (
        <Timeline
            groups={generateGroups()}
            intervals={generateRandomIntervals(30)}
            columns={[
                {
                    id: 1,
                    beginDay: new Date(2020, 0, 1),
                    endDay: new Date(2020, 0, 15),
                },
                {
                    id: 2,
                    beginDay: new Date(2020, 0, 16),
                    endDay: new Date(2020, 0, 31),
                },
            ]}
        />
    );
}
