import { Key } from "react";
import { SerializedError, createSlice, PayloadAction, Action } from "@reduxjs/toolkit";

export interface IErrorContainer {
    id: Key;
    action: string;
    error: SerializedError;
}

export type ErrorsListState = IErrorContainer[];

let id = 1;

export const errorsListSlice = createSlice({
    name: "errors/list",
    initialState: [] as ErrorsListState,
    reducers: {
        add: {
            reducer: (state, action: PayloadAction<IErrorContainer>) => [...state, action.payload],
            prepare: (action: PayloadAction<unknown, string, unknown, SerializedError>) => ({
                payload: {
                    id: id++,
                    action: action.type,
                    error: action.payload ?? action.error,
                } as IErrorContainer,
            }),
        },
        remove: (state, action: PayloadAction<Key>) => state.filter(p => p.id !== action.payload),
        removeAll: (state, action: Action) => [],
    },
});
