import React from "react";
import classes from "./CornerCell.module.scss";

interface IProps {
    contentRenderer?: () => React.ReactNode;
}

export const CornerCell = (props: IProps) => (
    <div
        className={classes.cell}
        style={{
            gridRowStart: 1,
            gridRowEnd: 1,
            gridColumnStart: 1,
            gridColumnEnd: 1,
        }}
    >
        {props.contentRenderer && props.contentRenderer()}
    </div>
);
