export async function handleResponseError(response: Response) {
    if (response.ok) return response;

    let body: string = "";

    if (response.headers.get("Content-Type")?.startsWith("application/json")) {
        try {
            body = await response.clone().json();
        } catch (e) {
            throw e;
        }
    } else {
        body = await response.clone().text();
    }

    const extra = {
        url: response.url,
        status: response.status,
        statusText: response.statusText,
        headers: Array.from(response.headers.entries()),
        body: body,
    };

    throw new Error(JSON.stringify(extra));
}
