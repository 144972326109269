import { IFileConfig } from "../model/config/IFileConfig";

export const defaults: IFileConfig = {
    endpoint: "http://localhost/WebTimeServer",
    syncEndpointOriginWithClient: true,
    debug: false,
    autoLogoutMinutes: 0,
    infoText: "",
    uploadSize: 1200,
    uploadQuality: 0.5,
};
