export interface LeftPanelParams {
    ActivePersonsOnly?: boolean;
    ValidPersonsOnly?: boolean;
    StartDate?: Date;
    EndDate?: Date;
    ModeWorkSheet?: boolean;
    FlagList?: number[];
    IsActual?: boolean;
    BoolValue?: boolean;
    CheckedCategories?: number[] | null;
    SelectedIxsStr?: string;
    CheckedIxsStrs?: string[];
    IncludeChildren?: boolean;
    SelectedIxsRef?: string;
    SelectedTab?: string;
    SelectedPresence?: SelectedPresence;
    Sort?: string;
    AlwaysShownIxsRefs?: any[];
    ModeVisits?: boolean;
    CurrentAgenda?: string;
    Lang?: string;
}

export interface WorkSheetMassRequestParams extends LeftPanelParams {
    LoadConfirmed: boolean;
    LoadUnconfirmed: boolean;
    LoadSigned: boolean;
}

export interface WorkSheetStatesSelfServiceParams extends WorkSheetMassRequestParams {
    LoadApproved: boolean;
    LoadOpened: boolean;
    LoadUnsigned: boolean;
    LoadAll: boolean;
    Kind: number;
}

export interface PlanStatesSelfServiceParams extends LeftPanelParams {
    LoadNotPassed: boolean;
    LoadNotChecked: boolean;
    LoadChecked: boolean;

    LoadWaiting: boolean;
    LoadApproved: boolean;
    LoadRejected: boolean;

    HandWritten: boolean;
}

export enum SelectedPresence {
    isCheckedAllPersons = 0,
    isCheckedOnlyPresent = 1,
    isCheckedOnlyAbsent = 2,
    isCheckedOnlyInconsintent = 3,
    isCheckedWithPlannedShift = 4,
}
