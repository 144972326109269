import { ILanguage } from "../model/localization/ILanguage";
import { IStrings } from "../constants/languageStrings/IStrings";
import { IStringsSection } from "../model/localization/IStringsSection";
import { strings } from "../constants/strings";

export function selectTranslations(language: ILanguage): IStrings {
    return strings[language.code] ?? createClearCopyRecursively(strings.en as unknown as IStringsSection);
}

function createClearCopyRecursively(section: IStringsSection): IStringsSection {
    const dolly: IStringsSection = {};

    for (const field in section) {
        if (Object.prototype.hasOwnProperty.call(section, field)) {
            const element = section[field];

            if (typeof element === "object") dolly[field] = createClearCopyRecursively(element);
            else if (typeof element === "string") dolly[field] = `_${field}`;
            else dolly[field] = element;
        }
    }

    return dolly;
}
