import { createReducer, SerializedError } from "@reduxjs/toolkit";
import {
    fetchDepartmentPlanData,
    fetchDepartmentPlanDataIntervals,
    fetchDepartmentPlanDataIntervalsNextRecalculate,
    fetchDepartmentPlanDataIntervalsPreviousRecalculate,
    fetchDepartmentPlanDataIntervalsRecalculate,
    fetchDepartmentPlanDataNextIntervals,
    fetchDepartmentPlanDataPreviousIntervals,
    fetchDepartmentPlanNextData,
    fetchDepartmentPlanPreviousData,
    resetDeparmentPlanData,
    scrollDepartmentPlanNext,
    scrollDepartmentPlanPrevious,
} from "../../actions/plan/department";
import { IPerson } from "../../../model/plan/IPerson";
import { SerializedInterval } from "../../../model/plan/types";
import { IDepartmentPlanData } from "../../../model/plan/department/IDepartmentPlanData";

export interface IDepartmentPage {
    persons: IPerson[];
    intervals: SerializedInterval[];

    /** ISO encoded dates. */
    publicHolidays: string[];

    tooMuchPersons: boolean;

    shouldFetchNext: boolean;
    fetchedIDs: string[];
    recalculatedIDs: string[];
    date: string;
}

export interface IDepartmentState {
    previousPage?: IDepartmentPage;
    currentPage?: IDepartmentPage;
    nextPage?: IDepartmentPage;

    previousRequestID?: string;
    currentRequestID?: string;
    nextRequestID?: string;

    isPreviousFetching: boolean;
    isCurrentFetching: boolean;
    isNextFetching: boolean;

    maxAllowedPersonsCount: number;
    error?: SerializedError;
}

function getPage(payload: Partial<IDepartmentPlanData>, date: Date) {
    return {
        persons: payload.persons ?? [],
        intervals: payload.intervals ?? [],
        publicHolidays: payload.publicHolidays ?? [],
        tooMuchPersons: payload.tooMuchPersons ?? false,
        shouldFetchNext: true,
        fetchedIDs: [],
        recalculatedIDs: [],
        date: date.toISOString(),
    };
}

function updatePage(
    page: IDepartmentPage,
    payload: Partial<IDepartmentPlanData>,
    fetchedIxsRefs: string[],
    recalculatedIxsRefs: string[]
) {
    return {
        ...page,
        fetchedIDs: page.fetchedIDs.concat(fetchedIxsRefs),
        intervals: page.intervals
            .filter(f => recalculatedIxsRefs.indexOf(f.ownerID) < 0)
            .concat(payload.intervals ?? []),
        recalculatedIDs: page.recalculatedIDs.concat(recalculatedIxsRefs),
        shouldFetchNext: true,
    };
}

export const departmentReducer = createReducer<IDepartmentState>(
    {
        isPreviousFetching: false,
        isCurrentFetching: false,
        isNextFetching: false,
        maxAllowedPersonsCount: 0,
    },
    builder =>
        builder
            .addCase(fetchDepartmentPlanData.pending, (state, action) => ({
                ...state,
                isCurrentFetching: true,
                currentRequestID: action.meta.requestId,
            }))
            .addCase(fetchDepartmentPlanData.fulfilled, (state, action) =>
                state.currentRequestID === action.meta.requestId
                    ? {
                          ...state,
                          isCurrentFetching: false,
                          currentPage: getPage(action.payload, action.meta.arg.from),
                          maxAllowedPersonsCount: action.payload.maxAllowedPersonsCount ?? 0,
                      }
                    : state
            )
            .addCase(fetchDepartmentPlanData.rejected, (state, action) =>
                state.currentRequestID === action.meta.requestId
                    ? {
                          ...state,
                          isCurrentFetching: false,
                          currentPage: undefined,
                      }
                    : state
            )
            .addCase(fetchDepartmentPlanPreviousData.pending, (state, action) => ({
                ...state,
                isPreviousFetching: true,
                previousRequestID: action.meta.requestId,
            }))
            .addCase(fetchDepartmentPlanPreviousData.fulfilled, (state, action) =>
                state.previousRequestID === action.meta.requestId
                    ? {
                          ...state,
                          isPreviousFetching: false,
                          previousPage: getPage(action.payload, action.meta.arg.from),
                      }
                    : state
            )
            .addCase(fetchDepartmentPlanPreviousData.rejected, (state, action) =>
                state.previousRequestID === action.meta.requestId
                    ? {
                          ...state,
                          isPreviousFetching: false,
                          previousPage: undefined,
                      }
                    : state
            )
            .addCase(fetchDepartmentPlanNextData.pending, (state, action) => ({
                ...state,
                isNextFetching: true,
                nextRequestID: action.meta.requestId,
            }))
            .addCase(fetchDepartmentPlanNextData.fulfilled, (state, action) =>
                state.nextRequestID === action.meta.requestId
                    ? {
                          ...state,
                          isNextFetching: false,
                          nextPage: getPage(action.payload, action.meta.arg.from),
                      }
                    : state
            )
            .addCase(fetchDepartmentPlanNextData.rejected, (state, action) =>
                state.previousRequestID === action.meta.requestId
                    ? {
                          ...state,
                          isNextFetching: false,
                          nextRequestID: undefined,
                      }
                    : state
            )
            .addCase(fetchDepartmentPlanDataIntervals.pending, (state, action) =>
                state.currentPage
                    ? {
                          ...state,
                          currentPage: {
                              ...state.currentPage,
                              shouldFetchNext: false,
                          },
                          currentRequestID: action.meta.requestId,
                      }
                    : state
            )
            .addCase(fetchDepartmentPlanDataIntervals.fulfilled, (state, action) =>
                state.currentRequestID === action.meta.requestId && state.currentPage
                    ? {
                          ...state,
                          currentPage: updatePage(state.currentPage, action.payload, action.meta.arg.ixsRefs, []),
                      }
                    : state
            )
            .addCase(fetchDepartmentPlanDataIntervals.rejected, (state, action) =>
                state.currentRequestID === action.meta.requestId && state.currentPage
                    ? {
                          ...state,
                          currentPage: {
                              ...state.currentPage,
                              shouldFetchNext: false,
                          },
                      }
                    : state
            )
            .addCase(fetchDepartmentPlanDataPreviousIntervals.pending, (state, action) =>
                state.previousPage
                    ? {
                          ...state,
                          previousPage: {
                              ...state.previousPage,
                              shouldFetchNext: false,
                          },
                          previousRequestID: action.meta.requestId,
                      }
                    : state
            )
            .addCase(fetchDepartmentPlanDataPreviousIntervals.fulfilled, (state, action) =>
                state.previousRequestID === action.meta.requestId && state.previousPage
                    ? {
                          ...state,
                          previousPage: updatePage(state.previousPage, action.payload, action.meta.arg.ixsRefs, []),
                      }
                    : state
            )
            .addCase(fetchDepartmentPlanDataPreviousIntervals.rejected, (state, action) =>
                state.previousRequestID === action.meta.requestId && state.previousPage
                    ? {
                          ...state,
                          previousPage: {
                              ...state.previousPage,
                              shouldFetchNext: false,
                          },
                      }
                    : state
            )
            .addCase(fetchDepartmentPlanDataNextIntervals.pending, (state, action) =>
                state.nextPage
                    ? {
                          ...state,
                          nextPage: {
                              ...state.nextPage,
                              shouldFetchNext: false,
                          },
                          nextRequestID: action.meta.requestId,
                      }
                    : state
            )
            .addCase(fetchDepartmentPlanDataNextIntervals.fulfilled, (state, action) =>
                state.nextRequestID === action.meta.requestId && state.nextPage
                    ? {
                          ...state,
                          nextPage: updatePage(state.nextPage, action.payload, action.meta.arg.ixsRefs, []),
                      }
                    : state
            )
            .addCase(fetchDepartmentPlanDataNextIntervals.rejected, (state, action) =>
                state.nextRequestID === action.meta.requestId && state.nextPage
                    ? {
                          ...state,
                          nextPage: {
                              ...state.nextPage,
                              shouldFetchNext: false,
                          },
                      }
                    : state
            )
            .addCase(fetchDepartmentPlanDataIntervalsRecalculate.pending, (state, action) =>
                state.currentPage
                    ? {
                          ...state,
                          currentPage: {
                              ...state.currentPage,
                              shouldFetchNext: false,
                          },
                          currentRequestID: action.meta.requestId,
                      }
                    : state
            )
            .addCase(fetchDepartmentPlanDataIntervalsRecalculate.fulfilled, (state, action) =>
                state.currentRequestID === action.meta.requestId && state.currentPage
                    ? {
                          ...state,
                          currentPage: updatePage(state.currentPage, action.payload, [], action.meta.arg.ixsRefs),
                      }
                    : state
            )
            .addCase(fetchDepartmentPlanDataIntervalsRecalculate.rejected, (state, action) =>
                state.currentRequestID === action.meta.requestId && state.currentPage
                    ? {
                          ...state,
                          currentPage: {
                              ...state.currentPage,
                              shouldFetchNext: false,
                          },
                      }
                    : state
            )
            .addCase(fetchDepartmentPlanDataIntervalsPreviousRecalculate.pending, (state, action) =>
                state.previousPage
                    ? {
                          ...state,
                          previousPage: {
                              ...state.previousPage,
                              shouldFetchNext: false,
                          },
                          previousRequestID: action.meta.requestId,
                      }
                    : state
            )
            .addCase(fetchDepartmentPlanDataIntervalsPreviousRecalculate.fulfilled, (state, action) =>
                state.previousRequestID === action.meta.requestId && state.previousPage
                    ? {
                          ...state,
                          previousPage: updatePage(state.previousPage, action.payload, [], action.meta.arg.ixsRefs),
                      }
                    : state
            )
            .addCase(fetchDepartmentPlanDataIntervalsPreviousRecalculate.rejected, (state, action) =>
                state.previousRequestID === action.meta.requestId && state.previousPage
                    ? {
                          ...state,
                          previousPage: {
                              ...state.previousPage,
                              shouldFetchNext: false,
                          },
                      }
                    : state
            )
            .addCase(fetchDepartmentPlanDataIntervalsNextRecalculate.pending, (state, action) =>
                state.nextPage
                    ? {
                          ...state,
                          nextPage: {
                              ...state.nextPage,
                              shouldFetchNext: false,
                          },
                          nextRequestID: action.meta.requestId,
                      }
                    : state
            )
            .addCase(fetchDepartmentPlanDataIntervalsNextRecalculate.fulfilled, (state, action) =>
                state.nextRequestID === action.meta.requestId && state.nextPage
                    ? {
                          ...state,
                          nextPage: updatePage(state.nextPage, action.payload, [], action.meta.arg.ixsRefs),
                      }
                    : state
            )
            .addCase(fetchDepartmentPlanDataIntervalsNextRecalculate.rejected, (state, action) =>
                state.nextRequestID === action.meta.requestId && state.nextPage
                    ? {
                          ...state,
                          nextPage: {
                              ...state.nextPage,
                              shouldFetchNext: false,
                          },
                      }
                    : state
            )
            .addCase(resetDeparmentPlanData, (state, action) => ({
                ...state,
                previousPage: undefined,
                currentPage: undefined,
                nextPage: undefined,
                previousRequestID: undefined,
                currentRequestID: undefined,
                nextRequestID: undefined,
            }))
            .addCase(scrollDepartmentPlanPrevious, (state, action) => ({
                ...state,
                nextPage: state.currentPage ? { ...state.currentPage, shouldFetchNext: true } : undefined,
                currentPage: state.previousPage ? { ...state.previousPage, shouldFetchNext: true } : undefined,
                previousPage: undefined,
                previousRequestID: undefined,
                currentRequestID: undefined,
                nextRequestID: undefined,
                isPreviousFetching: false,
                isCurrentFetching: false,
                isNextFetching: false,
            }))
            .addCase(scrollDepartmentPlanNext, (state, action) => ({
                ...state,
                previousPage: state.currentPage ? { ...state.currentPage, shouldFetchNext: true } : undefined,
                currentPage: state.nextPage ? { ...state.nextPage, shouldFetchNext: true } : undefined,
                nextPage: undefined,
                previousRequestID: undefined,
                currentRequestID: undefined,
                nextRequestID: undefined,
                isPreviousFetching: false,
                isCurrentFetching: false,
                isNextFetching: false,
            }))
);
