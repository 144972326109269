import React from "react";
import { useFormatDate } from "../../../hooks/useFormatDate";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { permissions } from "../../../constants/permissions";
import { IMyPlanPageProps } from "./MyPlan";

export function DayCell(props: { day: Date; page: IMyPlanPageProps }) {
    const day = props.day;

    const formatDate = useFormatDate();
    const numDay = formatDate(day, "d.");
    const strDay = formatDate(day, "EEEEEE");

    const publicHolidays = props.page.publicHolidays.map(s => new Date(s));
    const isHoliday = publicHolidays.some(s => s.getTime() === day.getTime());

    const dayInWeek = day.getDay();
    const isBold = isHoliday || [0, 6].includes(dayInWeek); // Sobota nebo Neděle

    const isCheckAllowed =
        !useAppSelector(s => s.session.user.token?.userDisabledActions.some(p => p === permissions.plan.check)) ??
        false;
    const isChangeAllowed =
        !useAppSelector(s => s.session.user.token?.userDisabledActions.some(p => p === permissions.plan.approve)) ??
        false;

    const signedDays = props.page.signedDays.map(s => new Date(s));
    const isSigned = isCheckAllowed ? signedDays.some(d => d.getTime() === day.getTime()) : false;

    const signableDays = props.page.signableDays.map(s => new Date(s));
    const isSignable = isCheckAllowed ? signableDays.some(d => d.getTime() === day.getTime()) : false;

    const changeableDays = props.page.changeableDays.map(s => new Date(s));
    const isChangeable = isChangeAllowed ? changeableDays.some(d => d.getTime() === day.getTime()) : false;

    function renderLockIcon() {
        if (isChangeable)
            return <span className="k-icon k-i-unlock" style={{ color: "red" }} title="Changeable"></span>;
        else if (isSigned) return <span className="k-icon k-i-lock" title="Signed"></span>;
        else if (isSignable)
            return <span className="k-icon k-i-unlock" style={{ color: "orange" }} title="For signing"></span>;
    }

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
            }}
        >
            <div
                style={{
                    display: "flex",
                    fontWeight: isBold ? "bold" : undefined,
                    color: isHoliday ? "red" : undefined,
                }}
            >
                <div style={{ width: "24px", textAlign: "right" }}>{numDay}</div>
                <div style={{ width: "26px", textAlign: "left" }}>{strDay}</div>
            </div>
            {renderLockIcon()}
        </div>
    );
}
