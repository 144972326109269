import { createAsyncThunk, createAction, ThunkAction, Action } from "@reduxjs/toolkit";
import { ICredentials } from "../../model/security/ICredentials";
import { IRootState } from "../reducers/root";
import { INormalizedToken, IRawToken } from "../../model/security/tokens";
import { LoginError } from "../../model/errors/LoginError";
import { permissions } from "../../constants/permissions";
import { KEY_REFRESH_TOKEN } from "../middlewares/tokenPersistor";
import { AppThunkAction } from "../../model/redux/AppThunkAction";
import { handleResponseError } from "../../helpers/handleResponseError";
import { DelegatedAuthStyle } from "../../model/app/DelegatedAuthStyle";

const DOMAIN = "user";

interface FetchTokenArgs {
    credentials?: ICredentials;
    ticket?: string;
    refreshToken?: string;
}

export const fetchToken = createAsyncThunk<INormalizedToken, FetchTokenArgs, { state: IRootState }>(
    `${DOMAIN}/token/fetch`,
    async (arg, api) => {
        let body = "";

        if (arg.refreshToken) {
            body = `grant_type=refresh_token&refresh_token=${arg?.refreshToken}`;
        } else if (arg.credentials) {
            body = `grant_type=password&username=${arg.credentials.username}&password=${encodeURIComponent(
                arg.credentials.password
            )}`;
        } else if (arg.ticket) {
            body = `grant_type=password&username=${arg.ticket}`;
        } else {
            throw new Error("Fetch token require any credentials type.");
        }

        if (api.getState().app.multiTenant) {
            body += `&customerCode=${api.getState().app.customerCode}`;
        }

        body += "&application=selfservice";

        const requestOptions: RequestInit = {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: body,
            signal: api.signal,
        };

        const response = await fetch(api.getState().config.config?.endpoint + "/Token", requestOptions);

        return await handleResponse(response);
    }
);

export const clearError = createAction(`${DOMAIN}/error/clear`);

export const logout = createAction(`${DOMAIN}/logout`);

const INFINITE_LOOP_PROTECTION_COOKIE = "infiniteLoopProtection";

export function tryLoginAutomatically(): ThunkAction<Promise<void>, IRootState, void, Action> {
    return async (dispatch, getState) => {
        const token = sessionStorage.getItem(KEY_REFRESH_TOKEN);

        if (token) {
            await dispatch(
                fetchToken({
                    refreshToken: token,
                })
            );
        }

        if (getState().session.user.isAuthenticated) return;

        if (getState().app.isDelegatedAuthEnabled) {
            if (document.cookie.indexOf(INFINITE_LOOP_PROTECTION_COOKIE) === -1) {
                if (getState().app.delegatedAuthStyle === DelegatedAuthStyle.automaticRedirection) {
                    dispatch(redirectToDelegatedAuth());
                }
            } else {
                // infinite loop protection cookie found
                // expire infinite loop protection cookie
                document.cookie = `${INFINITE_LOOP_PROTECTION_COOKIE}=; Max-Age=0; SameSite=Lax`;

                const ticket = await dispatch(fetchDelegatedTicket());

                if (ticket !== null) {
                    await dispatch(fetchToken({ ticket: ticket }));
                }
            }
        } else if (getState().app.canRunWindowsAuth) {
            const ticket = await dispatch(fetchWindowsTicket());

            if (ticket !== null) {
                await dispatch(fetchToken({ ticket: ticket }));
            }
        }
    };
}

export function redirectToDelegatedAuth(): AppThunkAction<void> {
    return (dispatch, getState) => {
        document.cookie = `${INFINITE_LOOP_PROTECTION_COOKIE}=true; SameSite=Lax`;
        window.location.href = `${getState().config.config.endpoint}/Test/TestAuth.aspx?r=${encodeURI(
            window.location.href
        )}`;
    };
}

export function redirectToDelegatedLogout(): AppThunkAction<void> {
    return (dispatch, getState) => {
        document.cookie = `${INFINITE_LOOP_PROTECTION_COOKIE}=true; SameSite=Lax`;
        window.location.href = `${getState().config.config.endpoint}/Test/TestAuth.aspx?u=${encodeURI(
            window.location.href
        )}`;
    };
}

export function fetchDelegatedTicket(): ThunkAction<Promise<string>, IRootState, void, Action> {
    return async (dispatch, getState) => {
        const requestOptions: RequestInit = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
        };

        const response = await fetch(
            getState().config.config.endpoint + "/controllers/customcontrollers/AuthIdentity/AuthUserTicket",
            requestOptions
        );

        if (response.ok) {
            const ticket = await response.json();

            return ticket === "" ? null : ticket;
        } else {
            return null;
        }
    };
}

export function fetchWindowsTicket(): ThunkAction<Promise<string>, IRootState, void, Action> {
    return async (dispatch, getState) => {
        const requestOptions: RequestInit = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
        };

        const response = await fetch(
            getState().config.config.endpoint + "/controllers/customcontrollers/WindowsIdentity/WindowsUserTicket",
            requestOptions
        );

        if (response.ok) {
            const ticket = await response.json();

            return ticket === "" ? null : ticket;
        } else {
            return null;
        }
    };
}

export function fetchUserLanguage(): AppThunkAction<Promise<string | null>> {
    return async (dispatch, getState) => {
        const requestOptions: RequestInit = {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getState().session.user.token?.access_token}`,
            },
        };

        const response = await fetch(
            getState().config.config.endpoint + "/controllers/SelfService/UserLanguage",
            requestOptions
        );

        await handleResponseError(response);

        return await response.json();
    };
}

async function handleResponse(response: Response): Promise<INormalizedToken> {
    const data = await response.json();

    if (response.status === 200) {
        const token = normalizeToken(data);

        requireRunAppPermission(token);

        return token;
    } else if (response.status === 400) {
        throw new LoginError(data.error, data.error_description);
    } else {
        throw new Error(response.statusText);
    }
}

function normalizeToken(token: IRawToken): INormalizedToken {
    return {
        ...token,
        userDisabledActions: token.userDisabledActions.split(",").filter((p: string) => p !== ""),
        userInvisibleActions: token.userInvisibleActions.split(",").filter((p: string) => p !== ""),
        userRoles: token.userRoles.split(",").filter((p: string) => p !== ""),
    };
}

function requireRunAppPermission(token: INormalizedToken): void {
    if (token.userDisabledActions.some(p => p === permissions.app.run))
        throw new LoginError("login_error_app_usage_not_allowed", "You are not allowed to use this application.");
}
