import { combineReducers } from "redux";
import { ErrorsVisibilityState, errorsVisibilitySlice } from "../slices/errorsVisibility";
import { ErrorsListState, errorsListSlice } from "../slices/errorsList";

export interface IErrorsState {
    list: ErrorsListState;
    areVisible: ErrorsVisibilityState;
}

export const errorsReducer = combineReducers<IErrorsState>({
    list: errorsListSlice.reducer,
    areVisible: errorsVisibilitySlice.reducer,
});
