import { List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { CenterPickerDialog } from "../../components/CenterPickerDialog";
import { ErrorNotification } from "../../components/ErrorNotification";
import { NavBar } from "../../components/NavBar/NavBar";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import PermissionGuard from "../../components/PermissionGuard";
import { SpinnerBox } from "../../components/Spinner/SpinnerBox";
import { TooMuchPersonNotification } from "../../components/TooMuchPersonNotification";
import { InternalMaxLimitParam } from "../../constants/common";
import { permissions } from "../../constants/permissions";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useStrings } from "../../hooks/useStrings";
import { IPresenceForPerson } from "../../model/IPresenceForPerson";
import { LeftPanelParams, SelectedPresence } from "../../model/LeftPanelParams";
import { initialize as initializeCenterPicker } from "../../redux/actions/centerPicker";
import {
    fetchAccountsTranslations,
    fetchPassesTranslations,
    fetchPresenceData,
    fetchPresenceTranslations,
} from "../../redux/actions/presence";
import { SelectedCenterButton } from "../Plan/Department/SelectedCenterButton";
import styles from "./Presence.module.scss";
import PresenceDetail from "./PresenceDetail";

enum EDialog {
    None,
    CenterPicker,
    Detail,
}

const param: LeftPanelParams = {
    StartDate: new Date(),
    ActivePersonsOnly: false,
    ValidPersonsOnly: true,
    CurrentAgenda: "ANeTPresenceApp",
    SelectedTab: "tab-centers",
    SelectedPresence: 0,
    SelectedIxsStr: "null",
    SelectedIxsRef: "aimpI<DE8=SF",
    IsActual: true,
};

export default function Presence() {
    const dispatch = useAppDispatch();
    const strings = useStrings();

    const data = useAppSelector(s => s.session.presence.presenceData);
    const isFetchingData = useAppSelector(s => s.session.presence.isFetching);
    const presenceError = useAppSelector(s => s.session.presence.error);

    const presenceTranslations = useAppSelector(s => s.session.presenceTranslations.presenceTranslations);
    const isPresenceTranslationsFetching = useAppSelector(s => s.session.presenceTranslations.isFetching);
    const presenceTranslationError = useAppSelector(s => s.session.presenceTranslations.error);

    const isPassesTranslationsFetching = useAppSelector(s => s.session.passesTranslations.isFetching);
    const passesTranslationError = useAppSelector(s => s.session.passesTranslations.error);

    const accountsTranslations = useAppSelector(s => s.session.accountsTranslations.accountsTranslations);
    const isAccountsTranslationsFetching = useAppSelector(s => s.session.accountsTranslations.isFetching);
    const accountsTranslationError = useAppSelector(s => s.session.accountsTranslations.error);

    const isReasonVisible = useAppSelector(
        s => !s.session.user.token?.userInvisibleActions.some(s => s === permissions.presence.showReason) ?? false
    );
    const count = useAppSelector(s => s.session.presence.count);

    const [visibleDialog, setVisibleDialog] = useState(EDialog.None);
    const [detailData, setDetailData] = useState<IPresenceForPerson>();

    const presenceOptions = [
        strings.presence.AllPersons,
        strings.presence.OnlyPresent,
        strings.presence.OnlyNotPresent,
        strings.presence.OnlyInconsistent,
        strings.presence.OnlyWithShift,
    ];

    const handleFilterChange = (event: DropDownListChangeEvent) => {
        switch (event.value) {
            case presenceOptions[0]:
                param.SelectedPresence = SelectedPresence.isCheckedAllPersons;
                break;
            case presenceOptions[1]:
                param.SelectedPresence = SelectedPresence.isCheckedOnlyPresent;
                break;
            case presenceOptions[2]:
                param.SelectedPresence = SelectedPresence.isCheckedOnlyAbsent;
                break;
            case presenceOptions[3]:
                param.SelectedPresence = SelectedPresence.isCheckedOnlyInconsintent;
                break;
            case presenceOptions[4]:
                param.SelectedPresence = SelectedPresence.isCheckedWithPlannedShift;
                break;
            default:
                param.SelectedPresence = SelectedPresence.isCheckedAllPersons;
                break;
        }
        param.StartDate = param.EndDate = new Date();
        dispatch(fetchPresenceData(param));
    };

    useEffect(() => {
        dispatch(initializeCenterPicker())
            .then(() => dispatch(fetchPresenceTranslations()))
            .then(() => dispatch(fetchPassesTranslations()))
            .then(() => dispatch(fetchAccountsTranslations()))
            .then(() => dispatch(fetchPresenceData(param)));
    }, [dispatch]);

    const handleDialogClose = () => setVisibleDialog(EDialog.None);

    const handleSelectedCenterButtonClick = () => setVisibleDialog(EDialog.CenterPicker);

    const handleCenterPickerDialogConfirm = () => {
        handleDialogClose();
        dispatch(fetchPresenceData(param));
    };

    const getPresenceTranslation = (presence: number | null): string =>
        presenceTranslations.find(ipo => ipo.PK_VALUE === presence?.toString())?.PREKLAD ?? "";

    const getAccountTranslation = (id_ucet: number | null): string =>
        accountsTranslations.find(ipo => ipo.PK_VALUE === id_ucet?.toString())?.PREKLAD ?? "";

    const isExistingMail = (row: IPresenceForPerson): boolean => row.EMAIL2 !== "" && row.EMAIL2 != null;

    const getHiddenReason = (smer: number): string =>
        smer === 0 ? strings.presence.Unknown : smer === 1 ? strings.presence.Present : strings.presence.Absent;

    const phoneNumberArray = (row: IPresenceForPerson): string[] => {
        const arrayNumber: string[] = [];
        if (row.TELEFON1) {
            arrayNumber.push(row.TELEFON1);
        }
        if (row.TELEFON2) {
            arrayNumber.push(row.TELEFON2);
        }
        if (row.TELEFON3) {
            arrayNumber.push(row.TELEFON3);
        }
        return arrayNumber;
    };

    const handleSingleNumberClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, row: IPresenceForPerson) => {
        e.preventDefault();
        window.location.href = "tel:" + phoneNumberArray(row)[0];
    };

    const getMailToAddress = (row: IPresenceForPerson): string => "mailto:" + row.EMAIL2;

    const handleMailClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, row: IPresenceForPerson): void => {
        e.preventDefault();
        window.location.href = getMailToAddress(row);
    };

    const openDetailDialog = (ixs_ref: string) => {
        if (data) {
            const dataSource = data.find(ipo => ipo.IXS_REF === ixs_ref);
            setDetailData(dataSource);
            setVisibleDialog(EDialog.Detail);
        }
    };

    const handleDetailPresenceDialogClose = () => setVisibleDialog(EDialog.None);

    const isFetching = () =>
        isFetchingData ||
        isPassesTranslationsFetching ||
        isPresenceTranslationsFetching ||
        isAccountsTranslationsFetching;

    const isConnectionError = () =>
        presenceError || passesTranslationError || presenceTranslationError || accountsTranslationError;

    return (
        <PageLayout header={<NavBar canNavigateRoot label={strings.presence.TitlePresence} />}>
            <div className={styles.params}>
                <SelectedCenterButton
                    className={styles.centerButton}
                    primary
                    onClick={handleSelectedCenterButtonClick}
                />
                <DropDownList data={presenceOptions} onChange={handleFilterChange} defaultValue={presenceOptions[0]} />
            </div>
            {isFetching() && <SpinnerBox />}
            {isConnectionError() && (
                <ErrorNotification
                    error={
                        presenceError ?? passesTranslationError ?? presenceTranslationError ?? accountsTranslationError
                    }
                />
            )}
            {!isFetching() &&
                !isConnectionError() &&
                // condition for internal parameter max count presence due to performance issues
                (count <= InternalMaxLimitParam ? (
                    <List>
                        {!data?.length && <label className={styles.noData}>{strings.common.NoItems}</label>}
                        {/* řádky */}
                        {data?.map((row: IPresenceForPerson, key: any) => (
                            <ListItem key={key} divider dense button onClick={() => openDetailDialog(row.IXS_REF)}>
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            {row.FullName}
                                            <br />
                                            <div
                                                className={
                                                    row.PRUCHOD_SMER === 0
                                                        ? styles.gray
                                                        : row.PRUCHOD_SMER === 1
                                                        ? styles.green
                                                        : styles.red
                                                }
                                            >
                                                {isReasonVisible
                                                    ? row.ID_UCET
                                                        ? getAccountTranslation(row.ID_UCET)
                                                        : getPresenceTranslation(row.PRITOMNOST)
                                                    : getHiddenReason(row.PRUCHOD_SMER)}
                                            </div>
                                        </React.Fragment>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <PermissionGuard permission={permissions.presence.showContacts}>
                                        {isExistingMail(row) && (
                                            <Button iconClass="fa fa-envelope" onClick={e => handleMailClick(e, row)} />
                                        )}
                                    </PermissionGuard>
                                    <PermissionGuard permission={permissions.presence.showContacts}>
                                        {phoneNumberArray(row).length > 1 && (
                                            <DropDownButton
                                                iconClass="fa fa-phone"
                                                items={phoneNumberArray(row).map((number: string, key: any) => (
                                                    <a key={key} href={"tel:" + number} data-rel="external">
                                                        <span>{number}</span>
                                                    </a>
                                                ))}
                                            />
                                        )}
                                        {phoneNumberArray(row).length === 1 && (
                                            <Button
                                                iconClass="fa fa-phone"
                                                onClick={e => handleSingleNumberClick(e, row)}
                                            />
                                        )}
                                    </PermissionGuard>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <TooMuchPersonNotification />
                ))}
            {ReactDOM.createPortal(
                <React.Fragment>
                    {visibleDialog === EDialog.CenterPicker && (
                        <CenterPickerDialog
                            onClose={handleDialogClose}
                            onConfirm={handleCenterPickerDialogConfirm}
                            isPresence={true}
                        />
                    )}
                    {visibleDialog === EDialog.Detail && (
                        <PresenceDetail onClose={handleDetailPresenceDialogClose} detailData={detailData!} />
                    )}
                </React.Fragment>,
                document.body
            )}
        </PageLayout>
    );
}
