import { SerializedInterval, DeserializedInterval } from "../../../model/plan/types";

export function deserialize(intrs: SerializedInterval[]): DeserializedInterval[] {
    return intrs.map(
        intr =>
            ({
                ...intr,
                day: new Date(intr.day),
                begin: new Date(intr.begin),
                end: new Date(intr.end),
            } as DeserializedInterval)
    );
}
