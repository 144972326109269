import { AppThunkAction } from "../../../model/redux/AppThunkAction";
import { buildKey } from "./buildKey";

export function setItem<T>(key: string, value: T): AppThunkAction<void> {
    return dispatch => {
        const realKey = dispatch(buildKey(key));

        try {
            const json = JSON.stringify(value);
            localStorage.setItem(realKey, json);
        } catch (e) {
            console.error(e);
        }
    };
}
