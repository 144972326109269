import React from "react";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import { NavBar } from "../../components/NavBar/NavBar";
import List from "@material-ui/core/List";
import styles from "./Unavailability.module.scss";
import { RouteChildrenProps } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { connect } from "react-redux";
import { IRootState } from "../../redux/reducers/root";
import { PLNUNAVA } from "../../model/PLNUNAVA";
import { QueryParam } from "../../model/CommonQueryParams";
import { fetchUnavalabilities } from "../../redux/actions/unavailability";
import { IStrings } from "../../constants/languageStrings/IStrings";
import { ListItem, ListItemText, ListItemSecondaryAction } from "@material-ui/core";
import { ILanguage } from "../../model/localization/ILanguage";
import UnavailabilityDetail from "./UnavailabilityDetail";
import NewUnavailability from "./NewUnavailability";
import PermissionGuard from "../../components/PermissionGuard";
import { permissions } from "../../constants/permissions";
import { IBaseMenuItem } from "../../components/NavBar/IBaseMenuItem";
import { SpinnerBox } from "../../components/Spinner/SpinnerBox";
import { SerializedError } from "@reduxjs/toolkit";
import { ErrorNotification } from "../../components/ErrorNotification";
import { selectIxsRef } from "../../redux/selectors/selectIxsRef";
import { fetchDashboardData } from "../../redux/actions/dashboard";
export class Unavailability extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            dialog: {
                detail: false,
                newRequest: false,
            },
        };
    }

    async componentDidMount() {
        let param: QueryParam<string> = { Value: this.props.ixsRef };
        if (this.props.isApprovablePlnunava === undefined) {
            await this.props.onDashboardDataFetch();
            this.props.history.push("my-unavailability");
        }
        await this.props.onDatasourceFetch(param);
    }

    openDetailDialog(row: PLNUNAVA) {
        this.setState({
            detailData: row,
            dialog: {
                detail: true,
            },
        });
    }

    async openNewRequestDialog() {
        this.setState({ dialog: { newRequest: true } });
    }

    private handleDialogsClose = async () => {
        this.setState({ dialog: { detail: false } });
        this.setState({ dialog: { newRequest: false } });
        let param: QueryParam<string> = { Value: this.props.ixsRef };
        await this.props.onDatasourceFetch(param);
    };

    private createMenuItems = () => {
        return [] as IBaseMenuItem[];
    };

    private handleMenuItemClick = (item: IBaseMenuItem) => {
        switch (item.id) {
            default:
                item.id && console.warn("Unhandled menu item: ", item);
        }
    };

    makeTitle(row: PLNUNAVA): string {
        if (row.STARTTIME === null) {
            return new Date(row.DATE_REF).toLocaleDateString(this.props.language.code);
        } else {
            return (
                new Date(row.STARTTIME).toLocaleDateString(this.props.language.code) +
                " " +
                new Date(row.STARTTIME).toLocaleTimeString(this.props.language.code) +
                " - " +
                new Date(row.ENDTIME!).toLocaleDateString(this.props.language.code) +
                " " +
                new Date(row.ENDTIME!).toLocaleTimeString(this.props.language.code)
            );
        }
    }

    dateTimeCreated(row: PLNUNAVA): string {
        if (row.DATE_CREATE !== null) {
            return (
                new Date(row.DATE_CREATE).toLocaleDateString(this.props.language.code) +
                " " +
                new Date(row.DATE_CREATE).toLocaleTimeString(this.props.language.code)
            );
        } else return "";
    }

    render() {
        let rows = this.props.dataSource || [];
        return (
            <PageLayout
                header={
                    <NavBar
                        canNavigateRoot
                        label={this.props.strings.unavailability.Unavailability}
                        menu={{
                            items: this.createMenuItems(),
                            onItemClick: this.handleMenuItemClick,
                        }}
                    >
                        <PermissionGuard permission={permissions.unavailabilities.edit}>
                            <Button
                                onClick={() => this.openNewRequestDialog()}
                                disabled={
                                    this.props.isApprovablePlnunava || this.props.isApprovablePlnunava === undefined
                                }
                            >
                                {this.props.strings.requests.NewReq}
                            </Button>
                        </PermissionGuard>
                    </NavBar>
                }
            >
                {this.props.isDatasourceFetching && <SpinnerBox></SpinnerBox>}
                {this.props.error && <ErrorNotification error={this.props.error} />}
                {!this.props.isDatasourceFetching && (
                    <List>
                        {/* řádky */}
                        {rows.length > 0 ? (
                            rows.map((row, key) => (
                                <ListItem key={key} divider dense button onClick={() => this.openDetailDialog(row)}>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <span className={styles.titleFont}>{this.makeTitle(row)}</span>
                                                <br></br>
                                                {/* {localization.strings.requests.From + " "}{row.from}{" "+localization.strings.requests.To + " "}{row.to} */}
                                                {row.NOTE}
                                            </React.Fragment>
                                        }
                                        secondary={
                                            <React.Fragment>
                                                {this.props.strings.unavailability.CreatedUnavailability +
                                                    ":  " +
                                                    this.dateTimeCreated(row)}
                                            </React.Fragment>
                                        }
                                    />
                                    <ListItemSecondaryAction></ListItemSecondaryAction>
                                </ListItem>
                            ))
                        ) : (
                            <ListItem>{this.props.strings.common.NoItems}</ListItem>
                        )}
                    </List>
                )}
                {this.state.dialog.detail && this.state.detailData !== undefined && (
                    <UnavailabilityDetail data={this.state.detailData} onClose={this.handleDialogsClose} />
                )}
                {this.state.dialog.newRequest && (
                    <NewUnavailability onClose={this.handleDialogsClose}></NewUnavailability>
                )}
            </PageLayout>
        );
    }
}

interface IStateProps {
    strings: IStrings;
    language: ILanguage;
    ixsRef?: string;
    isApprovablePlnunava?: boolean;

    isDatasourceFetching?: boolean;
    dataSource?: PLNUNAVA[];
    error?: SerializedError;
}

interface IDispatchProps {
    onDatasourceFetch: (e: QueryParam<string>) => Promise<void>;
    onDashboardDataFetch: () => Promise<void>;
}

interface IState {
    detailData?: PLNUNAVA;
    dialog: {
        detail?: boolean;
        newRequest?: boolean;
    };
}

type IProps = IStateProps & IDispatchProps & RouteChildrenProps;
export default connect<IStateProps, IDispatchProps, {}, IRootState>(
    state => ({
        strings: state.localization.strings,
        language: state.localization.language,
        ixsRef: selectIxsRef(state),
        isApprovablePlnunava: state.session.dashboard.data?.isApprovablePlnunava,

        isDatasourceFetching: state.session.unavailabilityFetch.isFetching || state.session.dashboard.isFetching,
        dataSource: state.session.unavailabilityFetch.datasource,
        error: state.session.unavailabilityFetch.error,
    }),
    {
        onDatasourceFetch: (e: QueryParam<string>) => fetchUnavalabilities(e) as any,
        onDashboardDataFetch: () => fetchDashboardData() as any,
    }
)(Unavailability);
