import React from "react";
import { IGroup, IInterval, IntervalContextMenuHandler, IntervalClickHandler, IColumnConfig, IStack } from "./types";
import { GroupRow } from "./rows/GroupRow";
import { IntervalContextMenuHandlerContext } from "./context/IntervalContextMenuHandlerContext";
import { IntervalClickHandlerContext } from "./context/IntervalClickHandlerContext";
import { Header } from "./headers/Header";
import classes from "./Timeline.module.scss";
import { HoursMultiplierContext } from "./context/HoursMultiplierContext";
import { VerticalLines } from "./markers/VerticalLines";
import { GridItem } from "./utils/GridItem";
import { GroupContext } from "./context/GroupContext";
import { StacksContext } from "./context/StacksContext";

const STATIC_ROWS_COUNT = 1;
const STATIC_COLS_COUNT = 1;
const DEFAULT_HOURS_MULTIPLIER = 2;

const defaultStacks: IStack[] = [
    { id: 1, visibleIfEmpty: true },
    { id: 2, visibleIfEmpty: true },
    { id: 3, visibleIfEmpty: false },
];

interface IProps<G extends IGroup, I extends IInterval> {
    groups: G[];
    stacks?: IStack[];
    intervals: I[];
    columns: IColumnConfig[];
    sidebarColumnWidth?: string;
    hoursMultiplier?: number;
    verticalLinesCount?: number;
    onIntervalClick?: IntervalClickHandler<I, G>;
    onIntervalContextMenu?: IntervalContextMenuHandler<I, G>;
    cornerCellContentRenderer?: () => React.ReactNode;
    groupSidebarCellContentRenderer?: (props: { group: G }) => React.ReactNode;
    dayHeaderContentRenderer?: (props: { column: IColumnConfig }) => React.ReactNode;
    columnMarkerRenderer?: (props: { column: IColumnConfig }) => React.ReactNode;
    cellMarkerRenderer?: (props: { group: G; column: IColumnConfig }) => React.ReactNode;
    fetchedIDs?: string[];
    recalculatedIDs?: string[];
    virtualized?: boolean;
}

export default function Timeline<G extends IGroup, I extends IInterval>(props: IProps<G, I>) {
    const hoursMultiplier = props.hoursMultiplier ?? DEFAULT_HOURS_MULTIPLIER;
    return (
        <StacksContext.Provider value={props.stacks ?? defaultStacks}>
            <HoursMultiplierContext.Provider value={hoursMultiplier}>
                <IntervalClickHandlerContext.Provider value={props.onIntervalClick ?? (() => {})}>
                    <IntervalContextMenuHandlerContext.Provider value={props.onIntervalContextMenu ?? (() => {})}>
                        <div
                            className={classes.container}
                            style={{
                                display: "grid",
                                gridTemplateColumns: `${props.sidebarColumnWidth ?? "auto"} ${props.columns
                                    .map(() => "1fr")
                                    .join(" ")}`,
                                gridTemplateRows: `repeat(${props.groups.length + STATIC_ROWS_COUNT}, auto)`,
                            }}
                        >
                            {/* <div > */}
                            <Header
                                columns={props.columns}
                                cornerCellContentRenderer={props.cornerCellContentRenderer}
                                dayHeaderContentRenderer={props.dayHeaderContentRenderer}
                            />
                            {/* </div> */}
                            {props.groups.map((group, index) => (
                                <GroupContext.Provider key={group.id} value={group}>
                                    <GroupRow
                                        group={group}
                                        columns={props.columns}
                                        rowIndex={STATIC_ROWS_COUNT + index + 1}
                                        intervals={props.intervals.filter(intr => intr.group.id === group.id)}
                                        sidebarCellContentRenderer={props.groupSidebarCellContentRenderer}
                                        cellMarkerRenderer={props.cellMarkerRenderer}
                                        className={
                                            !props.virtualized || props.recalculatedIDs?.some(s => s === group.id)
                                                ? undefined
                                                : props.fetchedIDs?.some(s => s === group.id)
                                                ? classes.intervalsNotRecalculated
                                                : classes.intervalsFetching
                                        }
                                    />
                                </GroupContext.Provider>
                            ))}
                            {props.columns.map((col, index) => {
                                const columnMarker =
                                    props.columnMarkerRenderer &&
                                    props.columnMarkerRenderer({
                                        column: col,
                                    });
                                return (
                                    <React.Fragment key={col.id}>
                                        {columnMarker && (
                                            <GridItem
                                                column={{
                                                    start: index + 1 + STATIC_COLS_COUNT,
                                                    end: index + 1 + STATIC_COLS_COUNT,
                                                }}
                                                row={{
                                                    start: STATIC_ROWS_COUNT + 1,
                                                    end: props.groups.length + 1 + STATIC_ROWS_COUNT,
                                                }}
                                            >
                                                {columnMarker}
                                            </GridItem>
                                        )}
                                        <GridItem
                                            column={{
                                                start: index + 1 + STATIC_COLS_COUNT,
                                                end: index + 1 + STATIC_COLS_COUNT,
                                            }}
                                            row={{
                                                start: STATIC_ROWS_COUNT + 1,
                                                end: props.groups.length + 1 + STATIC_ROWS_COUNT,
                                            }}
                                        >
                                            <VerticalLines
                                                count={props.verticalLinesCount ?? Math.floor(24 / hoursMultiplier)}
                                            />
                                        </GridItem>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </IntervalContextMenuHandlerContext.Provider>
                </IntervalClickHandlerContext.Provider>
            </HoursMultiplierContext.Provider>
        </StacksContext.Provider>
    );
}
