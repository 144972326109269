import React from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { IRootState } from "../../redux/reducers/root";
import ErrorsButton from "./ErrorsButton";
import ErrorsDialog from "./ErrorsDialog";

export function ErrorsManager() {
    const isButtonVisible = useSelector<IRootState, boolean>(s => s.errors.list.length > 0);
    const isDialogVisible = useSelector<IRootState, boolean>(s => s.errors.areVisible);

    return ReactDOM.createPortal(
        <>
            {isButtonVisible && <ErrorsButton />}
            {isDialogVisible && <ErrorsDialog />}
        </>,
        document.body
    );
}
