import React from "react";
import { Dialog, DialogActionsBar, DialogCloseEvent } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import styles from "../Requests/Requests.module.scss";
import { Input } from "@progress/kendo-react-inputs";
import { connect } from "react-redux";
import { IRootState } from "../../redux/reducers/root";
import { ILocalizationState } from "../../redux/slices/localization";
import { IntlProvider } from "@progress/kendo-react-intl";
import { SpinnerBox } from "../../components/Spinner/SpinnerBox";
import { PLNUNAVA } from "../../model/PLNUNAVA";
import { QueryParam } from "../../model/CommonQueryParams";
import { createUnavailability } from "../../redux/actions/unavailability";
import TranslatedDatePicker from "../../components/Pickers/TranslatedDatePicker";
import TranslatedTimePicker from "../../components/Pickers/TranslatedTimePicker";
import { selectIxsRef } from "../../redux/selectors/selectIxsRef";

class NewRequest extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        let tomorrow: Date = new Date().addDays(1).getStartOfDay();
        this.state = {
            dateFrom: this.props.defaults?.day ?? tomorrow,
            timeFrom: this.props.defaults?.begin ?? new Date(0, 0, 0, 0, 0, 0, 0),
            timeTo: this.props.defaults?.end ?? new Date(0, 0, 0, 23, 59, 0, 0),
            comment: "",
        };
    }

    fromDateChange = (day: Date) => {
        this.setState({ dateFrom: day });
    };

    fromTimeChanged = (time: Date) => {
        this.setState({ timeFrom: time });
        if (this.state.timeTo! < time) {
            this.setState({ timeTo: time });
        }
    };

    toTimeChanged = (time: Date) => {
        this.setState({ timeTo: time });
        if (this.state.timeFrom! > time) {
            this.setState({ timeFrom: time });
        }
    };

    private handleSaveButtonClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const unava: PLNUNAVA = {
            IXS_REF: this.props.ixsRef ?? "",
            DATE_REF: this.state.dateFrom!,
            NOTE: this.state.comment,
            STARTTIME: this.state.dateFrom!.addTime(this.state.timeFrom!),
            ENDTIME: this.state.dateFrom!.addTime(this.state.timeTo!),
            DATE_CREATE: new Date(),
        };
        let param: QueryParam<PLNUNAVA> = { Value: unava };
        this.props.onCreateUnavailability && (await this.props.onCreateUnavailability(param));

        this.props.onClose &&
            this.props.onClose({
                originalEvent: event,
                canceled: false,
                saved: true,
            });
    };

    public render() {
        let steps: number = 30;
        return (
            <IntlProvider locale={this.props.localization.language.code}>
                <Dialog
                    title={this.props.localization.strings.unavailability.NewUnavailability}
                    onClose={this.props.onClose}
                    autoFocus
                    width="90%"
                    closeIcon={false}
                >
                    {this.props.isUnavailabilityCreating ? (
                        <SpinnerBox />
                    ) : (
                        <>
                            <div className={styles.datePicker}>
                                <label>{this.props.localization.strings.common.Day + ": "}</label>
                                <TranslatedDatePicker selectedDay={this.state.dateFrom!} onPick={this.fromDateChange} />
                            </div>
                            <div className={styles.datePicker}>
                                <label>{this.props.localization.strings.unavailability.TimeFrom + ": "}</label>
                                <TranslatedTimePicker
                                    selectedTime={this.state.timeFrom!}
                                    steps={steps}
                                    onPick={this.fromTimeChanged}
                                />
                            </div>
                            <div className={styles.datePicker}>
                                <label>{this.props.localization.strings.unavailability.TimeTo + ": "}</label>
                                <TranslatedTimePicker
                                    selectedTime={this.state.timeTo!}
                                    steps={steps}
                                    onPick={this.toTimeChanged}
                                />
                            </div>
                            {
                                <Input
                                    label={this.props.localization.strings.unavailability.NoteComment}
                                    onChange={e => this.setState({ comment: e.value })}
                                />
                            }
                        </>
                    )}

                    <DialogActionsBar>
                        <Button
                            onClick={e =>
                                this.props.onClose &&
                                this.props.onClose({
                                    originalEvent: e,
                                    canceled: true,
                                    saved: false,
                                })
                            }
                            disabled={this.props.isUnavailabilityCreating}
                        >
                            {this.props.localization.strings.common.Cancel}
                        </Button>

                        <Button onClick={this.handleSaveButtonClick} disabled={this.props.isUnavailabilityCreating}>
                            {this.props.localization.strings.common.Save}
                        </Button>
                    </DialogActionsBar>
                </Dialog>
            </IntlProvider>
        );
    }
}

interface IState {
    dateFrom?: Date;
    timeFrom?: Date | null;
    timeTo?: Date | null;
    comment: string;
}

interface IOwnProps {
    onClose?: (e: CloseEvent | DialogCloseEvent) => void;
    defaults?: {
        day: Date;
        begin: Date;
        end: Date;
        isAllDay: boolean;
    };
}

interface IStateProps {
    localization: ILocalizationState;
    ixsRef?: string;

    isUnavailabilityCreating?: boolean;
    isSuccessfullyCreated?: boolean;
}

interface IDispatchProps {
    onCreateUnavailability: (e: QueryParam<PLNUNAVA>) => Promise<void>;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

export interface CloseEvent {
    originalEvent: React.MouseEvent;
    canceled: boolean;
    saved: boolean;
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IRootState>(
    state => ({
        localization: state.localization,
        ixsRef: selectIxsRef(state),

        isUnavailabilityCreating: state.session.unavailabilityCreate.isFetching,
        isSuccessfullyCreated: state.session.unavailabilityCreate.succesful,
    }),
    {
        onCreateUnavailability: (e: QueryParam<PLNUNAVA>) => createUnavailability(e) as any,
    }
)(NewRequest);
