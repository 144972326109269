import React from "react";
import classes from "./ErrorsButton.module.scss";
import { Button } from "@progress/kendo-react-buttons";
import { connect } from "react-redux";
import { IRootState } from "../../redux/reducers/root";
import { IStrings } from "../../constants/languageStrings/IStrings";
import { errorsVisibilitySlice } from "../../redux/slices/errorsVisibility";

interface IStateProps {
    strings: IStrings;
    count: number;
}

interface IDispatchProps {
    onClick: () => void;
}

type Props = IStateProps & IDispatchProps;

const ErrorsButton = (props: Props) => (
    <Button title={props.strings.debug.ShowError} icon="preview" className={classes.button} onClick={props.onClick}>
        {`${props.strings.debug.ShowError} (${props.count})`}
    </Button>
);

export default connect<IStateProps, IDispatchProps, {}, IRootState>(
    state => ({
        strings: state.localization.strings,
        count: state.errors.list.length,
    }),
    dispatch => ({
        onClick: () => dispatch(errorsVisibilitySlice.actions.set(true)),
    })
)(ErrorsButton);
