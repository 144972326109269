import { createAsyncThunk } from "@reduxjs/toolkit";
import { InternalMaxLimitParam } from "../../constants/common";
import { handleResponseError } from "../../helpers/handleResponseError";
import { QueryParam } from "../../model/CommonQueryParams";
import { IPlanPerson } from "../../model/IPlanPerson";
import { PlanStatesSelfServiceParams } from "../../model/LeftPanelParams";
import { IRootState } from "../reducers/root";

const DOMAIN = "PlanState";

export const fetchPlanStates = createAsyncThunk<IPlanPerson[], PlanStatesSelfServiceParams, { state: IRootState }>(
    `${DOMAIN}/fetchPlanStates`,
    async (arg, api) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
        let argParam: QueryParam<PlanStatesSelfServiceParams> = { Value: arg };
        arg.SelectedIxsStr = api.getState().session.centerPicker.selected?.id;
        arg.IncludeChildren = api.getState().session.centerPicker.includingSubcenters;
        arg.CheckedCategories = [api.getState().session.categories.selected];
        const requestOptions: RequestInit = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(argParam),
        };
        //`/breeze/IpoData/GetPresenceByLeftPanelParams?$skip=0&$top=${maxDataLoad}&$inlinecount=allpages`
        const response = await fetch(
            api.getState().config.config?.endpoint +
                `/breeze/IpoData/SelfService_GetPlanStates?$skip=0&$top=${
                    InternalMaxLimitParam + 1
                }&$inlinecount=allpages`,
            requestOptions
        );

        await handleResponseError(response);

        return await response.json();
    }
);

export const getParamsForPlanStates = createAsyncThunk<{}, void, { state: IRootState }>(
    `${DOMAIN}/getParamsForPlanStates`,
    async (arg, api) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
        const requestOptions: RequestInit = {
            method: "GET",
            headers: headers,
        };
        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/SelfService_GetPlanStatesParams",
            requestOptions
        );

        await handleResponseError(response);

        return await response.json();
    }
);
