import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRootState } from "../reducers/root";
import { IPersonalCardData } from "../../model/IPersonalCardData";
import { handleResponseError } from "../../helpers/handleResponseError";

export const fetchPersonalCardData = createAsyncThunk<Partial<IPersonalCardData>, void, { state: IRootState }>(
    "personalCard/data/fetch",
    async function (arg, api) {
        let requestOptions: RequestInit = {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${api.getState().session.user.token?.access_token}`,
            },
            signal: api.signal,
        };

        const response = await fetch(
            api.getState().config.config?.endpoint + "/controllers/SelfService/PersonalCard",
            requestOptions
        );

        await handleResponseError(response);

        return await response.json();
    }
);
