import React, { useEffect, useState } from "react";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import { NavBar } from "../../components/NavBar/NavBar";
import { List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import styles from "./Overtime.module.scss";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { SpinnerBox } from "../../components/Spinner/SpinnerBox";
import { useStrings } from "../../hooks/useStrings";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { fetchOvertime, setOvertimeStatus } from "../../redux/actions/overtime";
import { useAppSelector } from "../../hooks/useAppSelector";
import { OvertimeRequest } from "../../redux/reducers/overtime";
import { timeOptions } from "../../constants/dates";

const getInterval = (startDate: string, endDate: string) => {
    const f = new Intl.DateTimeFormat(undefined, timeOptions);
    return f.format(new Date(startDate)) + " - " + f.format(new Date(endDate));
};

function StatusIcon(props: { approved: boolean }) {
    if (props.approved) {
        return <span style={{ color: "green" }} className="k-icon k-i-check" />;
    } else {
        return <span style={{ color: "blue" }} className="k-icon k-i-clock" />;
    }
}

export default function Overtime() {
    const dispatch = useAppDispatch();
    const strings = useStrings();
    const data = useAppSelector(s => s.session.overtime.data);
    const loading = useAppSelector(s => s.session.overtime.isFetching);
    const [selectedItem, setSelectedItem] = useState<OvertimeRequest>();

    useEffect(() => {
        dispatch(fetchOvertime());
    }, [dispatch]);

    return (
        <PageLayout header={<NavBar canNavigateRoot menu={{ items: [] }} label={strings.overtime.Title} />}>
            {loading ? (
                <SpinnerBox />
            ) : (
                <List>
                    {data.map(item => (
                        <ListItem key={"ot_" + item.id} divider button onClick={() => setSelectedItem(item)}>
                            <ListItemText
                                primary={new Date(item.date).toLocaleDateString()}
                                secondary={
                                    <>
                                        {getInterval(item.start, item.end)}
                                        <br />
                                        {item.accountName}
                                        <br />
                                        {item.note}
                                    </>
                                }
                            ></ListItemText>
                            {(item.approvable || item.approved) && (
                                <ListItemSecondaryAction>
                                    <div className={styles.icon}>
                                        <StatusIcon approved={item.approved} />
                                    </div>
                                </ListItemSecondaryAction>
                            )}
                        </ListItem>
                    ))}
                </List>
            )}
            {selectedItem && <OvertimeDetail item={selectedItem} onClose={() => setSelectedItem(undefined)} />}
        </PageLayout>
    );
}

function OvertimeDetail(props: { item: OvertimeRequest; onClose: () => void }) {
    const f = new Intl.DateTimeFormat(undefined, timeOptions);
    const dispatch = useAppDispatch();
    const strings = useStrings();

    const setStatus = (approve: boolean) =>
        dispatch(setOvertimeStatus({ Id: props.item.id, Approve: approve })).then(() => {
            props.onClose();
            dispatch(fetchOvertime());
        });

    return (
        <Dialog title={strings.overtime.DialogTitle} onClose={props.onClose} width="90%">
            <Input label={strings.overtime.Day} readOnly value={new Date(props.item.date).toLocaleDateString()} />
            <Input label={strings.overtime.From} readOnly value={f.format(new Date(props.item.start))} />
            <Input label={strings.overtime.To} readOnly value={f.format(new Date(props.item.end))} />
            <Input label={strings.overtime.Account} readOnly value={props.item.accountName} />
            <Input label={strings.overtime.Note} readOnly value={props.item.note ? props.item.note : " "} />
            <DialogActionsBar>
                {props.item.approvable && !props.item.approved ? (
                    <>
                        <Button onClick={() => setStatus(false)} style={{ color: "red" }}>
                            {strings.common.Deny}
                        </Button>
                        <Button onClick={() => setStatus(true)} style={{ color: "green" }} autoFocus>
                            {strings.common.Approve}
                        </Button>
                    </>
                ) : (
                    <Button onClick={props.onClose} autoFocus>
                        {strings.common.Close}
                    </Button>
                )}
            </DialogActionsBar>
        </Dialog>
    );
}
