import { createReducer, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { LastPass } from "../../model/terminal/IPOPRT";
import { fetchLastPasses } from "../actions/passesLoader";

export interface ILastPassesState {
    isFetching: boolean;
    passes?: LastPass[];
    error?: SerializedError;
}

export const lastPassReducer = createReducer<ILastPassesState>(
    {
        isFetching: false,
    },
    builder => {
        builder
            .addCase(fetchLastPasses.pending, (state, action) => ({
                ...state,
                isFetching: true,
                passes: undefined,
                error: undefined,
            }))
            .addCase(fetchLastPasses.fulfilled, (state, action: PayloadAction<any>) => ({
                ...state,
                isFetching: false,
                passes: action.payload,
                error: undefined,
            }))
            .addCase(fetchLastPasses.rejected, (state, action) => ({
                ...state,
                isFetching: false,
                passes: undefined,
                error: action.error,
            }));
    }
);
