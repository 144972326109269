import * as React from "react";
import ReactDOM from "react-dom";
import { IStrings } from "../../constants/languageStrings/IStrings";
import { connect } from "react-redux";
import { IRootState } from "../../redux/reducers/root";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import { NavBar } from "../../components/NavBar/NavBar";
import styles from "./PlanState.module.scss";
import { List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { ILanguage } from "../../model/localization/ILanguage";
import { PlanStatesSelfServiceParams } from "../../model/LeftPanelParams";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import NavbarMonthPicker from "../../components/NavbarMonthPicker/NavbarMonthPicker";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { SelectedCenterTooltip } from "../Plan/Department/SelectedCenterTooltip";
import { SelectedCenterButton } from "../Plan/Department/SelectedCenterButton";
import { initialize as initializeCenterPicker } from "../../redux/actions/centerPicker";
import { CenterPickerDialog } from "../../components/CenterPickerDialog";
import { AppDispatch } from "../..";
import { SpinnerBox } from "../../components/Spinner/SpinnerBox";
import { SerializedError } from "@reduxjs/toolkit";
import { ErrorNotification } from "../../components/ErrorNotification";
import { TooMuchPersonNotification } from "../../components/TooMuchPersonNotification";
import { InternalMaxLimitParam } from "../../constants/common";
import { fetchPlanStates, getParamsForPlanStates } from "../../redux/actions/planState";
import { IPlanPerson } from "../../model/IPlanPerson";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import PlanStateDetail from "./PlanStateDetail";
import PermissionGuard from "../../components/PermissionGuard";
import { permissions } from "../../constants/permissions";
import { IBaseMenuItem } from "../../components/NavBar/IBaseMenuItem";

enum EDialog {
    None,
    Detail,
    CenterPicker,
}

enum EPlanState {
    NotPassed,
    Unchecked,
    Checked,
    CheckedHand,

    Waiting,
    Approved,
    ApprovedHand,
    Rejected,
    RejectedHand,
}

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}
interface ActualState {
    state: EPlanState;
    option: string;
    str: string;
}

const param: PlanStatesSelfServiceParams = {
    StartDate: new Date(2020, 4, 1),
    EndDate: new Date(2020, 4, 30),
    SelectedTab: "tab-centers",
    ActivePersonsOnly: true,
    ValidPersonsOnly: true,

    LoadNotPassed: true,
    LoadNotChecked: false,
    LoadChecked: false,

    LoadWaiting: false,
    LoadApproved: false,
    LoadRejected: false,

    HandWritten: false,
};

class PlanState extends React.Component<IProps, IState> {
    private planStates: ActualState[] = [
        {
            state: EPlanState.NotPassed,
            option: this.props.strings.planStates.OnlyNotPassed,
            str: this.props.strings.planStates.NotPassed,
        },
        {
            state: EPlanState.Unchecked,
            option: this.props.strings.planStates.OnlyUnchecked,
            str: this.props.strings.planStates.Unchecked,
        },
        {
            state: EPlanState.Checked,
            option: this.props.strings.planStates.OnlyChecked,
            str: this.props.strings.planStates.Checked,
        },
        {
            state: EPlanState.CheckedHand,
            option: this.props.strings.planStates.OnlyCheckedHand,
            str: this.props.strings.planStates.CheckedHand,
        },

        {
            state: EPlanState.Waiting,
            option: this.props.strings.planStates.OnlyWaiting,
            str: this.props.strings.planStates.Waiting,
        },
        {
            state: EPlanState.Approved,
            option: this.props.strings.planStates.OnlyApprovedPS,
            str: this.props.strings.planStates.ApprovedState,
        },
        {
            state: EPlanState.ApprovedHand,
            option: this.props.strings.planStates.OnlyApprovedPSHand,
            str: this.props.strings.planStates.ApprovedStateHand,
        },
        {
            state: EPlanState.Rejected,
            option: this.props.strings.planStates.OnlyRejected,
            str: this.props.strings.requests.RejectedElectronically,
        },
        {
            state: EPlanState.RejectedHand,
            option: this.props.strings.planStates.OnlyRejectedHand,
            str: this.props.strings.requests.RejectedHand,
        },
    ];

    constructor(props: IProps) {
        super(props);
        const now = new Date();
        this.state = {
            selectedMonth: now,
            visibleDialog: EDialog.None,
            selectedState: this.planStates[0],
            index: 0,
        };
        this.setDateIntoParam(now.getMonth(), now.getFullYear());
    }

    setDateIntoParam(actMonthNumber: number, actYear: number) {
        param.StartDate?.setMonth(actMonthNumber);
        param.StartDate?.setFullYear(actYear);
        param.EndDate = new Date(actYear, actMonthNumber + 1, 1, 0, 0, 0);
    }
    async componentDidMount() {
        this.props
            .dispatch(initializeCenterPicker())
            .then(async () => this.props.onLoadParams && this.props.onLoadParams())
            .then(async () => this.props.onLoadStates && this.props.onLoadStates(param));
    }

    public handleMonthChange = (month: Date) => {
        this.setDateIntoParam(month.getMonth(), month.getFullYear());
        this.setState({ selectedMonth: month });
        this.props.onLoadStates && this.props.onLoadStates(param);
    };

    private SetAllOptionsFalse() {
        param.LoadApproved =
            param.LoadChecked =
            param.LoadNotChecked =
            param.LoadRejected =
            param.LoadWaiting =
            param.LoadNotPassed =
            param.HandWritten =
                false;
        this.setState({ selectedState: this.planStates[0] });
    }

    private handleFilterChange = (event: DropDownListChangeEvent) => {
        this.SetAllOptionsFalse();
        switch (event.value) {
            case this.planStates[0]:
                param.LoadNotPassed = true;
                this.setState({ selectedState: this.planStates[0] });
                break;
            case this.planStates[1]:
                param.LoadNotChecked = true;
                this.setState({ selectedState: this.planStates[1] });
                break;
            case this.planStates[2]:
                param.LoadChecked = true;
                this.setState({ selectedState: this.planStates[2] });
                break;
            case this.planStates[3]:
                param.LoadChecked = param.HandWritten = true;
                this.setState({ selectedState: this.planStates[3] });
                break;
            case this.planStates[4]:
                param.LoadWaiting = true;
                this.setState({ selectedState: this.planStates[4] });
                break;
            case this.planStates[5]:
                param.LoadApproved = true;
                this.setState({ selectedState: this.planStates[5] });
                break;
            case this.planStates[6]:
                param.LoadApproved = param.HandWritten = true;
                this.setState({ selectedState: this.planStates[6] });
                break;
            case this.planStates[7]:
                param.LoadRejected = true;
                this.setState({ selectedState: this.planStates[7] });
                break;
            case this.planStates[8]:
                param.LoadRejected = param.HandWritten = true;
                this.setState({ selectedState: this.planStates[8] });
                break;
        }
        this.props.onLoadStates && this.props.onLoadStates(param);
    };

    private GetState(row: IPlanPerson): string {
        return this.state.selectedState.str;
    }

    private getMailToAddress(row: IPlanPerson): string {
        const mailTo: string = "mailto:";
        return mailTo + row.Email;
    }

    private handleMailClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, row: IPlanPerson): void {
        e.preventDefault();
        window.location.href = this.getMailToAddress(row);
    }

    private handleSingleNumberClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, row: IPlanPerson): void {
        e.preventDefault();
        window.location.href = "tel:" + row.Phone[0];
    }

    private handleDialogClose = () =>
        this.setState({
            visibleDialog: EDialog.None,
        });

    private handleSelectedCenterButtonClick = () =>
        this.setState({
            visibleDialog: EDialog.CenterPicker,
        });

    private handleCenterPickerDialogConfirm = () => {
        this.handleDialogClose();
        this.props.onLoadStates && this.props.onLoadStates(param);
    };

    private getPeriod = (): number => {
        if (this.props.param === 0 || this.props.param >= 30) {
            return 0;
        } else if (this.props.param > 0 && this.props.param <= 7) {
            return 4;
        } else if (this.props.param >= 14 && this.props.param <= 20) {
            return 2;
        } else return 0;
    };

    private getHeaderTitle = (period: number, index: number): string => {
        let month: string = (this.state.selectedMonth.getMonth() + 1).toString();
        let lastDayNumber: string = this.state.selectedMonth.lastDayOfMonth().getDate().toString();
        if (period === 2) {
            if (index === 0) {
                return "1. - 15." + month;
            } else if (index === 1) {
                return "16. - " + lastDayNumber + "." + month;
            }
        }
        if (period === 4) {
            if (index === 0) {
                return "1. - 7." + month;
            } else if (index === 1) {
                return "8. - 14." + month;
            } else if (index === 2) {
                return "15. - 21." + month;
            } else if (index === 3) {
                return "22. - " + lastDayNumber + "." + month;
            }
        }
        return "";
    };

    private getTabsHeaders = () => {
        let tabs = [];
        for (let i: number = 0; i < this.getPeriod(); i++) {
            tabs.push(<Tab label={this.getHeaderTitle(this.getPeriod(), i)} key={i}></Tab>);
        }
        return tabs;
    };

    private getTabsBodies = (rows: IPlanPerson[]) => {
        let panels = [];
        for (let i: number = 0; i < this.getPeriod(); i++) {
            panels.push(
                <TabPanel value={this.state.index} index={i} dir={"x"} key={i}>
                    {this.getList(rows, i)}
                </TabPanel>
            );
        }
        return (
            <SwipeableViews
                axis={"x"} //'x-reverse'
                className={styles.tabBodies}
                index={this.state.index}
                onChangeIndex={this.handleChangeIndex}
            >
                {panels}
            </SwipeableViews>
        );
    };

    private openDetailDialog(row: IPlanPerson) {
        if (this.props.data !== undefined) {
            let dataSource = this.props.data.find(
                ipo =>
                    ipo.Ixsref === row.Ixsref &&
                    ipo.DateFrom === row.DateFrom &&
                    ipo.DateTo === row.DateTo &&
                    ipo.Type === row.Type
            );
            this.setState({ detailData: dataSource! });
            this.setState({ visibleDialog: EDialog.Detail });
        }
    }

    private handleChangeIndex = (index: number) => {
        this.setState({ index: index });
    };

    private handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ index: newValue });
    };

    private getList = (rows: IPlanPerson[], periodNumber: number) => {
        let period: number = this.getPeriod();
        let result: IPlanPerson[] = [];
        if (period === 2) {
            if (periodNumber === 0)
                result = rows.filter(
                    ipo =>
                        new Date(ipo.DateFrom!).getDate() > 0 &&
                        new Date(ipo.DateFrom!).getDate() <= 15 &&
                        (ipo.DateTo == null || new Date(ipo.DateTo!).getDate() <= 31)
                );
            else if (periodNumber === 1)
                result = rows.filter(
                    ipo =>
                        new Date(ipo.DateFrom!).getDate() > 0 &&
                        (ipo.DateTo == null || new Date(ipo.DateTo!).getDate() > 15)
                );
        } else if (period === 4) {
            if (periodNumber === 0)
                result = rows.filter(
                    ipo =>
                        new Date(ipo.DateFrom!).getDate() > 0 &&
                        new Date(ipo.DateFrom!).getDate() <= 7 &&
                        (ipo.DateTo == null || new Date(ipo.DateTo!).getDate() <= 7)
                );
            else if (periodNumber === 1)
                result = rows.filter(
                    ipo =>
                        new Date(ipo.DateFrom!).getDate() > 0 &&
                        new Date(ipo.DateFrom!).getDate() <= 14 &&
                        (ipo.DateTo == null || new Date(ipo.DateTo!).getDate() <= 14)
                );
            else if (periodNumber === 2)
                result = rows.filter(
                    ipo =>
                        new Date(ipo.DateFrom!).getDate() > 0 &&
                        new Date(ipo.DateFrom!).getDate() <= 21 &&
                        (ipo.DateTo == null || new Date(ipo.DateTo!).getDate() <= 21)
                );
            else if (periodNumber === 3)
                result = rows.filter(
                    ipo =>
                        new Date(ipo.DateFrom!).getDate() > 0 &&
                        new Date(ipo.DateFrom!).getDate() <= 31 &&
                        (ipo.DateTo == null || new Date(ipo.DateTo!).getDate() <= 31)
                );
        } else result = rows;
        return (
            <>
                <List>
                    {result.length === 0 && (
                        <label className={styles.noData}>{this.props.strings.common.NoItems}</label>
                    )}
                    {/* řádky */}
                    {result.map((row: IPlanPerson, key: any) => (
                        <ListItem key={key} divider dense button onClick={() => this.openDetailDialog(row)}>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        {row.OcFullName}
                                        <br></br>
                                        {"Datum od " + new Date(row.DateFrom!).toLocaleDateString(this.props.lang.code)}
                                        <br></br>
                                        {"Datum do " +
                                            (row.DateTo != null
                                                ? new Date(row.DateTo).toLocaleDateString(this.props.lang.code)
                                                : "")}
                                    </React.Fragment>
                                }
                                secondary={<React.Fragment>{this.GetState(row)}</React.Fragment>}
                            />
                            <ListItemSecondaryAction>
                                <PermissionGuard permission={permissions.presence.showContacts}>
                                    <div>
                                        {row.Email !== "" && row.Email != null && (
                                            <Button
                                                iconClass="fa fa-envelope"
                                                onClick={e => this.handleMailClick(e, row)}
                                            ></Button>
                                        )}
                                        {row.Phone.length > 1 && (
                                            <DropDownButton
                                                iconClass="fa fa-phone"
                                                items={row.Phone.map((number: string, key: any) => (
                                                    <a key={key} href={"tel:" + number} data-rel="external">
                                                        <span>{number}</span>
                                                    </a>
                                                ))}
                                            ></DropDownButton>
                                        )}
                                        {row.Phone.length === 1 && (
                                            <Button
                                                iconClass="fa fa-phone"
                                                onClick={e => this.handleSingleNumberClick(e, row)}
                                            ></Button>
                                        )}
                                    </div>
                                </PermissionGuard>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </>
        );
    };

    public render() {
        let rows: IPlanPerson[] = this.props.data || [];
        return (
            <PageLayout
                header={
                    <NavBar
                        canNavigateRoot
                        label={this.props.strings.planStates.TitlePS}
                        menu={{
                            items: [] as IBaseMenuItem[],
                            onItemClick: undefined,
                        }}
                    >
                        <NavbarMonthPicker selectedMonth={this.state.selectedMonth} onPick={this.handleMonthChange} />
                    </NavBar>
                }
            >
                <div className={styles.params}>
                    <SelectedCenterTooltip arrow>
                        <SelectedCenterButton
                            className={styles.centerButton}
                            primary
                            onClick={this.handleSelectedCenterButtonClick}
                        />
                    </SelectedCenterTooltip>
                    <DropDownList
                        data={this.planStates}
                        textField={"option"}
                        onChange={this.handleFilterChange}
                        popupSettings={{
                            height: "auto",
                        }}
                        defaultValue={this.planStates[0]}
                    ></DropDownList>
                </div>
                {this.props.isFetching && <SpinnerBox></SpinnerBox>}
                {this.props.error && <ErrorNotification error={this.props.error} />}
                {this.props.inlineCount > InternalMaxLimitParam && (
                    <TooMuchPersonNotification></TooMuchPersonNotification>
                )}
                {!this.props.isFetching && !this.props.error && this.props.inlineCount <= InternalMaxLimitParam && (
                    <>
                        {this.getPeriod() > 0 ? (
                            <>
                                {this.getTabsBodies(rows)}
                                <Tabs
                                    className={styles.tabs}
                                    value={this.state.index}
                                    onChange={this.handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    aria-label="full width tabs example"
                                >
                                    {this.getTabsHeaders()}
                                </Tabs>
                            </>
                        ) : (
                            <div className={styles.withoutTabs}>{this.getList(rows, 0)}</div>
                        )}
                    </>
                )}
                {this.state.visibleDialog === EDialog.Detail && (
                    <PlanStateDetail onClose={this.handleDialogClose} detailData={this.state.detailData!} />
                )}
                {ReactDOM.createPortal(
                    <React.Fragment>
                        {this.state.visibleDialog === EDialog.CenterPicker && (
                            <CenterPickerDialog
                                onClose={this.handleDialogClose}
                                onConfirm={this.handleCenterPickerDialogConfirm}
                            />
                        )}
                    </React.Fragment>,
                    document.body
                )}
            </PageLayout>
        );
    }
}

interface IOwnProps {
    data: IPlanPerson[];
    inlineCount: number;
    isFetching: boolean;
    error: SerializedError;
    param: number;
}

interface IStateProps {
    strings: IStrings;
    lang: ILanguage;
}

interface IState {
    selectedMonth: Date;
    visibleDialog: EDialog;
    selectedState: ActualState;
    selectedTab?: number;
    index: number;
    detailData?: IPlanPerson;
}

interface IDispatchProps {
    dispatch: AppDispatch;
    onLoadStates: (e: PlanStatesSelfServiceParams) => void;
    onLoadParams: () => void;
}

type IProps = IOwnProps & IStateProps & IDispatchProps & IState;

export default connect<IStateProps, IDispatchProps, IOwnProps, IRootState>(
    state => ({
        strings: state.localization.strings,
        lang: state.localization.language,
        data: state.session.planStates.planWithState,
        inlineCount: state.session.planStates.inlineCount,
        param: state.session.planStatesParams.recommendedDays,
        isFetching: state.session.planStates.isFetching && state.session.planStatesParams.isFetching,
        error: state.session.planStates.error && state.session.planStatesParams.error,
    }),
    (dispatch: AppDispatch) => ({
        dispatch: dispatch,
        onLoadStates: (e: PlanStatesSelfServiceParams) => dispatch(fetchPlanStates(e)),
        onLoadParams: () => dispatch(getParamsForPlanStates()),
    })
)(PlanState);

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}
