import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseError } from "../../helpers/handleResponseError";
import { QueryParam } from "../../model/CommonQueryParams";
import { IporefForWidget } from "../../model/IporefForWidget";
import { IRootState } from "../reducers/root";

const DOMAIN = "RequestsForApproval";

export const fetchRequestsForApprovalDatasource = createAsyncThunk<
    {},
    [string, boolean, boolean],
    { state: IRootState }
>(`${DOMAIN}/fetchRequestsForApprovalDatasource`, async (arg, api) => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
    let reqBody = {
        IncludeChildren: true,
        SelectedIxsStr: "0",
        SelectedTab: "tab-centers",
        Sort: arg[0],
        BoolValue: arg[1],
        CurrentAgenda: arg[2] ? "requests" : "unavailabilities",
    };
    const requestOptions: RequestInit = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(reqBody),
    };

    const response = await fetch(
        api.getState().config.config?.endpoint + "/breeze/IpoData/GetWflApprovingTabForSelfService",
        requestOptions
    );

    await handleResponseError(response);

    return await response.json();
});

export const fetchIporefsForWFL = createAsyncThunk<IporefForWidget[], { isRequest: boolean }, { state: IRootState }>(
    `${DOMAIN}/fetchIporefsForWFL`,
    async (arg, api) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
        const requestOptions: RequestInit = {
            method: "GET",
            headers: headers,
        };

        const response = await fetch(
            api.getState().config.config?.endpoint +
                (arg.isRequest
                    ? "/breeze/IpoData/SelfService_GetListOfPersonWFL"
                    : "/breeze/IpoData/SelfService_GetListOfPersonPlnunavaWFL"),
            requestOptions
        );

        const data = (await response.json()) as unknown[];

        if (response.status === 200) {
            return data.map(
                (iporef: any) =>
                    ({
                        OcFullName: iporef.OcFullName,
                        IXS_REF: iporef.IXS_REF,
                    } as IporefForWidget)
            );
        } else return api.rejectWithValue(data);
    }
);

export const fetchIporefsForWFLWithFilter = createAsyncThunk<
    {},
    { filter: string; isRequest: boolean },
    { state: IRootState }
>(`${DOMAIN}/fetchIporefsForWFLWithFilter`, async (arg, api) => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
    const requestOptions: RequestInit = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            Value: arg.filter,
        } as QueryParam<string>),
    };

    const response = await fetch(
        api.getState().config.config?.endpoint +
            (arg.isRequest
                ? "/breeze/IpoData/SelfService_GetListOfPersonWFLFilter"
                : "/breeze/IpoData/SelfService_GetListOfPersonPlnunavaWFLFilter"),
        requestOptions
    );

    const data = await response.json();

    if (response.status === 200) {
        return data.map(
            (iporef: any) =>
                ({
                    OcFullName: iporef.OcFullName,
                    IXS_REF: iporef.IXS_REF,
                } as IporefForWidget)
        );
    } else return api.rejectWithValue(data);
});
