import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRootState } from "../reducers/root";
import { QueryParam2, QueryParam3 } from "../../model/CommonQueryParams";

const DOMAIN = "Requests";

export const wflChangeState = createAsyncThunk<{}, QueryParam3<number, number, string>, { state: IRootState }>(
    `${DOMAIN}/wflChangeState`,
    async (arg, api) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
        let reqBody: string = JSON.stringify(arg);
        const requestOptions: RequestInit = {
            method: "POST",
            headers: headers,
            body: reqBody,
        };

        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/SetWflRequestStatusSelfService",
            requestOptions
        );

        const data = await response.json();

        if (response.status === 200) return data;
        else return api.rejectWithValue(data);
    }
);

export const wflChangeStateMultiple = createAsyncThunk<{}, QueryParam2<number[], number>, { state: IRootState }>(
    `${DOMAIN}/wflChangeStateMultiple`,
    async (arg, api) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${api.getState().session.user?.token?.access_token}`);
        let reqBody: string = JSON.stringify(arg);
        const requestOptions: RequestInit = {
            method: "POST",
            headers: headers,
            body: reqBody,
        };

        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/SetWflRequestStatusMultiple",
            requestOptions
        );

        if (response.status === 200) return;
        else return api.rejectWithValue(response.status);
    }
);
