import React, { PropsWithChildren } from "react";
import { ErrorNotification } from "../../components/ErrorNotification";
import { SpinnerBox } from "../../components/Spinner/SpinnerBox";
import { useStrings } from "../../hooks/useStrings";
import { useAppSelector } from "../../hooks/useAppSelector";

export function ServerChecking(props: PropsWithChildren<{}>) {
    const strings = useStrings();
    const isChecking = useAppSelector(s => s.app.isFetching);
    const error = useAppSelector(s => s.app.error);

    if (isChecking) {
        return <SpinnerBox>{strings.app.ServerChecking + " ..."}</SpinnerBox>;
    }

    if (error) {
        return <ErrorNotification error={error} />;
    }

    return <>{props.children}</>;
}

export default ServerChecking;
