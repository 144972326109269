import { PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { ThunkMiddleware } from "redux-thunk";
import { errorsListSlice } from "../slices/errorsList";
import { reportError } from "../actions/errors";
import { IRootState } from "../reducers/root";

type A = PayloadAction<unknown, string, unknown, SerializedError>;

export const rejectedAsyncActionsReporter: ThunkMiddleware<IRootState> = api => next => (action: A) => {
    const result = next(action);

    if (action.type.endsWith("/rejected")) {
        if (api.getState().config.config.debug) {
            api.dispatch(errorsListSlice.actions.add(action));
        }
        api.dispatch(reportError(action.error));
    }

    return result;
};
