export type ColorParts = {
    red: number;
    green: number;
    blue: number;
    alpha: number;
};

export class Color {
    private _parts: ColorParts;

    public get parts(): ColorParts {
        return { ...this._parts };
    }

    constructor(parts: ColorParts) {
        this._parts = { ...parts };
    }

    public static random(): Color {
        return new Color({
            blue: Math.floor(Math.random() * 255),
            green: Math.floor(Math.random() * 255),
            red: Math.floor(Math.random() * 255),
            alpha: 1,
        });
    }

    /** Parse ANeT encoded RGB color into Color type. */
    public static fromPbColor(pbColor: number | null): Color {
        if (pbColor == null || pbColor <= 0) {
            return new Color({ red: 0, green: 0, blue: 0, alpha: 0 });
        }

        const red = pbColor % 256;
        const green = Math.floor((pbColor - red) % 65536) / 256;
        const blue = Math.floor((pbColor - red - green) / 65536);

        return new Color({ red, green, blue, alpha: 1 });
    }

    /** Encode Color type into CSS rgba() string. */
    public toCSSRGBA(): string {
        return `rgba(${this._parts.red}, ${this._parts.green}, ${this._parts.blue}, ${this._parts.alpha})`;
    }
}
