import React, { useState, FormEvent, useEffect } from "react";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import { NavBar } from "../../components/NavBar/NavBar";
import { LogoutButton } from "../../components/LogoutButton";
import { fetchToken } from "../../redux/actions/user";
import { changePassword, getOldPaswordVisible, getPasswordParam } from "../../redux/actions/passwordChange";
import { useStrings } from "../../hooks/useStrings";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useHistory } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import classes from "./PasswordChange.module.scss";
import { ErrorNotificationText } from "../../components/ErrorNotification";

function PasswordChange(props: { initial: boolean }) {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordVerification, setNewPasswordVerification] = useState("");
    const [inputError, setInputError] = useState("");
    const error = useAppSelector(s => s.session.passwordChange.error);
    const strict = useAppSelector(s => s.session.passwordParam.strict);
    const visible = useAppSelector(s => s.session.passwordOldVisible.visible);
    const changeClaim = useAppSelector(s => s.session.user.token?.changePassword === "True");
    const username = useAppSelector(s => s.session.user.token?.userName);
    const history = useHistory();

    function contains3SameCharactersInRow(pswd: string) {
        /**
         * regex for 10 same characters in a row:   /(.)\1{9,}/
         * Here the \1 is called a backreference. It references what is captured by the dot  . between the brackets (.) and then the {9,} asks for nine or more of the same character.
         * Thus this matches ten or more of any single character.
         */
        let regex = /(.)\1{2,}/;
        return regex.test(pswd);
    }

    function containsUprisingOrDownrisingSequenceOf3(pswd: string) {
        let charSequence =
            "abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz";
        let numSequence = "012|123|345|456|567|678|789";
        let regex3CharacterSequence = new RegExp(`(?:${charSequence}|${getReversed(charSequence)})`, "i");
        let regex3NumberSequence = new RegExp(`(?:${numSequence}|${getReversed(numSequence)})`);
        return regex3CharacterSequence.test(pswd) || regex3NumberSequence.test(pswd);
    }

    function containsUserName(pswd: string, usrName: string) {
        return pswd.toLowerCase().indexOf(usrName.toLowerCase()) > -1;
    }

    function getReversed(sequence: string) {
        return sequence
            .split("|")
            .reverse()
            .map(xyz => xyz.split("").reverse().join(""))
            .join("|");
    }

    function resolveErrorMsg(): string {
        if (inputError) {
            return inputError;
        } else if (error?.message === "PasswordInHistory") {
            return strings.passwordChange.error_password_in_history;
        } else if (error?.message === "InvalidOldPassword") {
            return strings.passwordChange.error_invalid_old_password;
        }
        return error?.message ?? "?";
    }

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getPasswordParam());
        dispatch(getOldPaswordVisible());
    }, [dispatch]);

    function handleFormSubmit(e: FormEvent) {
        e.preventDefault();

        if (newPassword !== newPasswordVerification) {
            setInputError(strings.passwordChange.error_not_same);
        } else if (strict && containsUserName(newPassword, username ?? "")) {
            setInputError(strings.passwordChange.error_contains_username);
        } else if (strict && contains3SameCharactersInRow(newPassword)) {
            setInputError(strings.passwordChange.error_contains_3_chars);
        } else if (strict && containsUprisingOrDownrisingSequenceOf3(newPassword)) {
            setInputError(strings.passwordChange.error_contains_sequence);
        } else {
            setInputError("");
            dispatch(
                changePassword({
                    username: username ?? "",
                    oldPassword: oldPassword,
                    newPassword: newPassword,
                })
            ).then(res => {
                if (res.type === "passwordChange/fulfilled") {
                    dispatch(
                        fetchToken({
                            credentials: {
                                username: username ?? "",
                                password: newPassword,
                            },
                        })
                    );
                    if (!props.initial) {
                        history.push("/dashboard");
                    }
                }
            });
        }
    }
    const strings = useStrings();
    return (
        <PageLayout
            header={
                <NavBar canNavigateRoot={!props.initial} label={strings.passwordChange.title}>
                    {props.initial && <LogoutButton />}
                </NavBar>
            }
        >
            <div className={classes.layout}>
                <form onSubmit={handleFormSubmit}>
                    <fieldset className={classes.fieldset}>
                        <Input name="username" type="hidden" autoComplete="username" />
                        {visible && !changeClaim && (
                            <Input
                                name="oldPassword"
                                type="password"
                                label={strings.passwordChange.oldPassword}
                                onChange={event => setOldPassword(event.value)}
                                value={oldPassword}
                                required={true}
                                autoComplete="current-password"
                            />
                        )}
                        <Input
                            name="newPasswordsword"
                            type="password"
                            label={strings.passwordChange.newPassword}
                            onChange={event => setNewPassword(event.value)}
                            value={newPassword}
                            required={true}
                            autoComplete="new-password"
                        />
                        <Input
                            name="newPasswordVerification"
                            type="password"
                            label={strings.passwordChange.newPasswordVerification}
                            onChange={event => setNewPasswordVerification(event.value)}
                            value={newPasswordVerification}
                            required={true}
                            autoComplete="new-password"
                        />
                    </fieldset>
                    <Button type="submit" className="w-100" primary={true}>
                        {strings.common.continue}
                    </Button>
                </form>
                {(error || inputError) && <ErrorNotificationText text={resolveErrorMsg()} />}
            </div>
        </PageLayout>
    );
}

export default PasswordChange;
