import React from "react";
import styles from "./SidebarCell.module.scss";
import { IGroup } from "../types";
import classNames from "classnames";

interface IProps<G extends IGroup> {
    group: G;
    rowIndex: number;
    className?: string;
    contentRenderer?: (props: { group: G }) => React.ReactNode;
}

export const SidebarCell = <G extends IGroup>(props: IProps<G>) => (
    <div
        className={classNames(styles.cell, props.className)}
        style={{
            gridRowStart: props.rowIndex,
            gridRowEnd: props.rowIndex,
            gridColumnStart: 1,
            gridColumnEnd: 1,
        }}
    >
        {props.contentRenderer ? props.contentRenderer({ group: props.group }) : <span>{props.group.title}</span>}
    </div>
);
