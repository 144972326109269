import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ErrorsVisibilityState = boolean;

export const errorsVisibilitySlice = createSlice({
    name: "errors/visibility",
    initialState: false as ErrorsVisibilityState,
    reducers: {
        set: (state, action: PayloadAction<boolean>) => action.payload,
    },
});
