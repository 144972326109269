import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRootState } from "../../reducers/root";
import { handleResponseError } from "../../../helpers/handleResponseError";
import { APP_VERSION } from "../../../constants/common";

export const confirmSignRequests = createAsyncThunk<void, number[], { state: IRootState }>(
    "plan/my/sign/requests/confirm",
    async (arg, api) => {
        const requestOptions: RequestInit = {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${api.getState().session.user.token?.access_token}`,
            },
            body: JSON.stringify({
                Item1: arg,
                Item2: APP_VERSION,
            }),
            signal: api.signal,
        };

        const response = await fetch(
            api.getState().config.config?.endpoint + "/controllers/SelfService/ConfirmPlanSignRequests",
            requestOptions
        );

        await handleResponseError(response);
    }
);
