import { createReducer, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { PLNUNAVA } from "../../model/PLNUNAVA";
import { fetchUnavalabilities, deleteUnavailability, createUnavailability } from "../actions/unavailability";

export interface IUnavailabilityGet {
    isFetching?: boolean;
    datasource?: PLNUNAVA[];
    error?: SerializedError;
}

export interface IUnavailabilityCreateOrRemove {
    isFetching?: boolean;
    succesful?: boolean;
    error?: SerializedError;
}

export const unavalabilityFetchReducer = createReducer<IUnavailabilityGet>(
    {
        isFetching: false,
    },
    builder => {
        return builder
            .addCase(fetchUnavalabilities.pending.type, (state, action) => ({
                ...state,
                isFetching: true,
                datasource: undefined,
                error: undefined,
            }))
            .addCase(fetchUnavalabilities.fulfilled.type, (state, action: PayloadAction<any>) => ({
                ...state,
                isFetching: false,
                datasource: action.payload,
                error: undefined,
            }))
            .addCase(fetchUnavalabilities.rejected, (state, action) => ({
                ...state,
                isFetching: false,
                datasource: undefined,
                error: action.error,
            }));
    }
);

export const unavalabilityCreateReducer = createReducer<IUnavailabilityCreateOrRemove>(
    {
        isFetching: false,
    },
    builder => {
        return builder
            .addCase(createUnavailability.pending.type, (state, action) => ({
                ...state,
                isFetching: true,
            }))
            .addCase(createUnavailability.fulfilled.type, (state, action: PayloadAction<any>) => ({
                ...state,
                isFetching: false,
                succesful: action.payload,
            }))
            .addCase(createUnavailability.rejected, (state, action: PayloadAction<any>) => ({
                ...state,
                isFetching: false,
                error: action.payload,
            }));
    }
);

export const unavalabilityDeleteReducer = createReducer<IUnavailabilityCreateOrRemove>(
    {
        isFetching: false,
    },
    builder => {
        return builder
            .addCase(deleteUnavailability.pending.type, (state, action) => ({
                ...state,
                isFetching: true,
            }))
            .addCase(deleteUnavailability.fulfilled.type, (state, action: PayloadAction<any>) => ({
                ...state,
                isFetching: false,
                succesful: action.payload,
            }))
            .addCase(deleteUnavailability.rejected, (state, action: PayloadAction<any>) => ({
                ...state,
                isFetching: false,
                error: action.payload,
            }));
    }
);
