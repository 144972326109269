import React from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useStrings } from "../../hooks/useStrings";
import { ErrorNotificationText } from "../../components/ErrorNotification";

export function AuthErrorRenderer() {
    const error = useAppSelector(s => s.session.user.error);
    const multiTenant = useAppSelector(s => s.app.multiTenant);
    const strings = useStrings();

    function resolveErrorMsg(): string {
        const code = error?.code;

        if (code) {
            let tmpl: string = "Chyba";
            if (code === "login_error_invalid_grant")
                tmpl = multiTenant
                    ? strings.login.login_error_invalid_grant_multitenant
                    : strings.login.login_error_invalid_grant;
            else if (code === "login_error_locked_account") tmpl = strings.login.login_error_locked_account;
            else if (code === "login_error_app_usage_not_allowed" || code === "login_error_invalid_permissions")
                tmpl = strings.login.login_error_app_usage_not_allowed;
            else if (code === "login_error_user_not_registered") tmpl = strings.login.login_error_user_not_registered;
            else if (code === "login_error_user_expired") tmpl = strings.login.login_error_user_expired;
            else if (code === "login_error_invalid_license") tmpl = strings.app.Msg_AppDisabledByLicense;

            return tmpl?.replace("{0}", error?.message ?? "?") ?? code;
        }

        return error?.message ?? "?";
    }

    return error ? <ErrorNotificationText text={resolveErrorMsg()} /> : <></>;
}
