import React from "react";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import { NavBar } from "../../components/NavBar/NavBar";
import { IStrings } from "../../constants/languageStrings/IStrings";
import { connect } from "react-redux";
import { IRootState } from "../../redux/reducers/root";
import { LeftPanelParams } from "../../model/LeftPanelParams";
import {
    fetchWorksheetDataForMonth,
    fetchWorksheetDataForNextMonth,
    fetchWorksheetDataForPreviousMonth,
    getParamsForWorksheet,
    resetWorksheetData,
    scrollWorksheetNext,
    scrollWorksheetPrevious,
    signWorksheet,
} from "../../redux/actions/worksheet";
import { Grid, GridColumn as Column, GridCellProps } from "@progress/kendo-react-grid";
import { PracListResultMobile } from "../../model/WorksheetModels";
import styles from "./Worksheet.module.scss";
import { Button } from "@progress/kendo-react-buttons";
import { ILanguage } from "../../model/localization/ILanguage";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import classNames from "classnames";
import NavbarMonthPicker from "../../components/NavbarMonthPicker/NavbarMonthPicker";
import { IParameter } from "../../model/IParameter";
import { DialogActionsBar, Dialog } from "@progress/kendo-react-dialogs";
import { QueryParam2 } from "../../model/CommonQueryParams";
import { Slide } from "@progress/kendo-react-animation";
import czstampSource from "../../assets/images/cspic_RazitkoPLKulate.png";
import skstampSource from "../../assets/images/skpic_RazitkoPLKulate.png";
import enstampSource from "../../assets/images/enpic_RazitkoPLKulate.png";

import csMessages from "../../assets/cs.json";
import { loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";
import { NotificationGroup, Notification } from "@progress/kendo-react-notification";
import { IBaseMenuItem } from "../../components/NavBar/IBaseMenuItem";
import { SpinnerBox } from "../../components/Spinner/SpinnerBox";
import { SerializedError } from "@reduxjs/toolkit";
import { ErrorNotification } from "../../components/ErrorNotification";
import { permissions } from "../../constants/permissions";
import { RouteComponentProps } from "react-router-dom";
import { selectIxsRef } from "../../redux/selectors/selectIxsRef";
import AttachmentsDialog from "./AttachmentsDialog";
import SwipeableViews from "react-swipeable-views";
import { SlideRenderProps, virtualize } from "react-swipeable-views-utils";

loadMessages(csMessages, "cs");
interface IStateProps {
    strings: IStrings;
    language: ILanguage;
    dataSource?: PracListResultMobile;
    dataSourcePrevious?: PracListResultMobile;
    dataSourceNext?: PracListResultMobile;
    error?: SerializedError;
    params?: IParameter[];
    paramsError?: SerializedError;
    paramsFetching?: boolean;
    ixsref?: string;
    isFetching?: boolean;
    isFetchingPrevious?: boolean;
    isFetchingNext?: boolean;
    isSinging?: boolean;
    isSigningAllowed?: boolean;
    signRequest?: Date;
    attachmentsAllowed: boolean;
}

interface IState {
    index: number;
    selectedMonth: Date;
    params: {
        zobrHodDec: boolean;
    };
    computeTo: number;
    warningnote: boolean;
    dialog: {
        isVisible: boolean;
    };
    attachmentsDialogVisible: boolean;
}

interface IDispatchProps {
    onFetchData: (e: LeftPanelParams) => void;
    onFetchDataPrevious: (e: LeftPanelParams, computeTo: number) => void;
    onFetchDataNext: (e: LeftPanelParams, computeTo: number) => void;
    onFetchParams: () => Promise<void>;
    onPostSigning: (e: QueryParam2<string, Date>) => Promise<void>;
    onScrollWorksheetPrevious: () => void;
    onScrollWorksheetNext: () => void;
    onResetWorksheetData: () => void;
}

interface WorksheetGridData {
    day: string;
    shift: string;
    note: string;
    passes: string;
    worked: string;
    absence: string;
    balance: string;
}

interface WorksheetGridDatas extends Array<WorksheetGridData> {}

const param: LeftPanelParams = {
    StartDate: new Date(2020, 4, 1),
    EndDate: new Date(2020, 4, 30),
};

const position = {
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
};

function convertToBoolean(input: string): boolean {
    return JSON.parse(input);
}

type IProps = IStateProps & IDispatchProps & ComponentProps;

interface RouteInfo {
    date: string;
}
interface ComponentProps extends RouteComponentProps<RouteInfo> {}

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

class Worksheet extends React.Component<IProps, IState> {
    private timer?: NodeJS.Timer;

    constructor(props: IProps) {
        super(props);
        const stringDate: string = props.match?.params.date;
        const paramDate: Date | null =
            stringDate != null
                ? new Date(Number(stringDate.substring(0, 4)), Number(stringDate.substring(4, 6)) - 1, 1)
                : null;
        const now = new Date();
        this.state = {
            // isNaN beacause of invalid date input
            selectedMonth: paramDate != null && !isNaN(paramDate.getTime()) ? paramDate : now,
            params: {
                zobrHodDec: false,
            },
            computeTo: 0,
            warningnote: false,
            dialog: {
                isVisible: false,
            },
            attachmentsDialogVisible: false,
            index: 0,
        };
        this.setDateIntoParam(this.state.selectedMonth.getMonth(), this.state.selectedMonth.getFullYear());
        param.Lang = this.props.language.code;
    }

    private checkIsActMonth(actMonthNumber: number, actYear: number): boolean {
        const today: Date = new Date();
        return actMonthNumber === today.getMonth() && actYear === today.getFullYear();
    }

    private setDateIntoParam(actMonthNumber: number, actYear: number) {
        param.StartDate?.setMonth(actMonthNumber);
        param.StartDate?.setFullYear(actYear);
        if (this.state.computeTo && this.checkIsActMonth(actMonthNumber, actYear)) this.setDateForCountTo();
        else param.EndDate = new Date(actYear, actMonthNumber + 1, 1, 0, 0, 0);
    }

    private handleComputeTo = (value: boolean) => {
        if (value) {
            this.setDateForCountTo();
        } else {
            param.EndDate = new Date(
                this.state.selectedMonth.getFullYear(),
                this.state.selectedMonth.getMonth() + 1,
                1,
                0,
                0,
                0
            );
        }
        this.setState({ computeTo: value ? 2 : 1 });
        value ? localStorage.setItem("PLIST_CBPOCDO", "2") : localStorage.setItem("PLIST_CBPOCDO", "1");
        this.props.onResetWorksheetData();
        this.props.onFetchData(param);
    };

    setDateForCountTo = () => {
        let today: Date = new Date();
        param.EndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    };

    private handleMenuItemClick = (item: IBaseMenuItem) => {
        switch (item.id) {
            case 1:
                this.handleComputeTo(this.state.computeTo !== 2);
                break;
            default:
                item.id && console.warn("Unhandled menu item: ", item);
        }
    };

    private createMenuItems = (strings: IStrings) => {
        let currentDay: Date = new Date();
        let checkboxVisibleDueCurrentmonth: boolean =
            currentDay.getMonth() === this.state.selectedMonth.getMonth() &&
            currentDay.getFullYear() === this.state.selectedMonth.getFullYear();
        let checkboxVisibleDueNotApproved: boolean = this.props.dataSource?.Potvrdil === null;
        const computeTo = {
            id: 1,
            text: (
                <Checkbox
                    key={0}
                    value={this.state.computeTo === 1 ? false : true}
                    label={strings.worksheet.ComputeTo + ": " + new Date().toLocaleDateString(this.props.language.code)}
                    onChange={event => this.handleComputeTo(event.value)}
                ></Checkbox>
            ),
        };
        let pole: any = [
            {
                id: 0,
                text: strings.common.Help,
            },
        ];
        if (checkboxVisibleDueCurrentmonth && checkboxVisibleDueNotApproved) {
            pole.unshift(computeTo);
        }
        return pole;
    };

    controlFordayParam(): void {
        let today: Date = new Date();
        let todayWithoutTime: Date = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        let actMonth: number = today.getMonth();
        let PLIST_FORDAY: number = parseInt(
            this.props.params?.find(ipo => ipo.NAZEV?.trim() === "PLIST_FORDAY")?.HODNOTA!
        );
        let controlDate: Date = new Date(todayWithoutTime.setDate(todayWithoutTime.getDate() - PLIST_FORDAY));
        if (controlDate.getMonth() < actMonth) {
            this.setDateIntoParam(controlDate.getMonth(), controlDate.getFullYear());
            this.setState({ selectedMonth: controlDate });
        }
    }

    checkAndSetIfSignRequest(): void {
        if (this.props.isSigningAllowed && this.props.signRequest !== null && this.props.signRequest !== undefined) {
            let signDate: Date = new Date(this.props.signRequest);
            this.setDateIntoParam(signDate.getMonth(), signDate.getFullYear());
            this.setState({
                selectedMonth: signDate,
                warningnote: true,
            });
            this.timer = setTimeout(() => {
                this.setState({ warningnote: false });
            }, 3000);
        }
    }

    async componentDidMount() {
        this.props.onResetWorksheetData();
        await this.props.onFetchParams();
        if (this.props.params) {
            let zobr: boolean = convertToBoolean(
                this.props.params?.find(ipo => ipo.NAZEV?.trim() === "VYP_ZOBRHODDEC")?.HODNOTA!
            );
            let countTo: number = +this.props.params?.find(ipo => ipo.NAZEV?.trim() === "PLIST_CBPOCDO")?.HODNOTA!;
            if (countTo === 0 && localStorage.getItem("PLIST_CBPOCDO") !== null)
                countTo = +localStorage.getItem("PLIST_CBPOCDO")!;
            else if (localStorage.getItem("PLIST_CBPOCDO") === null) countTo = 2;
            this.setState({
                params: {
                    zobrHodDec: zobr,
                },
                computeTo: countTo,
            });

            this.controlFordayParam();
            this.checkAndSetIfSignRequest();
            if (
                this.state.computeTo === 2 &&
                this.checkIsActMonth(this.state.selectedMonth.getMonth(), this.state.selectedMonth.getFullYear())
            )
                this.setDateForCountTo();
            this.props.onFetchData(param);
        }
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    public minutesToHours(minutesTotal: number): string {
        let isNegative: boolean = minutesTotal < 0;
        let abs: number = Math.abs(minutesTotal);
        let hours: number = Math.floor(abs / 60);
        let minutes: number = abs % 60;

        if (this.state.params.zobrHodDec) return (isNegative ? "-" : "") + parseFloat((abs / 60).toString()).toFixed(2);
        else
            return (
                (isNegative ? "-" : "") +
                hours +
                ":" +
                (minutes === 0 ? "00" : minutes.toString().length === 1 ? "0" + minutes : minutes)
            );
    }

    public getPassTimeString(passTime: Date): string {
        let hours: string = passTime.getHours().toString();
        let minutes: string = passTime.getMinutes().toString();
        if (minutes.length === 1) minutes = "0" + minutes;
        return hours + ":" + minutes + " ";
    }

    getCurrentDayNumberName(DEN: Date): string {
        let currentDay: Date = new Date(DEN);
        let number: string = currentDay.getDate().toString();
        let name: string = currentDay.toLocaleDateString(this.props.language.code, { weekday: "short" });
        name = this.props.language.code === "en" ? name.substring(0, 2) : name;
        return number + "." + name;
    }

    public prepareData(dataSource?: PracListResultMobile): WorksheetGridDatas {
        let data: WorksheetGridDatas = [];
        let errorDates: Date[] = [];
        dataSource?.PracListChyby.forEach(error => {
            errorDates.push(error.DATUM);
        });
        dataSource?.IpodensmenCeleks.forEach(element => {
            let passesArr: string = "";
            if (element.UCET_VISIBILITY === true) passesArr = element.UCET;
            else {
                if (element.Pruchody != null) {
                    element.Pruchody.forEach(pass => {
                        let passTime: Date = new Date(pass.CasPruchodu);
                        let time: string = this.getPassTimeString(passTime);
                        let type: string = pass.TypPruchoduTxt.trim() + " ";
                        passesArr += time + type;
                    });
                } else {
                    passesArr = element.RowHeader;
                }
            }
            let row: WorksheetGridData = {
                day: element.Pruchody != null ? this.getCurrentDayNumberName(element.DEN) : "",
                shift: element.Pruchody != null ? element.SMENA_NAZEV : "",
                note: element.Poznamka,
                passes: passesArr,
                worked: element.ODPRAC ? this.minutesToHours(element.ODPRAC) : "",
                absence: element.OMLUV
                    ? this.minutesToHours(element.OMLUV)
                    : element.OMLUV_NE
                    ? "(" + this.minutesToHours(element.OMLUV_NE) + ")"
                    : "",
                balance:
                    element.Pruchody != null
                        ? element.SALDO
                            ? this.minutesToHours(element.SALDO)
                            : errorDates.find(ipo => ipo === element.DEN)
                            ? this.props.strings.worksheet.ErrorWS
                            : ""
                        : element.SumFooter,
            };
            data.push(row);
        });
        return data;
    }

    public SumOdpracCell = (dataSource?: PracListResultMobile) => {
        return (
            <td>
                <div className={styles.cellNumber}>
                    {dataSource?.SumOdprac ? this.minutesToHours(dataSource?.SumOdprac) : "0"}
                </div>
            </td>
        );
    };

    public SumAbsenceCell = (dataSource?: PracListResultMobile) => {
        return (
            <td>
                <div className={styles.cellNumber}>
                    {(dataSource?.SumOmluv ? this.minutesToHours(dataSource?.SumOmluv) : "") +
                        (dataSource?.SumOmluvNe != null
                            ? " (" + this.minutesToHours(dataSource?.SumOmluvNe!) + ")"
                            : "")}
                </div>
            </td>
        );
    };

    public SumBalanceCell = (dataSource?: PracListResultMobile) => {
        return (
            <td>
                <div className={styles.cellNumber}>
                    {(dataSource?.SumSaldo ? this.minutesToHours(dataSource?.SumSaldo) : "") +
                        (dataSource?.SumSaldoSprevodem
                            ? " (" + this.minutesToHours(dataSource?.SumSaldoSprevodem) + ")"
                            : "")}
                </div>
            </td>
        );
    };

    odpracCell = (props: GridCellProps) => {
        return (
            <td>
                <div className={styles.cellNumber}>{props.dataItem.worked}</div>
            </td>
        );
    };

    absenceCell = (props: GridCellProps) => {
        return (
            <td>
                <div className={styles.cellNumber}>{props.dataItem.absence}</div>
            </td>
        );
    };

    balanceCell = (props: GridCellProps) => {
        return (
            <td>
                <div className={styles.cellNumber}>{props.dataItem.balance}</div>
            </td>
        );
    };

    hoursCell = (props: GridCellProps) => {
        return (
            <td>
                <div className={styles.cellNumber}>{props.dataItem.Hodiny}</div>
            </td>
        );
    };

    daysCell = (props: GridCellProps) => {
        return (
            <td>
                <div className={styles.cellNumber}>{props.dataItem.Dny}</div>
            </td>
        );
    };

    toggleDialog = () => {
        this.setState({
            dialog: {
                isVisible: !this.state.dialog.isVisible,
            },
        });
    };

    getFullName(dataSource?: PracListResultMobile): string {
        return dataSource?.CeleJmeno + " " + dataSource?.OC;
    }

    getState(dataSource?: PracListResultMobile): string {
        if (dataSource != null) {
            return dataSource.Podepsal !== null
                ? dataSource.PodepsalKind //this.props.strings.worksheet.State_Signed :
                : dataSource.Potvrdil !== null
                ? this.props.strings.worksheet.State_Confirmed
                : dataSource.Schvalil !== null
                ? this.props.strings.worksheet.State_Approved
                : this.props.strings.worksheet.State_Open;
        }
        return "";
    }

    setDialogText(row: string): string {
        let search: string = "PLIST_TEXT" + row;
        return this.props.params?.find(ipo => ipo.NAZEV?.trim() === search)?.HODNOTA!;
    }

    private agreeWithWS(): void {
        this.toggleDialog();
    }

    private handleSigning = async () => {
        await this.props.onPostSigning({ Value: this.props.ixsref || "", Value2: this.state.selectedMonth });
        this.toggleDialog();
        this.props.onFetchData(param);
    };

    public handleMonthChange = (month: Date) => {
        this.setState({
            selectedMonth: month,
        });
        this.setDateIntoParam(month.getMonth(), month.getFullYear());
        this.props.onResetWorksheetData();
        this.props.onFetchData(param);
    };

    getLocalDateTimeString(date: Date | null | undefined) {
        if (!date) return "";
        let datum: Date = new Date(date);
        return (
            datum.toLocaleDateString(this.props.language.code) +
            " " +
            datum.toLocaleTimeString(this.props.language.code)
        );
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (this.props.dataSource && !this.props.isFetching) {
            if (!this.props.dataSourceNext && !this.props.isFetchingNext)
                this.props.onFetchDataNext(param, this.state.computeTo);
            else if (!this.props.dataSourcePrevious && !this.props.isFetchingPrevious)
                this.props.onFetchDataPrevious(param, this.state.computeTo);
        }
    }

    pageLayout(dataSource?: PracListResultMobile) {
        const props = this.props;
        let data: WorksheetGridDatas = this.prepareData(dataSource);
        if (dataSource) {
            return (
                <div className={styles.layout}>
                    <div className={styles.worksheetLayout}>
                        <LocalizationProvider language={props.language.code}>
                            <div className={styles.nameRow}>
                                <Input
                                    readOnly
                                    label={this.props.strings.worksheet.NameWS}
                                    value={dataSource?.CeleJmeno.trim() + ", " + dataSource?.OC}
                                />
                            </div>
                            <div className={styles.row}>
                                <div className={styles.colL}>
                                    <Input
                                        readOnly
                                        label={this.props.strings.worksheet.Period}
                                        value={
                                            new Date(dataSource?.Mesic ?? "").toLocaleString(this.props.language.code, {
                                                month: "long",
                                            }) +
                                            ", " +
                                            new Date(dataSource?.Mesic ?? "").getFullYear()
                                        }
                                    />
                                </div>
                                <div className={styles.colR}>
                                    <Input
                                        readOnly
                                        label={this.props.strings.requests.StateName}
                                        value={this.getState(dataSource)}
                                    />
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.colL}>
                                    <Input
                                        readOnly
                                        label={this.props.strings.common.Center}
                                        value={dataSource?.Stredisko}
                                    />
                                </div>
                                <div className={styles.colR}>
                                    <Input
                                        readOnly
                                        label={this.props.strings.worksheet.Model}
                                        value={dataSource?.Model}
                                    />
                                </div>
                            </div>
                            {dataSource?.Potvrdil !== null && (
                                <img
                                    className={styles.stamp}
                                    alt={"stamp"}
                                    src={
                                        this.props.language.id === -1
                                            ? enstampSource
                                            : this.props.language.id === -2
                                            ? czstampSource
                                            : skstampSource
                                    }
                                ></img>
                            )}
                            {dataSource.AnyPublished ? (
                                <>
                                    <Grid className={styles.gridStyle} data={data} scrollable={"none"}>
                                        <Column field="day" title={this.props.strings.common.Day} width="50" />
                                        <Column
                                            field="shift"
                                            title={this.props.strings.presence.ShiftName}
                                            width="70"
                                        />
                                        {data.some(ipo => ipo.note !== "") && (
                                            <Column
                                                field="note"
                                                title={this.props.strings.requests.Comment}
                                                width="50"
                                            />
                                        )}
                                        <Column field="passes" title={this.props.strings.worksheet.PassesWS} />
                                        <Column
                                            field="worked"
                                            title={this.props.strings.worksheet.Worked}
                                            cell={this.odpracCell}
                                            /*width="50"*/ footerCell={() => this.SumOdpracCell(dataSource)}
                                        />
                                        <Column
                                            field="absence"
                                            title={this.props.strings.worksheet.Absence}
                                            cell={this.absenceCell}
                                            /*width="50"*/ footerCell={() => this.SumAbsenceCell(dataSource)}
                                        />
                                        <Column
                                            field="balance"
                                            title={this.props.strings.dashboard.Balance}
                                            cell={this.balanceCell}
                                            /*width="60"*/ footerCell={() => this.SumBalanceCell(dataSource)}
                                        />
                                    </Grid>
                                    {dataSource.ContainsUnpublished && (
                                        <span className={styles.noteRow}>
                                            <span className="k-icon k-i-info" />
                                            {this.props.strings.plan.ContainsUnpublished}
                                        </span>
                                    )}
                                    <div className={styles.row}>
                                        <span className={styles.labels}>
                                            {this.props.strings.worksheet.ShiftsFund +
                                                ": " +
                                                this.minutesToHours(dataSource?.FondDlePlanuSmenMinuty!) +
                                                " [ " +
                                                dataSource?.FondDlePlanuSmenSmeny +
                                                " ]"}
                                        </span>
                                    </div>
                                    {dataSource?.ZobrazitKalFond && (
                                        <div className={styles.row}>
                                            <span className={styles.labels}>
                                                {this.props.strings.worksheet.CalendarFund +
                                                    ": " +
                                                    this.minutesToHours(dataSource?.FondDleKalendareMinuty) +
                                                    " / " +
                                                    this.minutesToHours(dataSource?.FondStanovDleKalendareMinuty)}
                                            </span>
                                        </div>
                                    )}
                                    <div className={styles.row}>
                                        <span className={styles.labels}>
                                            {this.props.strings.worksheet.TransferPast +
                                                ": " +
                                                this.minutesToHours(dataSource?.PrevodMinuly ?? 0)}
                                        </span>
                                        <span className={styles.labels}>
                                            {this.props.strings.worksheet.TransferNext +
                                                ": " +
                                                this.minutesToHours(dataSource?.PrevodDoDalsiho ?? 0)}
                                        </span>
                                    </div>
                                    <div className={styles.row}>
                                        <span className={styles.labels}>
                                            {this.props.strings.worksheet.BalanceNext +
                                                ": " +
                                                this.minutesToHours(dataSource?.SaldoVPristim ?? 0)}
                                        </span>
                                    </div>
                                    <div className={styles.row}>
                                        <span className={styles.larger}>
                                            {this.props.strings.worksheet.MonthAccounts}
                                        </span>
                                    </div>
                                    <Grid data={dataSource?.PracListMesUcty} scrollable={"none"}>
                                        <Column
                                            field="Ucet"
                                            title={this.props.strings.worksheet.AccountDefName}
                                            width="65"
                                        />
                                        <Column field="Nazev" title={this.props.strings.worksheet.NameWS} />
                                        <Column
                                            field="Hodiny"
                                            title={this.props.strings.dashboard.Hours}
                                            cell={this.hoursCell}
                                            width="60"
                                        />
                                        <Column
                                            field="Dny"
                                            title={this.props.strings.worksheet.DaysWS}
                                            cell={this.daysCell}
                                            width="60"
                                        />
                                    </Grid>
                                    {dataSource?.Schvalil && (
                                        <div className={styles.row}>
                                            <span className={styles.labels}>
                                                {this.props.strings.worksheet.ApprovedWS +
                                                    ": " +
                                                    (dataSource?.Schvalil ?? "") +
                                                    " " +
                                                    this.getLocalDateTimeString(dataSource?.SchvalilKdy)}
                                            </span>
                                        </div>
                                    )}
                                    {dataSource?.Potvrdil && (
                                        <div className={styles.row}>
                                            <span className={styles.labels}>
                                                {this.props.strings.worksheet.Confirmed +
                                                    ": " +
                                                    (dataSource?.Potvrdil ?? "") +
                                                    " " +
                                                    this.getLocalDateTimeString(dataSource?.PotvrdilKdy)}
                                            </span>
                                        </div>
                                    )}
                                    {dataSource?.Podepsal && (
                                        <div className={styles.row}>
                                            <span className={styles.labels}>
                                                {this.props.strings.worksheet.Signature +
                                                    ": " +
                                                    (dataSource?.Podepsal ?? "") +
                                                    " " +
                                                    this.getLocalDateTimeString(dataSource?.PodepsalKdy)}
                                            </span>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <span className={styles.noteRow}>
                                    <span className="k-icon k-i-info" />
                                    {this.props.strings.plan.NotPublished}
                                </span>
                            )}
                        </LocalizationProvider>
                    </div>
                </div>
            );
        } else return <SpinnerBox stretchToWindow />;
    }

    private slideRenderer = (props: SlideRenderProps) => {
        if (props.index < this.state.index)
            return <div key={props.key}>{this.pageLayout(this.props.dataSourcePrevious)}</div>;
        else if (props.index > this.state.index)
            return <div key={props.key}>{this.pageLayout(this.props.dataSourceNext)}</div>;
        else return <div key={props.key}>{this.pageLayout(this.props.dataSource)}</div>;
    };

    private scrollPrevious = () => {
        this.props.onScrollWorksheetPrevious();
        const newMonth = this.state.selectedMonth.addMonths(-1);
        this.setDateIntoParam(newMonth.getMonth(), newMonth.getFullYear());
        this.setState({ selectedMonth: newMonth });
        this.props.onFetchDataPrevious(param, this.state.computeTo);
    };

    private scrollNext = () => {
        this.props.onScrollWorksheetNext();
        const newMonth = this.state.selectedMonth.addMonths(1);
        this.setDateIntoParam(newMonth.getMonth(), newMonth.getFullYear());
        this.setState({ selectedMonth: newMonth });
        this.props.onFetchDataNext(param, this.state.computeTo);
    };

    public render() {
        const props = this.props;
        return (
            <PageLayout
                canScrollPrevious={!props.isFetchingPrevious && !!props.dataSourcePrevious}
                canScrollNext={!props.isFetchingNext && !!props.dataSourceNext}
                onScrollPrevious={this.scrollPrevious}
                onScrollNext={this.scrollNext}
                header={
                    <NavBar
                        canNavigateRoot
                        label={this.props.strings.worksheet.MyWorksheetTitle}
                        menu={{
                            items: this.createMenuItems(props.strings),
                            onItemClick: this.handleMenuItemClick,
                        }}
                    >
                        <NavbarMonthPicker selectedMonth={this.state.selectedMonth} onPick={this.handleMonthChange} />
                        {this.props.dataSource?.Potvrdil !== null &&
                            this.props.dataSource?.Podepsal === null &&
                            this.props.isSigningAllowed && (
                                <Button
                                    iconClass={"k-icon k-i-check"}
                                    className={styles.signButton}
                                    onClick={event => this.agreeWithWS()}
                                ></Button>
                            )}
                        {this.props.attachmentsAllowed && (
                            <Button
                                iconClass={"k-icon k-i-attachment"}
                                onClick={() => this.setState({ attachmentsDialogVisible: true })}
                            />
                        )}
                    </NavBar>
                }
            >
                {this.state.attachmentsDialogVisible && (
                    <AttachmentsDialog
                        month={this.state.selectedMonth}
                        onClose={() => this.setState({ attachmentsDialogVisible: false })}
                    />
                )}
                {this.props.isSigningAllowed && (
                    <NotificationGroup style={position} className={styles.z}>
                        <Slide direction={!this.state.warningnote ? "up" : "down"}>
                            {this.state.warningnote && (
                                <Notification
                                    type={{ style: "warning", icon: true }}
                                    closable={true}
                                    onClose={() => this.setState({ warningnote: false })}
                                >
                                    <span>{this.props.strings.worksheet.SignatureNeededWarning}</span>
                                </Notification>
                            )}
                        </Slide>
                    </NotificationGroup>
                )}
                {!(this.props.error || this.props.paramsError || this.props.dataSource === null) && (
                    <VirtualizeSwipeableViews
                        style={{ minHeight: "100%" }}
                        overscanSlideBefore={1}
                        overscanSlideAfter={1}
                        slideRenderer={this.slideRenderer}
                        onChangeIndex={(index1, index0) => {
                            this.setState({ index: index1 });
                            if (index0 < index1) {
                                this.scrollNext();
                            } else {
                                this.scrollPrevious();
                            }
                        }}
                    />
                )}
                {(this.props.error || this.props.paramsError) && (
                    <ErrorNotification
                        error={this.props.error ? this.props.error : this.props.paramsError}
                    ></ErrorNotification>
                )}
                {!this.props.paramsFetching && !this.props.isFetching && this.props.dataSource == null && (
                    <div className={styles.nodata}>{this.props.strings.worksheet.NoDataWorksheetNull}</div>
                )}
                {this.state.dialog.isVisible && (
                    <Dialog
                        className={styles.dialogStyle}
                        title={this.props.strings.worksheet.SignTitle}
                        onClose={this.toggleDialog}
                        closeIcon={false}
                        width="90%"
                    >
                        <p>{this.props.strings.common.Person + ": " + this.getFullName(this.props.dataSource)}</p>
                        <p>
                            {this.props.strings.worksheet.Period +
                                ": " +
                                this.state.selectedMonth.toLocaleString(this.props.language.code, { month: "long" }) +
                                " " +
                                this.state.selectedMonth.getFullYear()}
                        </p>
                        <p>{this.setDialogText("")}</p>
                        <p>{this.setDialogText("2")}</p>
                        <p>{this.setDialogText("3")}</p>
                        <DialogActionsBar>
                            {!this.props.isSinging && (
                                <Button className="k-button" onClick={this.toggleDialog}>
                                    {this.props.strings.common.Close}
                                </Button>
                            )}
                            {!this.props.isSinging && (
                                <Button className="k-button" onClick={this.handleSigning} style={{ color: "orange" }}>
                                    {this.props.strings.worksheet.Sign}
                                </Button>
                            )}
                            {this.props.isSinging && (
                                <div className={styles.signSpinnerBox}>
                                    <span className={classNames(styles.signSpinner, "k-icon k-i-loading")}></span>
                                </div>
                            )}
                        </DialogActionsBar>
                    </Dialog>
                )}
            </PageLayout>
        );
    }
}

export default connect<IStateProps, IDispatchProps, {}, IRootState>(
    state => ({
        strings: state.localization.strings,
        language: state.localization.language,
        ixsref: selectIxsRef(state),
        dataSource: state.session.worksheet.datasource,
        dataSourcePrevious: state.session.worksheet.datasourcePrevious,
        dataSourceNext: state.session.worksheet.datasourceNext,
        isFetching: state.session.worksheet.isFetching,
        isFetchingPrevious: state.session.worksheet.isFetchingPrevious,
        isFetchingNext: state.session.worksheet.isFetchingNext,
        error: state.session.worksheet.error,
        params: state.session.worksheetParams.params,
        paramsError: state.session.worksheetParams.error,
        paramsFetching: state.session.worksheetParams.isFetching,
        isSigningAllowed:
            !state.session.user.token?.userDisabledActions.some(p => p === permissions.workSheet.sign) ?? false,
        isSinging: state.session.worksheetSign.isFetching,
        signRequest: state.session.dashboard.data?.wsRequests?.firstDate,
        attachmentsAllowed:
            (state.session.worksheet.datasource?.showAttachments ?? false) &&
            (!(
                state.session.user.token?.userDisabledActions.some(p => p === permissions.workSheet.attachments) ||
                state.session.user.token?.userInvisibleActions.some(p => p === permissions.workSheet.attachments)
            ) ??
                false),
    }),
    {
        onFetchData: (e: LeftPanelParams) => fetchWorksheetDataForMonth(e),
        onFetchDataPrevious: (e: LeftPanelParams, computeTo: number) =>
            fetchWorksheetDataForPreviousMonth({ params: e, computeTo }),
        onFetchDataNext: (e: LeftPanelParams, computeTo: number) =>
            fetchWorksheetDataForNextMonth({ params: e, computeTo }),
        onFetchParams: () => getParamsForWorksheet() as any,
        onPostSigning: (e: QueryParam2<string, Date>) => signWorksheet(e) as any,
        onScrollWorksheetPrevious: scrollWorksheetPrevious,
        onScrollWorksheetNext: scrollWorksheetNext,
        onResetWorksheetData: resetWorksheetData,
    }
)(Worksheet);
