import { createAsyncThunk } from "@reduxjs/toolkit";
import { IChangeCredentials } from "../../model/security/IChangeCredentials";
import { IRootState } from "../reducers/root";
import { handleResponseError } from "../../helpers/handleResponseError";

export const changePassword = createAsyncThunk<void, IChangeCredentials, { state: IRootState }>(
    "passwordChange",
    async (arg, api) => {
        const requestOptions: RequestInit = {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": `Bearer ${api.getState().session.user.token?.access_token}`,
            },
            credentials: "include",
            body: JSON.stringify({
                UserName: arg.username,
                OldPassword: arg.oldPassword,
                NewPassword: arg.newPassword,
            }),
        };

        const response = await fetch(
            api.getState().config.config.endpoint + "/controllers/IpoData/ChangePassword",
            requestOptions
        );
        var data = await response.json();
        if (data["status"] !== "OK") {
            throw new Error(data["status"]);
        }
    }
);

export const getPasswordParam = createAsyncThunk<{}, void, { state: IRootState }>(
    "passwordChange/getParams",
    async (arg, api) => {
        const requestOptions: RequestInit = {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": `Bearer ${api.getState().session.user.token?.access_token}`,
            },
            credentials: "include",
        };
        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/SelfService/GetPasswordParam",
            requestOptions
        );

        await handleResponseError(response);

        return (await response.json()).trim() === "1";
    }
);

export const getOldPaswordVisible = createAsyncThunk<{}, void, { state: IRootState }>(
    "passwordChange/getOldVisible",
    async (arg, api) => {
        const requestOptions: RequestInit = {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": `Bearer ${api.getState().session.user.token?.access_token}`,
            },
            credentials: "include",
        };
        const response = await fetch(
            api.getState().config.config?.endpoint + "/breeze/IpoData/HasAuthUserPasswordSet",
            requestOptions
        );

        await handleResponseError(response);

        return response.json();
    }
);
