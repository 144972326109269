import { formatDate } from "@telerik/kendo-intl";
import { IInterval, IGroup } from "./types";
import { Color } from "../../model/Color";
import { Key } from "react";

export function generateRandomIntervals(count: number): IInterval[] {
    const intervals: IInterval[] = [];

    for (let day = 1; day <= count; day++) {
        intervals.push(generateRandomInterval(day, 1));
        intervals.push(generateRandomInterval(day, 2, "Odpracováno"));
        intervals.push(generateRandomInterval(day, 2, "Přestávka"));
    }

    return intervals;
}

export function createCustomIntervals(): IInterval[] {
    const intervals: IInterval[] = [
        {
            id: 1,
            title: "Od",
            day: new Date(2020, 0, 1),
            beginTime: new Date(2020, 0, 1, 1, 0),
            endTime: new Date(2020, 0, 1, 23, 0),
            style: {
                backgroundColor: Color.random().toCSSRGBA(),
            },
            group: {
                id: 1,
            },
        },
        {
            id: 2,
            title: "PO",
            day: new Date(2020, 0, 1),
            beginTime: new Date(2020, 0, 1, 9, 0),
            endTime: new Date(2020, 0, 1, 15, 0),
            style: {
                backgroundColor: Color.random().toCSSRGBA(),
                zIndex: 2,
            },
            group: {
                id: 2,
            },
        },
    ];

    return intervals;
}

export function generateRandomInterval(day: number, stackID: Key, title?: string): IInterval {
    let hours = Math.floor(Math.random() * 23);
    let minutes = Math.floor(Math.random() * 59);
    let begin = new Date(2020, 0, day, hours, minutes);

    hours = Math.floor(Math.random() * 23);
    minutes = Math.floor(Math.random() * 59);
    let end = new Date(2020, 0, day, hours, minutes);

    if (begin.getTime() > end.getTime()) [begin, end] = [end, begin];

    return {
        id: uuidv4(),
        title: title
            ? title
            : formatDate(begin, { hour: "2-digit", minute: "2-digit" }) +
              "-" +
              formatDate(end, { hour: "2-digit", minute: "2-digit" }),
        day: new Date(2020, 0, day),
        beginTime: begin,
        endTime: end,
        style: {
            backgroundColor: Color.random().toCSSRGBA(),
        },
        group: {
            id: day,
            stackID: stackID,
        },
    };
}

function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, char => {
        const r = (Math.random() * 16) | 0;
        const v = char === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export function generateDummyInterval(): IInterval {
    return {
        id: uuidv4(),
        title: "dummy",
        day: new Date(),
        beginTime: new Date(),
        endTime: new Date(),
        group: {
            id: -1,
        },
        style: {
            visibility: "hidden",
        },
    };
}

export function generateGroups(): IGroup[] {
    const groups: IGroup[] = [];

    for (let day = 1; day <= 30; day++) {
        groups.push({
            id: day,
            title: formatDate(new Date(2020, 0, day), { day: "numeric", weekday: "short" }),
        });
    }

    return groups;
}
