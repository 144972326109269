import React, { PropsWithChildren, useEffect } from "react";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import { NavBar } from "../../components/NavBar/NavBar";
import { Input } from "@progress/kendo-react-inputs";
import styles from "./PersonalCard.module.css";
import { fetchPersonalCardData } from "../../redux/actions/personalCard";
import { IBaseMenuItem } from "../../components/NavBar/IBaseMenuItem";
import { SpinnerBox } from "../../components/Spinner/SpinnerBox";
import defaultAvatarSource from "../../assets/images/avatar-140.jpg";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useStrings } from "../../hooks/useStrings";
import { useFormatDate } from "../../hooks/useFormatDate";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { ErrorNotification } from "../../components/ErrorNotification";

export function PersonalCard() {
    const dispatch = useAppDispatch();
    useEffect(() => {
        const abortable = dispatch(fetchPersonalCardData());
        return () => abortable.abort();
    }, [dispatch]);

    function createMenuItems() {
        return [] as IBaseMenuItem[];
    }

    function handleMenuItemClick(item: IBaseMenuItem) {
        switch (item.id) {
            default:
                item.id && console.warn("Unhandled menu item: ", item);
        }
    }

    const strings = useStrings();
    return (
        <PageLayout
            header={
                <NavBar
                    canNavigateRoot
                    label={strings.dashboard.PersonalCard}
                    menu={{
                        items: createMenuItems(),
                        onItemClick: handleMenuItemClick,
                    }}
                ></NavBar>
            }
        >
            <div className={styles.contentContainer}>
                <Loading>
                    <ErrorGuard>
                        <Content />
                    </ErrorGuard>
                </Loading>
            </div>
        </PageLayout>
    );
}

export default PersonalCard;

function Loading(props: PropsWithChildren<{}>) {
    const isLoading = useAppSelector(s => s.session.personalCard.isFetching);

    if (isLoading) {
        return <SpinnerBox />;
    }

    return <>{props.children}</>;
}

function ErrorGuard(props: PropsWithChildren<{}>) {
    const error = useAppSelector(s => s.session.personalCard.error);

    if (error) {
        return <ErrorNotification error={error} />;
    }

    return <>{props.children}</>;
}

function Content() {
    const strings = useStrings();
    const formatDate = useFormatDate();
    const data = useAppSelector(s => s.session.personalCard.data) ?? {};

    return (
        <>
            <img
                className={styles.avatar}
                src={data.avatarSource ?? defaultAvatarSource}
                alt={`${data.lastname} ${data.firstname}`}
            />
            <div>
                <Input
                    readOnly
                    label={strings.common.Person}
                    value={`${data.degreeBefore ?? ""} ${data.lastname ?? ""} ${data.firstname ?? ""}${
                        data.degreeAfter ? ", " + data.degreeAfter : ""
                    }`.replace("/ {2, }/", "")}
                />
                <Input
                    readOnly
                    label={`${strings.common.OC} + ${strings.personalCard.EmploymentNumber}`}
                    value={`${data.personalNumber || ""} (${data.order || ""})`.replace("()", "")}
                />
                <Input readOnly label={strings.personalCard.Phone} value={data.phone || " "} />
                <Input readOnly label="Email" value={data.email || " "} />
                <Input readOnly label={strings.common.Center} value={data.centerName || " "} />
                <Input readOnly label={strings.personalCard.Profession} value={data.profession || " "} />
                <div className={styles.row}>
                    <div className={styles.colL}>
                        <Input
                            readOnly
                            label={strings.personalCard.ValidFrom}
                            value={data.validFrom ? formatDate(new Date(data.validFrom), "d") : " "}
                        />
                    </div>
                    <div className={styles.colR}>
                        <Input
                            readOnly
                            label={strings.personalCard.ValidTo}
                            value={data.validTo ? formatDate(new Date(data.validTo), "d") : " "}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.colL}>
                        <Input readOnly label={strings.dashboard.Presence} value={data.presence || " "} />
                    </div>
                    <div className={styles.colR}>
                        <Input
                            readOnly
                            label={strings.personalCard.LastPassage}
                            value={data.lastPassageTime ? formatDate(new Date(data.lastPassageTime), "g") : " "}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
