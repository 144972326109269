import React from "react";
import { logout, redirectToDelegatedLogout } from "../redux/actions/user";
import { useStrings } from "../hooks/useStrings";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { deleteSavedUser } from "../redux/actions/localStorage/deleteSavedUser";
import { useHistory } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { useAppSelector } from "../hooks/useAppSelector";

export function LogoutButton() {
    const strings = useStrings();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const tokenFetching = useAppSelector(s => s.session.user.isAuthenticating);

    function handleButtonClick() {
        history.push("/");
        dispatch(redirectToDelegatedLogout());
        dispatch(logout());
        deleteSavedUser();
    }

    return (
        <Button disabled={tokenFetching} onClick={handleButtonClick}>
            {strings.dashboard.Logout.toLocaleUpperCase()}
        </Button>
    );
}
