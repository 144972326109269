import { createReducer, SerializedError } from "@reduxjs/toolkit";
import { confirmSignRequests } from "../../actions/plan/signing";

export interface ISigningState {
    isSigning: boolean;
    requestID?: string;
    error?: SerializedError;
}

export const signingReducer = createReducer<ISigningState>(
    {
        isSigning: false,
    },
    builder =>
        builder
            .addCase(confirmSignRequests.pending, (state, action) => ({
                isSigning: true,
                requestID: action.meta.requestId,
                error: undefined,
            }))
            .addCase(confirmSignRequests.fulfilled, (state, action) =>
                state.requestID === action.meta.requestId
                    ? {
                          isSigning: false,
                          error: undefined,
                      }
                    : state
            )
            .addCase(confirmSignRequests.rejected, (state, action) =>
                state.requestID === action.meta.requestId
                    ? {
                          isSigning: false,
                          error: action.error,
                      }
                    : state
            )
);
