import React from "react";
import { IColumnConfig } from "../types";
import { CornerCell } from "../cells/CornerCell";
import { ColumnCell } from "../cells/ColumnCell";

interface IProps {
    columns: IColumnConfig[];
    cornerCellContentRenderer?: () => React.ReactNode;
    dayHeaderContentRenderer?: (props: { column: IColumnConfig }) => React.ReactNode;
}

export const Header = (props: IProps) => (
    <>
        <CornerCell contentRenderer={props.cornerCellContentRenderer} />
        {props.columns.map((col, index) => (
            <ColumnCell
                key={col.id}
                column={col}
                columnIndex={1 + index + 1}
                dayHeaderContentRenderer={props.dayHeaderContentRenderer}
            />
        ))}
    </>
);
