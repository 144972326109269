import { createReducer, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import {
    fetchIporefsForWFL,
    fetchIporefsForWFLWithFilter,
    fetchRequestsForApprovalDatasource,
} from "../actions/requestsForApproval";
import { WflReq } from "../../model/wflReq";
import { IporefForWidget } from "../../model/IporefForWidget";

export interface IRequestsForApprovalState {
    isFetching: boolean;
    datasource?: WflReq[];
    error?: SerializedError;
}

export const requestsForApprovalReducer = createReducer<IRequestsForApprovalState>(
    {
        isFetching: false,
    },
    builder => {
        return builder
            .addCase(fetchRequestsForApprovalDatasource.pending, (state, action) => ({
                isFetching: true,
                datasource: undefined,
                error: undefined,
            }))
            .addCase(fetchRequestsForApprovalDatasource.fulfilled, (state, action: PayloadAction<any>) => ({
                isFetching: false,
                datasource: action.payload,
                error: undefined,
            }))
            .addCase(fetchRequestsForApprovalDatasource.rejected, (state, action) => ({
                isFetching: false,
                datasource: undefined,
                error: action.error,
            }));
    }
);

export interface IPersonsForApprovalState {
    fetching?: boolean;
    datasource?: IporefForWidget[];
    error?: unknown;
}

export const personsForApprovingReducer = createReducer<IPersonsForApprovalState>(
    {
        fetching: false,
    },
    builder => {
        return builder
            .addCase(fetchIporefsForWFL.pending, (state, action) => ({
                ...state,
                fetching: true,
            }))
            .addCase(fetchIporefsForWFL.fulfilled, (state, action: PayloadAction<any>) => ({
                ...state,
                fetching: false,
                datasource: action.payload,
            }))
            .addCase(fetchIporefsForWFL.rejected, (state, action: PayloadAction<any>) => ({
                ...state,
                fetching: false,
                error: action.payload,
            }));
    }
);

export const personsForApprovingWithFilterReducer = createReducer<IPersonsForApprovalState>(
    {
        fetching: false,
    },
    builder => {
        return builder
            .addCase(fetchIporefsForWFLWithFilter.pending, (state, action) => ({
                ...state,
                fetching: true,
            }))
            .addCase(fetchIporefsForWFLWithFilter.fulfilled, (state, action: PayloadAction<any>) => ({
                ...state,
                fetching: false,
                datasource: action.payload,
            }))
            .addCase(fetchIporefsForWFLWithFilter.rejected, (state, action: PayloadAction<any>) => ({
                ...state,
                fetching: false,
                error: action.payload,
            }));
    }
);
