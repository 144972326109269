import { AppThunkAction } from "../../../model/redux/AppThunkAction";
import { buildKey } from "./buildKey";

export function getItem<T>(key: string): AppThunkAction<T | null> {
    return (dispatch, getState) => {
        const realKey = dispatch(buildKey(key));

        try {
            const json = localStorage.getItem(realKey);

            if (json === null) return null;

            return JSON.parse(json);
        } catch (e) {
            console.error(e);
        }

        return null;
    };
}
