import { Reducer } from "redux";
import { PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { ILocalizationState, localizationSlice } from "../slices/localization";
import { configReducer, IConfigState } from "./config";
import { IErrorsState, errorsReducer } from "./errors";
import { ISessionState, sessionReducer } from "./session";
import { IAppState, appReducer } from "./app";

export interface IRootState {
    errors: IErrorsState;
    config: IConfigState;
    app: IAppState;
    localization: ILocalizationState;
    session: ISessionState;
}

export const rootReducer: Reducer<IRootState, PayloadAction<unknown, string, never, SerializedError>> = (
    state,
    action
) => {
    return {
        errors: errorsReducer(state?.errors, action),
        config: configReducer(state?.config, action),
        app: appReducer(state?.app, action),
        localization: localizationSlice.reducer(state?.localization, action),
        session: sessionReducer(state?.session, action),
    };
};
