import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import React, { MouseEventHandler } from "react";
import { connect } from "react-redux";
import { dateOptions } from "../../constants/dates";
import { IPlanPerson } from "../../model/IPlanPerson";
import { ILanguage } from "../../model/localization/ILanguage";
import { IStrings } from "../../constants/languageStrings/IStrings";
import { IRootState } from "../../redux/reducers/root";

class PlanStateDetail extends React.Component<IProps, {}> {
    private getCorrectDateString(dateInput: Date | null | undefined): string {
        if (dateInput != null) {
            let dateOutput: Date = new Date(dateInput);
            return dateOutput.toLocaleDateString(this.props.lang.code, dateOptions);
        } else return "";
    }

    public render() {
        return (
            <Dialog
                title={
                    <label>
                        {this.props.detailData.Type
                            ? this.props.strings.planStates.DetailRequest
                            : this.props.strings.planStates.DetailCheck}
                    </label>
                }
                width="90%"
                closeIcon={false}
            >
                {this.props.detailData.OcFullName && (
                    <Input readOnly label={this.props.strings.common.Person} value={this.props.detailData.OcFullName} />
                )}
                {this.props.detailData.DateFrom && (
                    <Input
                        readOnly
                        label={this.props.strings.planStates.DateFrom}
                        value={this.getCorrectDateString(this.props.detailData.DateFrom)}
                    />
                )}
                {this.props.detailData.DateTo && (
                    <Input
                        readOnly
                        label={this.props.strings.planStates.DateTo}
                        value={this.getCorrectDateString(this.props.detailData.DateTo)}
                    />
                )}
                {this.props.detailData.PrefKind && (
                    <Input
                        readOnly
                        label={this.props.strings.planStates.PreferedKind}
                        value={this.props.detailData.PrefKind}
                    />
                )}
                {this.props.detailData.CheckedBy && (
                    <Input
                        readOnly
                        label={
                            this.props.detailData.Type
                                ? this.props.strings.planStates.ChangedBy
                                : this.props.strings.planStates.Checkedby
                        }
                        value={this.props.detailData.CheckedBy}
                    />
                )}
                {this.props.detailData.App && (
                    <Input
                        readOnly
                        label={this.props.strings.planStates.Application}
                        value={this.props.detailData.App}
                    />
                )}
                <DialogActionsBar>
                    <Button onClick={this.props.onClose}>{this.props.strings.common.Close}</Button>
                </DialogActionsBar>
            </Dialog>
        );
    }
}

interface IOwnProps {
    onClose?: MouseEventHandler;
    detailData: IPlanPerson;
}

interface IStateProps {
    strings: IStrings;
    lang: ILanguage;
}

type IProps = IOwnProps & IStateProps;

export default connect<IStateProps, {}, IOwnProps, IRootState>(state => ({
    strings: state.localization.strings,
    lang: state.localization.language,
}))(PlanStateDetail);
